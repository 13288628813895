import { CareEsioEntity } from "../../../constants/CareESIO/CareEsioConstants";
import {
  ContentType,
  RAFShiftActionStatus,
} from "../../../constants/Common/Constants";
import { getRelatedRecords } from "../../../RAFComponents/helpers/AppHelper";
import {
  isNotEmptyArray,
  isNotNullAndUndefined,
  IsNotNullOrWhiteSpace,
  propertyOf,
} from "../../../RAFComponents/helpers/utils";
import { ListServiceRequest } from "../../../RAFComponents/models/Common/ListRequest";
import {
  RAFCustomFilter,
  RAFCustomOperator,
} from "../../../RAFComponents/RAFViewPanels/RAFFilterColumn/RAFCustomFilter";
import * as repositoryActions from "../../../RAFComponents/store/actions/repositoryActions";
import { showWarningToast } from "../../../RAFComponents/Utility/RAFToastComponent";
import { getAllActivityItems } from "../../ActiveContacts/Activity/ActivityHelper";
import {
  ActivityRow,
  RAFActivityCommentType,
} from "../../ActiveContacts/Activity/ActivityRow";

export const getShiftActivitiesByTaskId = (taskId: string) => {
  return new Promise<[]>((resolve) => {
    if (IsNotNullOrWhiteSpace(taskId)) {
      let relatedFilter: RAFCustomFilter = {};
      relatedFilter.Condition = "and";
      relatedFilter.Rules = [];

      let filter1: RAFCustomFilter = {};
      let filterVal1: string[] = [];
      filterVal1.push(taskId);
      filter1.Operator = RAFCustomOperator.Equal;
      filter1.Value = filterVal1;
      filter1.Field = "TaskUID";
      relatedFilter.Rules.push(filter1);

      let filter2: RAFCustomFilter = {};
      let filterVal2: string[] = [];
      filterVal2.push(taskId);
      filter2.Operator = RAFCustomOperator.IsNotNull;
      //filter2.Value = filterVal2;
      filter2.Field = "SupportNeedUID";
      relatedFilter.Rules.push(filter2);

      let listServiceRequest = new ListServiceRequest();
      listServiceRequest.CustomFilter = relatedFilter;
      listServiceRequest.ViewName = "all_shift_activity";
      listServiceRequest.Skip = 0;
      listServiceRequest.Take = 0;

      listServiceRequest.EntityName =
        CareEsioEntity.CareShiftActivity.EntityName;

      repositoryActions
        .postDataAndGetResponse(
          "DataList/List",
          listServiceRequest,
          null,
          ContentType.applicationJson
        )
        .then((response) => {
          if (isNotNullAndUndefined(response)) {
            if (response.status === 204) {
              resolve(null);
            } else if (
              isNotNullAndUndefined(response.data) &&
              isNotNullAndUndefined(response.data.Entity)
            ) {
              resolve(response.data.Entity);
            } else if (
              response.data &&
              response.data.ResultTable &&
              response.data.ResultTable.length > 0
            ) {
              resolve(response.data.ResultTable);
            } else {
              resolve(null);
            }
          } else {
            resolve(null);
          }
        })
        .catch((error) => error);
    } else {
      resolve(null);
    }
  });
};

export const getShiftActivitiesByType = (
  careRecipientUID?: string,
  careShiftTypes?: string[]
) => {
  return new Promise<[]>((resolve) => {
    if (IsNotNullOrWhiteSpace(careRecipientUID)) {
      let relatedFilter: RAFCustomFilter = {};
      relatedFilter.Condition = "and";
      relatedFilter.Rules = [];

      let filter1: RAFCustomFilter = {};
      let filterVal1: string[] = [];
      filterVal1.push(careRecipientUID);
      filter1.Operator = RAFCustomOperator.Equal;
      filter1.Value = filterVal1;
      filter1.Field = "CareRecipientUID";
      relatedFilter.Rules.push(filter1);

      let filter2: RAFCustomFilter = {};
      let filterVal2: string[] = [];
      filterVal2.push("Planned");
      filter2.Operator = RAFCustomOperator.Equal;
      filter2.Value = filterVal2;
      filter2.Field = "Status";
      relatedFilter.Rules.push(filter2);

      let filter3: RAFCustomFilter = {};
      filter3.Operator = RAFCustomOperator.IsNull;
      filter3.Field = "TaskUID";
      relatedFilter.Rules.push(filter3);

      let customFilter: RAFCustomFilter = {};
      customFilter.Condition = "or";
      customFilter.Rules = [];

      if (isNotEmptyArray(careShiftTypes)) {
        careShiftTypes.forEach((careShiftType) => {
          let filter1: RAFCustomFilter = {};
          let filterVal1: string[] = [];
          filterVal1.push(careShiftType);
          filter1.Operator = RAFCustomOperator.Equal;
          filter1.Value = filterVal1;
          filter1.Field = "CareShiftType";
          customFilter.Rules.push(filter1);
        });
      }

      if (isNotEmptyArray(customFilter.Rules)) {
        relatedFilter.Rules.push(customFilter);
      }

      let listServiceRequest = new ListServiceRequest();
      listServiceRequest.CustomFilter = relatedFilter;
      listServiceRequest.ViewName = "related_shift_activity";
      listServiceRequest.Skip = 0;
      listServiceRequest.Take = 0;

      listServiceRequest.EntityName =
        CareEsioEntity.CareShiftActivity.EntityName;

      repositoryActions
        .postDataAndGetResponse(
          "DataList/List",
          listServiceRequest,
          null,
          ContentType.applicationJson
        )
        .then((response) => {
          if (isNotNullAndUndefined(response)) {
            if (response.status === 204) {
              resolve(null);
            } else if (
              isNotNullAndUndefined(response.data) &&
              isNotNullAndUndefined(response.data.Entity)
            ) {
              resolve(response.data.Entity);
            } else if (
              response.data &&
              response.data.ResultTable &&
              response.data.ResultTable.length > 0
            ) {
              resolve(response.data.ResultTable);
            } else {
              resolve(null);
            }
          } else {
            resolve(null);
          }
        })
        .catch((error) => error);
    } else {
      resolve(null);
    }
  });
};

export const ShiftActivityTitle = {
  StartedShift: "Shift started",
  CompletedShift: "Shift completed",
};
export const ShiftActivityTitleDisplayName = {
  StartedShift: "Started",
  CompletedShift: "Completed",
};

export const SaveShiftActivity = (saveRequestData?: any, apiurl?: string) => {
  return new Promise<{ entityId: string; objectName: string }>(
    (resolve, reject) => {
      let url = isNotNullAndUndefined(apiurl)
        ? apiurl
        : "Datalist/SaveSubForms";

      repositoryActions
        .postDataAndGetResponse(
          url,
          saveRequestData,
          null,
          ContentType.applicationJson
        )
        .then((response) => {
          if (
            isNotNullAndUndefined(response) &&
            isNotNullAndUndefined(response.data) &&
            isNotNullAndUndefined(response.data.EntityId)
          ) {
            resolve({
              entityId: response.data.EntityId,
              objectName: response.data.ObjectName,
            });
          } else {
            showWarningToast("Sorry something went wrong !");
            resolve(null);
          }
        })
        .catch((error) => error);
    }
  );
};

export const SaveShift = (saveRequestData: any, url: string) => {
  return new Promise<{ entityId: string; objectName: string }>(
    (resolve, reject) => {
      repositoryActions
        .postDataAndGetResponse(
          url,
          saveRequestData,
          null,
          ContentType.applicationJson
        )
        .then((response) => {
          if (
            isNotNullAndUndefined(response) &&
            isNotNullAndUndefined(response.data) &&
            isNotNullAndUndefined(response.data.EntityId)
          ) {
            resolve({
              entityId: response.data.EntityId,
              objectName: response.data.ObjectName,
            });
          } else {
            showWarningToast("Sorry something went wrong !");
            resolve(null);
          }
        })
        .catch((error) => error);
    }
  );
};

export const getLastCompletedShiftItem = (careShiftLogs: any[]) => {
  if (isNotEmptyArray(careShiftLogs)) {
    const careShiftLogsItems = [...careShiftLogs];

    const careShiftLogsNewList = careShiftLogsItems.sort((a, b) => {
      if (a.Title === ShiftActivityTitle.StartedShift) return -1;
      if (b.Title === ShiftActivityTitle.CompletedShift) return 1;
      return 0;
    });

    const completedItems = careShiftLogsNewList.filter(
      (x) =>
        x.Status === RAFShiftActionStatus.Done ||
        x.Status === RAFShiftActionStatus.Completed
    );
    completedItems.sort(
      (a, b) => new Date(b.EndDate).getTime() - new Date(a.EndDate).getTime()
    );

    const lastCompletedItem = isNotEmptyArray(completedItems)
      ? completedItems[0]
      : null;
    return lastCompletedItem;
  } else {
    return null;
  }
};

export const getCareShiftLogsList = (
  careEventUID: string,
  selectedEntity: string
) => {
  return new Promise<any[]>(async (resolve) => {
    if (isNotNullAndUndefined(careEventUID)) {
      const sortQuery: string[] = ["start_date", "SubCategory"];

      let customFilter: RAFCustomFilter = {};
      customFilter.Condition = "and";
      customFilter.Rules = [];

      if (isNotNullAndUndefined(selectedEntity)) {
        if (selectedEntity !== CareEsioEntity.CareShiftActivity.EntityName) {
          let filter1: RAFCustomFilter = {};
          let filterVal1: string[] = [];
          filterVal1.push(selectedEntity);
          filter1.Operator = RAFCustomOperator.Equal;
          filter1.Value = filterVal1;
          filter1.Field =
            selectedEntity === ShiftActivityTitle.StartedShift
              ? "title"
              : "log_type";
          customFilter.Rules.push(filter1);
        }
      }

      const careShiftLogsList = await getRelatedRecords(
        CareEsioEntity.CareShiftLog.EntityName,
        null,
        "taskuid",
        careEventUID,
        null,
        CareEsioEntity.CareShiftLog.EntityName,
        sortQuery,
        null,
        customFilter
      );
      resolve(careShiftLogsList);
    } else {
      resolve(null);
    }
  });
};

export const getCareShiftActivityItems = (
  careEventUID: string,
  careRecipientUID: string
) => {
  return new Promise<ActivityRow[]>(async (resolve) => {
    if (
      isNotNullAndUndefined(careEventUID) &&
      isNotNullAndUndefined(careRecipientUID)
    ) {
      const noteSortQuery: string[] = [
        propertyOf<ActivityRow>("Pinned"),
        propertyOf<ActivityRow>("DisplayOrder"),
        `${propertyOf<ActivityRow>("ModifiedDate")} desc`,
      ];

      let noteCustomFilter: RAFCustomFilter = {};
      noteCustomFilter.Condition = "and";
      noteCustomFilter.Rules = [];

      let customFilter1: RAFCustomFilter = {};
      customFilter1.Condition = "or";
      customFilter1.Rules = [];

      let shiftfilter1: RAFCustomFilter = {};
      let shiftVal1: string[] = [];
      shiftVal1.push(careEventUID);
      shiftfilter1.Operator = RAFCustomOperator.Equal;
      shiftfilter1.Value = shiftVal1;
      shiftfilter1.Field = propertyOf<ActivityRow>("SecondaryRelatedToUID");
      noteCustomFilter.Rules.push(shiftfilter1);

      let shiftfilter2: RAFCustomFilter = {};
      shiftfilter2.Operator = RAFCustomOperator.IsNotNull;
      shiftfilter2.Field = propertyOf<ActivityRow>("SecondaryRelatedToUID");
      noteCustomFilter.Rules.push(shiftfilter2);

      let filter1: RAFCustomFilter = {};
      let filterVal1: string[] = [];
      filterVal1.push("false");
      filter1.Operator = RAFCustomOperator.Equal;
      filter1.Value = filterVal1;
      filter1.Field = propertyOf<ActivityRow>("Pinned");
      customFilter1.Rules.push(filter1);

      noteCustomFilter.Rules.push(customFilter1);

      let nullParentUIDFilter: RAFCustomFilter = {};
      nullParentUIDFilter.Operator = RAFCustomOperator.IsNull;
      nullParentUIDFilter.Field = propertyOf<ActivityRow>("ParentUID");
      noteCustomFilter.Rules.push(nullParentUIDFilter);

      let commentTypeFiler: RAFCustomFilter = {};
      let commentTypeFilterValue: string[] = [];
      commentTypeFilterValue.push(RAFActivityCommentType.Note);
      commentTypeFiler.Operator = RAFCustomOperator.Equal;
      commentTypeFiler.Value = commentTypeFilterValue;
      commentTypeFiler.Field = propertyOf<ActivityRow>("CommentType");
      noteCustomFilter.Rules.push(commentTypeFiler);

      const activityItems = await getAllActivityItems(
        null,
        careRecipientUID,
        null,
        null,
        noteCustomFilter,
        noteSortQuery,
        0,
        0
      );
      resolve(activityItems);
    } else {
      resolve(null);
    }
  });
};
