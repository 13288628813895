import {
  RAFCustomFilter,
  RAFCustomOperator,
} from "../../../RAFComponents/RAFViewPanels/RAFFilterColumn/RAFCustomFilter";
import {
  isNotEmptyArray,
  isNotNullAndUndefined,
  propertyOf,
} from "../../../RAFComponents/helpers/utils";
import { ListServiceRequest } from "../../../RAFComponents/models/Common/ListRequest";
import { SaveRequest } from "../../../RAFComponents/models/Common/ListResponse";
import * as repositoryActions from "../../../RAFComponents/store/actions/repositoryActions";
import { ContentType } from "../../../constants/Common/Constants";
import { CareTeamRow } from "./CareTeamRow";

export const getRelatedCareTeamByRecipientID = (
  careRecipientUID: string,
  relatedToType?: string,
  sortQuery?: string[]
) => {
  return new Promise<CareTeamRow[]>((resolve) => {
    if (isNotNullAndUndefined(careRecipientUID)) {
      let customFilter: RAFCustomFilter = {};
      customFilter.Condition = "and";
      customFilter.Rules = [];

      if (isNotNullAndUndefined(relatedToType)) {
        let relatedToFilter: RAFCustomFilter = {};
        let relatedToFilterVal: string[] = [];
        relatedToFilterVal.push(relatedToType);
        relatedToFilter.Operator = RAFCustomOperator.Equal;
        relatedToFilter.Value = relatedToFilterVal;
        relatedToFilter.Field = propertyOf<CareTeamRow>('RelatedToType');
        customFilter.Rules.push(relatedToFilter);
      }

      let listServiceRequest = new ListServiceRequest();
      listServiceRequest.CustomFilter = customFilter;
      listServiceRequest.Take = 1000;
      listServiceRequest.Skip = 0;

      if (isNotEmptyArray(sortQuery)) {
        listServiceRequest.Sort = sortQuery;
      }

      let additionalParams = [
        {
          key: "RelatedField",
          value: propertyOf<CareTeamRow>("CareRecipientUID"),
        },
        {
          key: "RelatedFieldValue",
          value: careRecipientUID,
        },
      ];

      if (
        isNotNullAndUndefined(additionalParams) &&
        additionalParams.length > 0
      ) {
        additionalParams.forEach((objProp) => {
          listServiceRequest[objProp.key] = objProp.value;
        });
      }

      repositoryActions
        .postDataAndGetResponse(
          "CareTeam/RelatedList",
          listServiceRequest,
          null,
          ContentType.applicationJson
        )
        .then((response) => {
          if (isNotNullAndUndefined(response)) {
            if (response.status === 204) {
              resolve(null);
            } else if (isNotNullAndUndefined(response.data)) {
              if (isNotNullAndUndefined(response.data.Entity)) {
                resolve(response.data.Entity);
              } else if (isNotEmptyArray(response.data.ResultTable)) {
                resolve(response.data.ResultTable);
              } else if (isNotNullAndUndefined(response.data.Entities)) {
                resolve(response.data.Entities);
              } else {
                resolve(null);
              }
            }
          } else {
            resolve(null);
          }
        });
    } else {
      resolve(null);
    }
  });
};

export const isAssigneeExistInCareTeam = (
  careRecipientUID: string,
  assigneeUID: string,
  sortQuery?: string[]
) => {
  return new Promise<boolean>((resolve) => {
    let customFilter: RAFCustomFilter = {};
    customFilter.Condition = "and";
    customFilter.Rules = [];

    let assigneeFilter: RAFCustomFilter = {};
    let assigneeFilterVal: string[] = [];
    assigneeFilterVal.push(assigneeUID);
    assigneeFilter.Operator = RAFCustomOperator.Equal;
    assigneeFilter.Value = assigneeFilterVal;
    assigneeFilter.Field = "NameUID";
    customFilter.Rules.push(assigneeFilter);

    let listServiceRequest = new ListServiceRequest();
    listServiceRequest.CustomFilter = customFilter;
    listServiceRequest.Take = 1;
    listServiceRequest.Skip = 0;

    if (isNotEmptyArray(sortQuery)) {
      listServiceRequest.Sort = sortQuery;
    }

    let additionalParams = [
      {
        key: "RelatedField",
        value: propertyOf<CareTeamRow>("CareRecipientUID"),
      },
      {
        key: "RelatedFieldValue",
        value: careRecipientUID,
      },
    ];

    if (
      isNotNullAndUndefined(additionalParams) &&
      additionalParams.length > 0
    ) {
      additionalParams.forEach((objProp) => {
        listServiceRequest[objProp.key] = objProp.value;
      });
    }

    repositoryActions
      .postDataAndGetResponse(
        "CareTeam/RelatedList",
        listServiceRequest,
        null,
        ContentType.applicationJson
      )
      .then((response) => {
        if (isNotNullAndUndefined(response)) {
          if (response.status === 204) {
            resolve(false);
          } else if (isNotNullAndUndefined(response.data)) {
            if (isNotNullAndUndefined(response.data.Entities)) {
              if (isNotEmptyArray(response.data.Entities)) {
                resolve(true);
              } else {
                resolve(false);
              }
            } else {
              resolve(false);
            }
          }
        } else {
          resolve(false);
        }
      });
  });
};

export const IsUserExistInCareTeam = (
  careRecipientUID: string,
  userUID: string,
) => {
  return new Promise<boolean>((resolve) => {
    if (isNotNullAndUndefined(careRecipientUID) && isNotNullAndUndefined(userUID)) {
      const careTeamExistsParams = {
        'CareRecipientUID': careRecipientUID,
        'UserUID': userUID
      };

      repositoryActions
        .postDataAndGetResponse(
          "CareTeam/Exists",
          careTeamExistsParams,
          null,
          ContentType.applicationJson
        )
        .then((response) => {
          if (isNotNullAndUndefined(response.data) && isNotNullAndUndefined(response.data) && response.data.Exists === true) {
            resolve(true);
          } else {
            resolve(false);
          }
        });
    } else {
      resolve(true);
    }
  });
};

export const addUserToCareTeam = (
  careRecipient: string,
  careRecipientUID: string,
  userUID: string,
) => {
  return new Promise<{ response: boolean, warningMessage: string; }>((resolve) => {
    const warningMessage = "Apologies, unable to add the user to the care team. Please retry or reach out to the administrator for assistance";
    if (isNotNullAndUndefined(careRecipient) && isNotNullAndUndefined(careRecipientUID) && isNotNullAndUndefined(userUID)) {
      const entity = {
        "CareRecipient": careRecipient,
        "CareRecipientUID": careRecipientUID,
        "UserUID": userUID,
        "IsPrimary": false,
      };

      repositoryActions
        .postDataAndGetResponse(
          "CareTeam/SaveByUser",
          { Entity: entity },
          null,
          ContentType.applicationJson
        )
        .then((response) => {
          if (
            isNotNullAndUndefined(response) &&
            isNotNullAndUndefined(response.data)
          ) {
            if (isNotNullAndUndefined(response.data.EntityId)) {
              resolve({ response: true, warningMessage: null });
            } else if (isNotNullAndUndefined(response.data.Error) && isNotNullAndUndefined(response.data.Error.Message)) {
              const errorMessage = response.data.Error.Message;
              resolve({ response: false, warningMessage: errorMessage ?? warningMessage });
            }
          } else {
            resolve({ response: false, warningMessage: warningMessage });
          }
        })
        .catch((error) => resolve({ response: false, warningMessage: warningMessage }));
    } else {
      resolve({ response: false, warningMessage: warningMessage });
    }
  });
};

export const addCareTeamMember = (careTeamRow: CareTeamRow) => {
  return new Promise<boolean>((resolve) => {
    if (isNotNullAndUndefined(careTeamRow)) {
      let saveRequest: SaveRequest<CareTeamRow>[] = [];
      saveRequest.push({ Entity: careTeamRow });

      repositoryActions
        .postDataAndGetResponse(
          "CareTeam/Add",
          saveRequest,
          null,
          ContentType.applicationJson
        )
        .then((response) => {
          if (
            isNotNullAndUndefined(response) &&
            isNotEmptyArray(response.data)
          ) {
            resolve(true);
          } else {
            resolve(false);
          }
        })
        .catch((error) => resolve(false));
    } else {
      resolve(false);
    }
  });
};
