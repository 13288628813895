import * as R from "ramda";
import React, { PropsWithChildren } from "react";
import { FormRenderProps } from "react-final-form";
import RAFDateTimePicker from "../../../../../RAFComponents/Inputs/RAFDateTimePicker";
import RAFForm from "../../../../../RAFComponents/Inputs/RAFForm";
import RAFLookUpMUI from "../../../../../RAFComponents/Inputs/RAFLookUpMUI";
import RAFButtonComponent from "../../../../../RAFComponents/Navigation/RAFButtonComponent";
import RAFDeletedRecordState from "../../../../../RAFComponents/Navigation/RAFDeletedRecordState";
import RAFAttributeRelatedListProvider, {
  RAFAttributesContext,
} from "../../../../../RAFComponents/Providers/RAFAttributeRelatedListProvider";
import {
  showSuccessToast,
  showWarningToast,
} from "../../../../../RAFComponents/Utility/RAFToastComponent";
import RAFPermissionRender from "../../../../../RAFComponents/helpers/PermissionHelper";
import {
  IDialogProps,
  IsNotNullOrWhiteSpace,
  getTimeDifferenceInMinutes,
  isNotNullAndUndefined,
} from "../../../../../RAFComponents/helpers/utils";
import { EvaluateScore } from "../../../../../RAFMaster/helpers/RMutils";
import { PayrollTransactionPermissionConstants } from "../../../../../constants/CareESIO/CareESIOPermissionConstant";
import {
  Constants,
  RAFButtonConstant,
  RAFLayout,
  RAFShiftActionStatus,
} from "../../../../../constants/Common/Constants";
import { TimeSheetSummaryModuleName } from "../../../../ActiveContacts/ServiceTransaction/ServiceTimesheetSummary/ServiceTimesheetSummaryHelper";
import RAFEntityProvider, {
  RAFEntityContext,
} from "../../../../Common/Providers/RAFEntityProvider";
import RAFObjectContextProvider, {
  RAFObjectContext,
} from "../../../../Common/Providers/RAFObjectContextProvider";

interface IProps {
  onDelete?: () => void;
  objectUID: string;
}

function UpdatePayrollShiftItem({
  ...props
}: PropsWithChildren<IProps & IDialogProps>) {
  let rafForm: FormRenderProps | null;

  const moduleName = TimeSheetSummaryModuleName.PayrollTransaction;

  const onSubmitObject = (
    values,
    entity,
    queryAttributes,
    saveObject?: (
      objectData?: any
    ) => Promise<{ objectUID?: string; objectName?: string; }>
  ) => {
    let updatedValues = R.clone(values);
    if (
      isNotNullAndUndefined(entity) &&
      isNotNullAndUndefined(entity.EntitySettings) &&
      isNotNullAndUndefined(entity.EntitySettings.EnableScore) &&
      entity.EntitySettings.EnableScore === true
    ) {
      updatedValues = EvaluateScore(values, queryAttributes);
    }

    const actualDuration = getTimeDifferenceInMinutes(
      updatedValues.ActualStartDate,
      updatedValues.ActualEndDate
    );
    updatedValues.ActualDuration = actualDuration;

    saveObject(updatedValues)
      .then((response) => {
        if (
          isNotNullAndUndefined(response) &&
          isNotNullAndUndefined(response.objectUID)
        ) {
          showSuccessToast("Saved successfully");

          if (props.onSave)
            props.onSave(response.objectUID, response.objectName);
        } else {
          showWarningToast("Sorry something went wrong !");
        }
      })
      .catch((error) => error);
  };

  const isActualStartDateGreaterThanActualEndDate = (
    endDateFieldValue,
    allFormValue
  ) => {
    let hasError = false,
      error = "";
    if (IsNotNullOrWhiteSpace(allFormValue)) {
      const startDateFieldValue = allFormValue["ActualStartDate"];
      if (
        isNotNullAndUndefined(startDateFieldValue) &&
        IsNotNullOrWhiteSpace(endDateFieldValue)
      ) {
        const startDate = new Date(startDateFieldValue);
        const endDate = new Date(endDateFieldValue);

        if (
          isNotNullAndUndefined(startDate) &&
          isNotNullAndUndefined(endDate)
        ) {
          if (startDate > endDate) {
            hasError = true;
            error = "End date should be greater than start date";
          }
        }
      }
    }

    return hasError ? error : undefined;
  };

  const isActualStartDateLessThan24hrs = (endDateFieldValue, allFormValue) => {
    let hasError = false,
      error = "";
    if (IsNotNullOrWhiteSpace(allFormValue)) {
      const startDateFieldValue = allFormValue["ActualStartDate"];
      if (
        isNotNullAndUndefined(startDateFieldValue) &&
        IsNotNullOrWhiteSpace(endDateFieldValue)
      ) {
        const startDate = new Date(startDateFieldValue);
        const endDate = new Date(endDateFieldValue);

        if (
          isNotNullAndUndefined(startDate) &&
          isNotNullAndUndefined(endDate)
        ) {
          if (getTimeDifferenceInMinutes(startDate, endDate) > 1440) {
            hasError = true;
            error = "End date should be within 24 hours from start date";
          }
        }
      }
    }

    return hasError ? error : undefined;
  };

  const getDateContent = () => {
    return (
      <div className="row gx-0 gy-3">
        <div className="col-md-12">
          <RAFDateTimePicker
            field={"ActualStartDate"}
            label="Start Date"
            required
            interval={15}
            roundOff
          />
        </div>
        <div className="col-md-12">
          <RAFDateTimePicker
            field={"ActualEndDate"}
            label="End Date"
            required
            interval={15}
            roundOff
            validators={[
              isActualStartDateGreaterThanActualEndDate,
              isActualStartDateLessThan24hrs,
            ]}
          />
        </div>
      </div>
    );
  };

  const getInputFormContent = (rafObject) => {
    return (
      <div className="row gx-0 gy-3">
        {getDateContent()}
        <div className="col-md-12">
          <RAFLookUpMUI
            field="PayLoading"
            label="Pay Item"
            placeholder="Select Pay Item"
            url="DataList/Lookup"
            moduleName={"pay_item"}
            showLabel
            closeToolTip={false}
            showFullList={false}
            required
          />
        </div>
      </div>
    );
  };

  if (props.isActive) {
    return (
      <div
        className="h-100"
        key={moduleName}
        id={`raf_dlg_Outer_Div_${moduleName}`}
      >
        <RAFEntityProvider moduleName={moduleName}>
          <RAFAttributeRelatedListProvider moduleName={moduleName}>
            <RAFAttributesContext.Consumer>
              {({ queryAttributes }) => {
                return (
                  <RAFEntityContext.Consumer>
                    {({ entity, formLibrary }) => {
                      return (
                        <RAFObjectContextProvider
                          moduleName={moduleName}
                          objectId={props.objectUID}
                          progressDivId={`#raf_dlg_Outer_Div_${moduleName}`}
                        >
                          <RAFObjectContext.Consumer>
                            {({ rafObject, error, saveObject }) => {
                              const initialValues =
                                isNotNullAndUndefined(rafObject) &&
                                  isNotNullAndUndefined(rafObject.UID)
                                  ? rafObject
                                  : null;

                              if (isNotNullAndUndefined(initialValues)) {
                                if (
                                  initialValues.Status ===
                                  RAFShiftActionStatus.Completed
                                ) {
                                  return (
                                    <RAFForm
                                      initialValues={initialValues}
                                      formRef={(g) => (rafForm = g)}
                                      layout={RAFLayout.TwoColumnLayout}
                                      onSubmit={(values) =>
                                        onSubmitObject(
                                          values,
                                          entity,
                                          queryAttributes,
                                          saveObject
                                        )
                                      }
                                      primaryKey={initialValues.UID}
                                      className="h-100"
                                    >
                                      <div className="e-dlg-content-outer">
                                        <div className="e-dlg-body-content">
                                          {getInputFormContent(rafObject)}
                                        </div>
                                        <div className="e-dlg-footerContent">
                                          <div className="w-100">
                                            <div className="row gx-2">
                                              <div className="col-auto ms-auto">
                                                <RAFPermissionRender
                                                  permissionName={PayrollTransactionPermissionConstants.PayrollTransactionManageTimeSheet}
                                                >
                                                  <RAFButtonComponent
                                                    isPrimary
                                                    action={
                                                      RAFButtonConstant.Save
                                                    }
                                                    onClick={() =>
                                                      rafForm &&
                                                      rafForm.form.submit()
                                                    }
                                                    idString="EditContent"
                                                  />
                                                </RAFPermissionRender>
                                              </div>
                                              <div className="col-auto">
                                                <RAFButtonComponent
                                                  action={
                                                    RAFButtonConstant.Cancel
                                                  }
                                                  onClick={props.onClose}
                                                  idString="EditContent"
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </RAFForm>
                                  );
                                } else {
                                  return (
                                    <div className="container-fluid px-0">
                                      <RAFDeletedRecordState title="Shift is not completed. You cannot update this timesheet." />
                                    </div>
                                  );
                                }
                              } else {
                                if (error === Constants.RAFObject_NoContent) {
                                  return (
                                    <div className="container-fluid px-0">
                                      <RAFDeletedRecordState title="This item is either deleted or You do not have sufficient privileges to view this item." />
                                    </div>
                                  );
                                } else {
                                  return (
                                    <div
                                      className="container-fluid px-0"
                                      style={{ background: "transparent" }}
                                    >
                                      {/* <ACLoadingPanel loadingText="Loading..." /> */}
                                    </div>
                                  );
                                }
                              }
                            }}
                          </RAFObjectContext.Consumer>
                        </RAFObjectContextProvider>
                      );
                    }}
                  </RAFEntityContext.Consumer>
                );
              }}
            </RAFAttributesContext.Consumer>
          </RAFAttributeRelatedListProvider>
        </RAFEntityProvider>
      </div>
    );
  } else {
    return <div></div>;
  }
}

export default React.memo(UpdatePayrollShiftItem);
