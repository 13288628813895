import { CheckBoxComponent } from "@syncfusion/ej2-react-buttons";
import { DialogComponent, DialogUtility } from "@syncfusion/ej2-react-popups";
import DOMPurify from "dompurify";
import moment from "moment";
import React, {
  PropsWithChildren,
  Reducer,
  useEffect,
  useReducer,
} from "react";
import { IconPickerItem } from "react-fa-icon-picker";
import { PreventFocusOnDialogOpen } from "../../../RAFComponents/Dialog/SFDialogUtils";
import CustomCardWidget from "../../../RAFComponents/Navigation/CustomCardWidget";
import RAFButtonComponent from "../../../RAFComponents/Navigation/RAFButtonComponent";
import RAFCollapse from "../../../RAFComponents/Navigation/RAFCollapse";
import RAFDetailFieldCustom from "../../../RAFComponents/Navigation/RAFDetailFieldCustom";
import RAFDetailsValueWithSeparator from "../../../RAFComponents/Navigation/RAFDetailsValueWithSeparator/RAFDetailsValueWithSeparator";
import RAFIconImage from "../../../RAFComponents/Navigation/RAFIconImage";
import RAFRecordInfo from "../../../RAFComponents/Navigation/RAFRecordInfo";
import { showWarningToast } from "../../../RAFComponents/Utility/RAFToastComponent";
import {
  DeleteRecord,
  getFields,
  hideProgress,
  isHTML,
  showProgress,
} from "../../../RAFComponents/helpers/AppHelper";
import {
  IsNotNullOrWhiteSpace,
  convertUTCDateToLocalTimezone,
  getTimeDifferenceInUserReadableText,
  getUTCDateValue,
  hexToRGBA,
  isArray,
  isEmptyOrNull,
  isNotEmptyArray,
  isNotNullAndUndefined,
  isNullOrUndefined,
} from "../../../RAFComponents/helpers/utils";
import { FormLibraryRow } from "../../../RAFComponents/models/Common/FormLibraryRow";
import {
  QueryAttributeJM,
  ValueJson,
} from "../../../RAFComponents/models/Common/QueryAttributeJM";
import {
  RAFDataType,
  RAFUIType,
} from "../../../RAFComponents/models/Common/RAFDataType";
import { EntityRow } from "../../../RAFMaster/RMModules/Entity/EntityRow";
import { CareEsioEntity } from "../../../constants/CareESIO/CareEsioConstants";
import {
  BrowserIsDevice,
  RAFActionStatus,
  RAFButtonConstant,
  RAFShiftActionStatus,
  RAFStatusNameWithColor,
} from "../../../constants/Common/Constants";
import { DownloadAttachment } from "../../ActiveContacts/Document/Library/DocumentHelper";
import ManageShiftClockAction from "../../ActiveContacts/ServiceTransaction/ServiceTransactionActions/ManageShiftClockAction";
import {
  RetrieveServiceTransactionById,
  checkHasPermissionToAddOrEditActivitylog,
} from "../../ActiveContacts/ServiceTransaction/ServiceTransactionHelper";
import { ServiceTransactionRow } from "../../ActiveContacts/ServiceTransaction/ServiceTransactionRow";
import { getUserTenantSettings } from "../../Common/TenantSettings/TenantSettingsHelper";
import {
  ShiftActivityTitle,
  ShiftActivityTitleDisplayName,
} from "./CareShiftLogHelper";
import ManageClientNote from "./ClientNote/ManageClientNote";
import UpdateCareShiftLog from "./UpdateCareShiftLog";

import "./CareShiftLog.scss";
import CareShiftLogAttachments from "./CareShiftLogAttachments";
import CareShiftLogDetails from "./CareShiftLogDetails";

interface IProps {
  careShiftLogUID?: string; //ShiftLogUID
  careRecipientUID?: string;
  careShiftLogRow?: any;
  carePlanSectionEntity?: EntityRow;
  onSave?: () => void;
  IsCollapsed?: boolean;
  showRelatedSections?: boolean;
  allForms?: FormLibraryRow[];
  careEventRow?: ServiceTransactionRow;
  allowEdit: boolean;
  allowDelete: boolean;
  isReporting?: boolean;
}

interface IState {
  showUpdateCareShiftLogContent: boolean;
  showCreateShiftLogsContent: boolean;
  showCareShiftLogDetailsContent: boolean;
  showUpdateClockContent: boolean;
  showUpdateClientNoteContent: boolean;
  taskAction: "Clock In" | "Clock Out";
  careEventRow: ServiceTransactionRow;
  viewAttributes: QueryAttributeJM[];
  checkboxDivkey: number;
}

function CareShiftLogSection({
  careShiftLogRow,
  careShiftLogUID,
  allForms,
  allowEdit = true,
  allowDelete = true,
  isReporting = false,
  ...props
}: PropsWithChildren<IProps>) {
  const careShiftLogModule = CareEsioEntity.CareShiftLog.EntityName;

  let deleteDialog: any;

  const [state, setState] = useReducer<Reducer<IState, Partial<IState>>>(
    (state, newState) => ({ ...state, ...newState }),
    {
      showUpdateCareShiftLogContent: false,
      showCreateShiftLogsContent: false,
      showCareShiftLogDetailsContent: false,
      showUpdateClockContent: false,
      taskAction: null,
      showUpdateClientNoteContent: false,
      careEventRow: null,
      viewAttributes: null,
      checkboxDivkey: Math.random(),
    }
  );

  useEffect(() => {
    if (isReporting === true) {
      getViewAttributes();
    }
  }, [isReporting]);

  const getViewAttributes = async () => {
    let viewAttributes = await getFields(careShiftLogRow.log_type);
    setState({ viewAttributes });
  };

  //delete item start

  let selectedFormLibrary: FormLibraryRow = null;
  if (
    isNotEmptyArray(allForms) &&
    isNotNullAndUndefined(careShiftLogRow) &&
    isNotNullAndUndefined(careShiftLogRow.log_type)
  ) {
    selectedFormLibrary = allForms.find(
      (x) => x.Entity === careShiftLogRow.log_type
    );
  }

  const moduleName =
    isNotNullAndUndefined(careShiftLogRow) &&
      isNotNullAndUndefined(careShiftLogRow.log_type)
      ? careShiftLogRow.log_type
      : CareEsioEntity.CareShiftLog.EntityName;

  const onClickDeleteCareShiftLog = () => {
    let title = isNotNullAndUndefined(selectedFormLibrary)
      ? selectedFormLibrary.Title
      : CareEsioEntity.CareShiftLog.DisplayName;
    deleteDialog = DialogUtility.confirm({
      animationSettings: { effect: "Fade" },
      cancelButton: { text: "No", cssClass: "form-custom-button" },
      closeOnEscape: false,
      content: "Are you sure want to delete?",
      okButton: {
        text: "Yes",
        click: deleteRecord.bind(this),
        cssClass: "form-custom-button",
      },
      title: `Delete ${title}`,
      position: { X: "center", Y: "center" },
      cssClass: `alert-dialog deleteDialog`,
    });
  };

  async function deleteRecord() {
    let progressDiv = showProgress(".deleteDialog.e-dialog");

    const tenantSettings = await getUserTenantSettings();
    const hasPermissionToAddOrEditActivitylog =
      checkHasPermissionToAddOrEditActivitylog(
        tenantSettings,
        props.careEventRow
      );
    if (
      isNotNullAndUndefined(hasPermissionToAddOrEditActivitylog) &&
      !hasPermissionToAddOrEditActivitylog.hasPermissionToEdit
    ) {
      showWarningToast(
        `${hasPermissionToAddOrEditActivitylog.warningMessage}`,
        null,
        5000
      );
      hideProgress(progressDiv);
      deleteDialog.hide();
      return;
    }

    let isDeleted = await DeleteRecord(careShiftLogRow.UID, moduleName);

    if (isDeleted) {
      hideProgress(progressDiv);
      deleteDialog.hide();
      refreshOnUpdate();
    } else {
      hideProgress(progressDiv);
      deleteDialog.hide();
      showWarningToast("Sorry something went wrong !");
    }
  }
  //delete item end

  //update CareShiftLog_start
  const editCareShiftLog = () => {
    const { careEventRow } = props;
    const status = isNotNullAndUndefined(careEventRow)
      ? careEventRow.Status
      : null;
    if (
      status === RAFShiftActionStatus.Unassigned ||
      status === RAFShiftActionStatus.Accepted ||
      status === RAFShiftActionStatus.OfferSent ||
      status === RAFShiftActionStatus.Scheduled
    ) {
      showWarningToast(
        "Start the shift to edit shift log."
        //"The task cannot be completed until the shift has started."
      );
      return;
    }

    if (
      isNotNullAndUndefined(careShiftLogRow) &&
      (careShiftLogRow.title === ShiftActivityTitle.StartedShift ||
        careShiftLogRow.title === ShiftActivityTitle.CompletedShift)
    ) {
      showShiftClockContent();
    } else if (
      isNotNullAndUndefined(careShiftLogRow) &&
      careShiftLogRow.log_type === CareEsioEntity.ClientNote.EntityName
    ) {
      showUpdateClientNoteContent();
    } else {
      setState({ showUpdateCareShiftLogContent: true });
    }
  };

  function updateCareShiftLogContent() {
    if (state.showUpdateCareShiftLogContent === true) {
      const { careEventRow } = props;
      return (
        <UpdateCareShiftLog
          objectUID={careShiftLogRow.UID}
          onSave={refreshOnUpdate.bind(this)}
          onClose={closeUpdateCareShiftLogDialog.bind(this)}
          onDelete={refreshOnUpdate.bind(this)}
          selectedServiceTransactionRow={careEventRow}
          allForms={allForms}
          isActive
        />
      );
    } else {
      return <div></div>;
    }
  }

  const closeUpdateCareShiftLogDialog = () => {
    setState({
      showUpdateCareShiftLogContent: false,
      checkboxDivkey: Math.random(),
    });
  };

  const refreshOnUpdate = () => {
    setState({
      showUpdateCareShiftLogContent: false,
      showUpdateClockContent: false,
      showUpdateClientNoteContent: false,
    });
    if (props.onSave) {
      props.onSave();
    }
  };
  //update CareShiftLog_end

  //details careshiftlog start
  const detailsCareShiftLog = () => {
    setState({
      showCareShiftLogDetailsContent: true,
    });
  };
  const closeCareShiftLogDetailsDialog = () => {
    setState({
      showCareShiftLogDetailsContent: false,
    });
  };

  function careShiftLogDetailsContent() {
    if (state.showCareShiftLogDetailsContent) {
      return (
        <CareShiftLogDetails
          objectUID={careShiftLogRow.UID}
          moduleName={careShiftLogRow.log_type}
          onSave={refreshOnUpdate.bind(this)}
          onClose={closeCareShiftLogDetailsDialog.bind(this)}
          isActive
        />
      );
    } else {
      return <div></div>;
    }
  }
  //details careshiftlog end

  //taskClockAction start
  const showShiftClockContent = async () => {
    let careEventRow;
    if (isNotNullAndUndefined(careShiftLogRow)) {
      if (isNotNullAndUndefined(props.careEventRow)) {
        careEventRow = props.careEventRow;
      } else {
        careEventRow = await RetrieveServiceTransactionById(
          careShiftLogRow.taskuid
        );
      }
      const taskAction =
        careShiftLogRow.title === ShiftActivityTitle.StartedShift
          ? "Clock In"
          : "Clock Out";
      setState({ showUpdateClockContent: true, taskAction, careEventRow });
    }
  };

  const shiftClockContent = () => {
    if (state.showUpdateClockContent) {
      const { taskAction, careEventRow } = state;
      const minTimeValue = getUTCDateValue(
        careEventRow && careEventRow.StartDate
      );

      const maxTimeValue = getUTCDateValue(
        careEventRow && careEventRow.EndDate
      );

      const taskDateValue = careShiftLogRow.start_date;

      console.log("careShiftLogRow", careShiftLogRow);

      return (
        <ManageShiftClockAction
          shiftActivityObjectUID={careShiftLogRow.UID}
          selectedServiceTransactionRow={careEventRow && careEventRow}
          taskAction={taskAction}
          taskDateValue={taskDateValue}
          onSave={refreshOnUpdate.bind(this)}
          onClose={taskClockActionDialogClose}
          minTimeValue={minTimeValue}
          maxTimeValue={maxTimeValue}
          isActive
          isEdit={
            careShiftLogRow.status === RAFActionStatus.Done ? true : false
          }
        />
      );
    } else {
      return <div></div>;
    }
  };

  const taskClockActionDialogClose = () => {
    setState({
      showUpdateClockContent: false,
    });
  };
  //taskClockAction end

  //update ClientNote_start
  const showUpdateClientNoteContent = () => {
    setState({ showUpdateClientNoteContent: true });
  };

  const updateClientNoteContent = () => {
    if (isNotNullAndUndefined(state.showUpdateClientNoteContent)) {
      return (
        <ManageClientNote
          objectUID={careShiftLogRow.UID}
          onSave={refreshOnUpdate.bind(this)}
          onClose={closeUpdateClientNoteDialog.bind(this)}
          onDelete={refreshOnUpdate.bind(this)}
          careRecipientUID={props.careRecipientUID}
          isActive
          {...props}
        />
      );
    } else {
      return <div></div>;
    }
  };

  const closeUpdateClientNoteDialog = () => {
    setState({ showUpdateClientNoteContent: false });
  };

  //update ClientNote_end
  const carePlanSectionCustomButton = () => {
    return (
      <div
        className="row g-2 flex-nowrap align-items-center"
        id="divHeaderButtons"
      >
        {/* <div className="col-auto d-none d-md-block">
                    <ButtonComponent
                        type="button"
                        className="primary-custom-button"
                        onClick={() => editCareShiftLog()}
                        content={RAFBtnIdString.Edit}
                        id={`btn_${RAFBtnIdString.Edit}`}
                        iconCss={RAFBtnIconCss.Edit}
                    />
                </div> */}

        <div className="col-auto">
          {/* <div className="col-auto d-md-none"> */}
          <RAFButtonComponent
            action={RAFButtonConstant.Edit}
            onClick={() => editCareShiftLog()}
            // className={BrowserIsDevice ? "custom-button-sm mb-1" : "link-button mb-1"}
            className={
              BrowserIsDevice
                ? "custom-button-sm mb-1"
                : "primary-custom-button custom-button-sm mb-1"
            }
            {...(BrowserIsDevice ? { iconBtn: true } : {})}
          ></RAFButtonComponent>
        </div>
      </div>
    );
  };

  const carePlanSectionCustomTitle = () => {
    const iconName = isNotNullAndUndefined(careShiftLogRow.Icon)
      ? careShiftLogRow.Icon
      : null;

    return (
      <div id={"loginMethods_" + careShiftLogRow.UID} className="actionSection">
        <div className="d-flex align-items-center pointer">
          {isEmptyOrNull(iconName) ? (
            <div className="">
              <RAFIconImage
                iconCssClass={"fa fa-group-arrows-rotate"}
                moduleavatar="raf-avatar raf-avatar-sm circle bg-transparent-avatar"
              ></RAFIconImage>
            </div>
          ) : (
            <div className="">
              <span className="raf-avatar raf-avatar-sm circle">
                <IconPickerItem icon={iconName} size={"1em"} color={"#333"} />
              </span>
            </div>
          )}
          <div className="ps-2 me-auto">
            <div className="d-flex align-items-center">
              <span className="cardTitle ecllipseFirstLine word-break-all">
                {careShiftLogRow.form_library}
                {/* {`Log updated by ${isNotNullAndUndefined(careShiftLogRow.CreatedBy) ? careShiftLogRow.CreatedBy : "Not set"}`} */}
              </span>
            </div>
            <div className="detailsLeftCard-second-title h-auto">
              <div className="w-100">
                <div className="row gx-2 flex-nowrap align-items-center">
                  <div className="col-auto detailsLeftCard-second-title-col">
                    <div className="detailsLeftCard-second-title-item">
                      {`Updated by: ${careShiftLogRow.ModifiedBy}`}
                    </div>
                  </div>
                  <div className="col-auto detailsLeftCard-second-title-col">
                    <div
                      className="detailsLeftCard-second-title-item"
                      onClick={(e) => e.stopPropagation()}
                    >
                      <RAFRecordInfo
                        createdBy={careShiftLogRow.CreatedBy}
                        createdDate={careShiftLogRow.CreatedDate}
                        modifiedDate={careShiftLogRow.ModifiedDate}
                        modifiedBy={careShiftLogRow.ModifiedBy}
                        lastActivityDate={careShiftLogRow.LastActivityDate}
                        hideClockIcon
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {carePlanSectionCustomButton()}
        </div>
      </div>
    );
  };

  function getDetailsElement(
    value: any,
    queryAttrib: QueryAttributeJM,
    visible: boolean,
    showLabel: boolean,
    navigationEnabled: boolean,
    onNavigationClicked: (relatedEntities: string, refUID: string) => void,
    type?: "Details" | "default"
    // onNavigationClicked: (url) => void
  ) {
    let retVal = <></>;
    if (isNullOrUndefined(queryAttrib)) {
      return retVal;
    }
    if (queryAttrib.AttributeName === "instruction") {
      queryAttrib.UIType = RAFUIType.HtmlEditor;
    }
    let dataType: string = queryAttrib.DataType;
    let compValue = isNotNullAndUndefined(value) ? value : null;
    if (dataType === RAFDataType.Date && isNotNullAndUndefined(compValue)) {
      let dateValue = convertUTCDateToLocalTimezone(compValue);
      compValue = moment(dateValue).format("DD/MM/YYYY").toString();
    }

    if (dataType === RAFDataType.DateTime && isNotNullAndUndefined(compValue)) {
      let dateValue = convertUTCDateToLocalTimezone(compValue);
      compValue = moment(dateValue).format("DD/MM/YYYY hh:mm A").toString();
    }
    compValue = compValue as string;
    let displayName: string = queryAttrib.DisplayName;
    let relatedEntities = queryAttrib.RelatedEntities;

    // let refUID: string = isNotNullAndUndefined(objectData)
    //   ? objectData[compName + "UID"]
    //   : null;

    if (isNullOrUndefined(showLabel)) {
      showLabel = true;
    }
    let showComponent = false;
    if (visible === true) {
      showComponent = true;
    }

    let objRow: ValueJson;

    if (queryAttrib.DataType === RAFDataType.Dropdown) {
      if (queryAttrib.IsMultiSelect === true) {
        let objectValue: string[] = JSON.parse(compValue);
        let retVal: string[] = [];
        if (isArray(objectValue)) {
          objectValue.forEach((item) => {
            let obj =
              queryAttrib.ValueJson &&
              queryAttrib.ValueJson.find(
                (x) => x.DisplayName === item || x.Name === item
              );
            retVal.push(obj && obj.DisplayName);
          });
          compValue = retVal && retVal.join(", ");
        }
      } else {
        objRow =
          queryAttrib.ValueJson &&
          queryAttrib.ValueJson.find(
            (x) => x.DisplayName === compValue || x.Name === compValue
          );
        if (isNotNullAndUndefined(compValue) && compValue) {
          let selectedValueJson = queryAttrib.ValueJson;
          if (isNotNullAndUndefined(selectedValueJson)) {
            let selectedItem = selectedValueJson.find(
              (x) => x.Name === compValue || x.DisplayName === compValue
            );
            compValue = isNotNullAndUndefined(selectedItem)
              ? selectedItem.DisplayName
              : compValue;
          }
        }
      }
    }

    if (
      queryAttrib.DataType === RAFDataType.Lookup &&
      queryAttrib.IsMultiSelect === true
    ) {
      let lookUpValue =
        isNotNullAndUndefined(compValue) &&
          isArray(compValue) &&
          compValue.length > 0
          ? compValue.map((x) => x.Value)
          : null;
      compValue = isNotNullAndUndefined(lookUpValue)
        ? lookUpValue.toString()
        : null;
    }

    if (queryAttrib.DataType === RAFDataType.Boolean) {
      if (isNotNullAndUndefined(compValue) && compValue) {
        compValue = "Yes";
      } else {
        compValue = "No";
      }
    }

    let hasHtml = isHTML(compValue);

    if (showComponent) {
      //retVal = <RAFDetailField valueDivClassName="pb-0 pt-0" valueClassName="m-0 detailsValue" value={objectData && objectData[compName]}></RAFDetailField>;
      //retVal = <RAFDetailFieldEditableWithIcon title={displayName} iconName={compName} disabled value={objectData && objectData[compName]} onChange={(e) => this.onChange(compName, e)} type={queryAttrib.DataType} name={compName}></RAFDetailFieldEditableWithIcon>
      retVal = (
        <div className={`row gx-0 gy-1`}>
          {showLabel && (
            <div className="col-12">
              <span className={"profile_card_details_label ecllipseFirstLine"}>
                {`${displayName} :`}
              </span>
            </div>
          )}
          <div className="col-12">
            <div
              className={`${queryAttrib.DataType === RAFDataType.Text &&
                (queryAttrib.UIType === RAFUIType.Multiline || hasHtml)
                ? "description-text "
                : ""
                }details-value d-flex align-items-center justify-content-start`}
            >
              {queryAttrib.UIType === RAFUIType.HtmlEditor || hasHtml ? (
                <span
                  dangerouslySetInnerHTML={{
                    __html:
                      compValue !== null
                        ? DOMPurify.sanitize(compValue)
                        : "Not set",
                  }}
                ></span>
              ) : (
                <span
                  className={
                    type === "Details"
                      ? `${queryAttrib.DataType === RAFDataType.Text &&
                        queryAttrib.UIType === RAFUIType.Multiline
                        ? "description-text "
                        : ""
                      }details-value`
                      : isNotNullAndUndefined(objRow) &&
                        isNotNullAndUndefined(objRow.ColorCode) &&
                        compValue !== "None"
                        ? "raf_badge"
                        : "details-value"
                  }
                  {...(isNotNullAndUndefined(objRow) &&
                    isNotNullAndUndefined(objRow.ColorCode) &&
                    compValue !== "None"
                    ? {
                      style: {
                        background: hexToRGBA(objRow.ColorCode, 0.1),
                        color: objRow.ColorCode,
                        border: `1px solid ${hexToRGBA(
                          objRow.ColorCode,
                          0.5
                        )}`,
                      },
                    }
                    : {})}
                // onClick={() =>
                //   isNotNullAndUndefined(navigationEnabled) &&
                //   navigationEnabled === true
                //     ? onNavigationClicked(
                //         relatedEntities,
                //         refUID
                //         //"/RAF/View/" + relatedEntities + "/" + refUID
                //       )
                //     : null
                // }
                // {...(queryAttrib.UIType === RAFUIType.HtmlEditor
                //   ? {
                //       dangerouslySetInnerHTML: {
                //         __html:
                //           compValue !== null
                //             ? DOMPurify.sanitize(compValue)
                //             : "Not set",
                //       },
                //     }
                //   : {})}
                // dangerouslySetInnerHTML={{
                //   __html:
                //     compValue !== null
                //       ? DOMPurify.sanitize(compValue)
                //       : "Not set",
                // }}
                >
                  {compValue !== null && compValue !== ""
                    ? compValue
                    : "Not set"}
                </span>
              )}
            </div>
          </div>
        </div>
      );
    }
    return retVal;
  }

  const logAdditionalDetails = () => {
    let { viewAttributes } = state;
    let filteredAttributes =
      viewAttributes &&
      viewAttributes.filter(
        (x) =>
          x.ShowInSelect === true &&
          x.ShowInWhere === true &&
          x.DataType !== RAFDataType.AutoNumber &&
          x.AttributeName !== "assignee" &&
          x.AttributeName !== "title" &&
          x.AttributeName !== "description" &&
          x.AttributeName !== "role_permission_name"
      );

    const uniqueFilteredAttributes: QueryAttributeJM[] =
      filteredAttributes &&
      filteredAttributes.reduce((unique, item) => {
        if (
          !unique.some(
            (uniqueItem) => uniqueItem.AttributeName === item.AttributeName
          )
        ) {
          unique.push(item);
        }
        return unique;
      }, []);

    if (isNotNullAndUndefined(careShiftLogRow.log_typedata)) {
      let logTypeData = JSON.parse(careShiftLogRow.log_typedata);
      let logTypeDataKeys = Object.keys(logTypeData);
      let filteredUniqueAttributes =
        uniqueFilteredAttributes &&
        uniqueFilteredAttributes.filter((attribute) =>
          logTypeDataKeys.includes(attribute.AttributeName)
        );
      filteredUniqueAttributes &&
        filteredUniqueAttributes.sort(
          (a, b) =>
            logTypeDataKeys.indexOf(a.AttributeName) -
            logTypeDataKeys.indexOf(b.AttributeName)
        );

      let logType = isNotNullAndUndefined(careShiftLogRow.log_type)
        ? careShiftLogRow.log_type
        : null;
      let colorCode;
      if (
        isNotNullAndUndefined(logType) &&
        logType === CareEsioEntity.ClientNote.EntityName &&
        isNotNullAndUndefined(careShiftLogRow) &&
        isNotNullAndUndefined(careShiftLogRow.color_code)
      ) {
        colorCode = careShiftLogRow.color_code;
      }

      let formTitle = null;
      if (isNotNullAndUndefined(allForms) && isNotNullAndUndefined(logType)) {
        let selectedForm = allForms.find((x) => x.Entity === logType);
        if (isNotNullAndUndefined(selectedForm)) {
          formTitle = selectedForm.Title;
        }
      }

      let fileAttachments = careShiftLogRow.attachments;

      let fileAttachmentsDiv;
      if (isNotEmptyArray(fileAttachments)) {
        fileAttachmentsDiv = (
          <div className="col-md-12">
            <div className="row gx-0 gy-1">
              <div className="col-12">
                <div className="d-flex align-items-center">
                  <span className="profile_card_details_label ecllipseFirstLine">
                    Supporting Images
                  </span>
                  <span className="ms-2 raf-badge-circle raf-badge-tertiary-circle">
                    {fileAttachments.length}
                  </span>
                </div>
              </div>
              {false && (
                <div className="col-12">
                  {fileAttachments.map((attachment) => {
                    return (
                      <div className={"col-sm-6 col-lg-4 col-xl-3"}>
                        <div
                          key={attachment.UID}
                          id={attachment.UID}
                          onClick={() =>
                            DownloadAttachment(attachment.Value, attachment.UID)
                          }
                        >
                          <div className="px-3 p-2 d-flex align-items-center">
                            <span className="me-2">
                              <span
                                className={"fa fa-file-alt"}
                                style={{ fontSize: "18px" }}
                              ></span>
                            </span>
                            <span className="secondary-header-bold-text">
                              {attachment.Value}
                            </span>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
              <div className="col-12">
                <CareShiftLogAttachments attachments={fileAttachments} />
              </div>
            </div>
          </div>
        );
      }

      if (
        careShiftLogRow.title !== ShiftActivityTitle.StartedShift &&
        careShiftLogRow.title !== ShiftActivityTitle.CompletedShift
      ) {
        let startDate = convertUTCDateToLocalTimezone(
          careShiftLogRow.start_date
        );
        return (
          <div className="mt-2">
            <div style={{ margin: "0 -0.5rem" }}>
              <CustomCardWidget cardContentClassName="p-2">
                <div className="row g-3">
                  {careShiftLogRow.log_type !==
                    CareEsioEntity.CareShiftActivity.EntityName && (
                      <>
                        <div className="col-md-12">
                          <div className="row gx-0 gy-1">
                            <div className="col-12">
                              <span
                                className={
                                  "profile_card_details_label ecllipseFirstLine"
                                }
                              >{`Title :`}</span>
                            </div>

                            <div className="col-12">
                              <div
                                className={`details-value d-flex align-items-center justify-content-start`}
                              >
                                <span className={"details-value"}>
                                  {careShiftLogRow.title}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="row gx-0 gy-1">
                            <div className="col-12">
                              <span
                                className={
                                  "profile_card_details_label ecllipseFirstLine"
                                }
                              >{`Date And Time :`}</span>
                            </div>

                            <div className="col-12">
                              <div
                                className={`details-value d-flex align-items-center justify-content-start`}
                              >
                                <span className={"details-value"}>
                                  {moment(startDate).format("MMM DD hh:mm A")}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    )}

                  {IsNotNullOrWhiteSpace(careShiftLogRow.description) ? (
                    <div className="col-md-12">
                      <div className="row gx-0 gy-1">
                        <div className="col-12">
                          <span
                            className={
                              "profile_card_details_label ecllipseFirstLine"
                            }
                          >{`Description :`}</span>
                        </div>

                        <div className="col-12">
                          <div className={`description-text details-value`}>
                            <span
                              className={"description-text details-value"}
                              dangerouslySetInnerHTML={{
                                __html:
                                  careShiftLogRow.description !== null
                                    ? DOMPurify.sanitize(
                                      careShiftLogRow.description
                                    )
                                    : "Not set",
                              }}
                            ></span>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  {filteredUniqueAttributes &&
                    filteredUniqueAttributes.map((attribute) => {
                      let value = logTypeData[attribute.AttributeName];

                      if (IsNotNullOrWhiteSpace(value) && value !== "status") {
                        return (
                          <div
                            className="col-md-12"
                            key={attribute.AttributeName}
                          >
                            {getDetailsElement(
                              value,
                              attribute,
                              attribute.Visible,
                              true,
                              false,
                              null
                            )}
                          </div>
                        );
                      }
                    })}

                  {isNotNullAndUndefined(careShiftLogRow.support_level) && (
                    <div className="col-md-12">
                      {getDetailsElement(
                        careShiftLogRow.support_level,
                        uniqueFilteredAttributes &&
                        uniqueFilteredAttributes.find(
                          (x) => x.AttributeName === "support_level"
                        ),
                        uniqueFilteredAttributes &&
                        uniqueFilteredAttributes.find(
                          (x) => x.AttributeName === "support_level"
                        ).Visible,
                        true,
                        false,
                        null
                      )}
                    </div>
                  )}
                  {isNotNullAndUndefined(
                    careShiftLogRow.mood_emotional_state
                  ) && (
                      <div className="col-md-12">
                        {getDetailsElement(
                          careShiftLogRow.mood_emotional_state,
                          uniqueFilteredAttributes &&
                          uniqueFilteredAttributes.find(
                            (x) => x.AttributeName === "mood_emotional_state"
                          ),
                          uniqueFilteredAttributes &&
                          uniqueFilteredAttributes.find(
                            (x) => x.AttributeName === "mood_emotional_state"
                          ).Visible,
                          true,
                          false,
                          null
                        )}
                      </div>
                    )}

                  {fileAttachmentsDiv}
                </div>
              </CustomCardWidget>
            </div>
          </div>
        );
      } else {
        if (IsNotNullOrWhiteSpace(careShiftLogRow.description)) {
          return (
            <div className="mt-2">
              <div style={{ margin: "0 -0.5rem" }}>
                <CustomCardWidget cardContentClassName="p-2">
                  <div className={`description-text details-value`}>
                    <span
                      className={"description-text details-value"}
                      dangerouslySetInnerHTML={{
                        __html:
                          careShiftLogRow.description !== null
                            ? DOMPurify.sanitize(careShiftLogRow.description)
                            : "Not set",
                      }}
                    ></span>
                  </div>
                </CustomCardWidget>
              </div>
            </div>
          );
        }
      }
    } else {
      if (
        careShiftLogRow.title === ShiftActivityTitle.StartedShift ||
        careShiftLogRow.title === ShiftActivityTitle.CompletedShift
      ) {
        if (IsNotNullOrWhiteSpace(careShiftLogRow.description)) {
          return (
            <div className="mt-2">
              <div style={{ margin: "0 -0.5rem" }}>
                <CustomCardWidget cardContentClassName="p-2">
                  <div className={`description-text details-value`}>
                    <span
                      className={"description-text details-value"}
                      dangerouslySetInnerHTML={{
                        __html:
                          careShiftLogRow.description !== null
                            ? DOMPurify.sanitize(careShiftLogRow.description)
                            : "Not set",
                      }}
                    ></span>
                  </div>
                </CustomCardWidget>
              </div>
            </div>
          );
        }
      } else {
        return (
          <div className="mt-2">
            <div style={{ margin: "0 -0.5rem" }}>
              <CustomCardWidget cardContentClassName="p-2">
                <div className="row g-3">
                  {IsNotNullOrWhiteSpace(careShiftLogRow.description) ? (
                    <div className="col-12">
                      <div className="row gx-0 gy-1">
                        <div className="col-12">
                          <span
                            className={"profile_card_details_label text-nowrap"}
                          >{`Description :`}</span>
                        </div>
                        <div className="col-12">
                          <div className={`description-text details-value`}>
                            <span
                              className={"description-text details-value"}
                              dangerouslySetInnerHTML={{
                                __html:
                                  careShiftLogRow.description !== null
                                    ? DOMPurify.sanitize(
                                      careShiftLogRow.description
                                    )
                                    : "Not set",
                              }}
                            ></span>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  {isNotNullAndUndefined(careShiftLogRow.support_level) && (
                    <div className="col-md-12">
                      {getDetailsElement(
                        careShiftLogRow.support_level,
                        uniqueFilteredAttributes &&
                        uniqueFilteredAttributes.find(
                          (x) => x.AttributeName === "support_level"
                        ),
                        uniqueFilteredAttributes &&
                        uniqueFilteredAttributes.find(
                          (x) => x.AttributeName === "support_level"
                        ).Visible,
                        true,
                        false,
                        null
                      )}
                    </div>
                  )}
                  {isNotNullAndUndefined(
                    careShiftLogRow.mood_emotional_state
                  ) && (
                      <div className="col-md-12">
                        {getDetailsElement(
                          careShiftLogRow.mood_emotional_state,
                          uniqueFilteredAttributes &&
                          uniqueFilteredAttributes.find(
                            (x) => x.AttributeName === "mood_emotional_state"
                          ),
                          uniqueFilteredAttributes &&
                          uniqueFilteredAttributes.find(
                            (x) => x.AttributeName === "mood_emotional_state"
                          ).Visible,
                          true,
                          false,
                          null
                        )}
                      </div>
                    )}
                </div>
              </CustomCardWidget>
            </div>
          </div>
        );
      }
    }
  };

  const onChangeCheckBoxValue = (checked) => {
    if (allowEdit === true) {
      if (checked === true) {
        editCareShiftLog();
      }
    }
  };

  const checkBoxComponentContent = () => {
    return (
      isNotNullAndUndefined(careShiftLogRow.title) &&
      careShiftLogRow.title !== ShiftActivityTitle.StartedShift &&
      careShiftLogRow.title !== ShiftActivityTitle.CompletedShift &&
      careShiftLogRow.status === RAFActionStatus.Planned && (
        <div
          className="col-auto d-flex align-items-center"
          onClick={(e) => e.stopPropagation()}
          key={state.checkboxDivkey}
        >
          <CheckBoxComponent
            name={"UID"}
            value={careShiftLogRow.UID}
            // cssClass="e-checkbox-light e-checkbox-lg raf-round show-check-icon"
            cssClass="e-checkbox-light"
            change={(e) => {
              onChangeCheckBoxValue(e.checked);
            }}
          // disabled={
          //   isNotNullAndUndefined(careShiftLogRow.status) &&
          //   (careShiftLogRow.status === RAFActionStatus.Done ||
          //     careShiftLogRow.status ===
          //       RAFActionStatus.NotDone ||
          //     careShiftLogRow.status ===
          //       RAFActionStatus.NoTime ||
          //     careShiftLogRow.status ===
          //       RAFActionStatus.Refused)
          //     ? true
          //     : false
          // }
          />
        </div>
      )
    );
  };

  const cardFooterTemplate = () => {
    let taskStatus = isNotNullAndUndefined(careShiftLogRow.status)
      ? careShiftLogRow.status
      : null;
    let statusNameWithColor =
      isNotNullAndUndefined(taskStatus) &&
        isNotNullAndUndefined(RAFStatusNameWithColor[taskStatus])
        ? RAFStatusNameWithColor[taskStatus]
        : "";
    if (
      (careShiftLogRow.title === ShiftActivityTitle.StartedShift ||
        careShiftLogRow.title === ShiftActivityTitle.CompletedShift) &&
      !allowEdit &&
      !allowDelete
    ) {
      return;
    }
    return (
      careShiftLogRow.title !== ShiftActivityTitle.StartedShift &&
      careShiftLogRow.title !== ShiftActivityTitle.CompletedShift && (
        <div className="care_shift_log_footer">
          <div>
            <span
              className="raf_badge raf_sm"
              style={{
                color: statusNameWithColor.Color,
                backgroundColor: hexToRGBA(statusNameWithColor.Color, 0.1),
                border: `1px solid ${hexToRGBA(
                  statusNameWithColor.Color,
                  0.5
                )}`,
              }}
            >
              {statusNameWithColor.DisplayName ?? taskStatus}
            </span>
            {careShiftLogRow.NotesRating && (
              <span className="raf_badge raf_sm raf_badge_quaternary ms-2">
                <i
                  className="fas fa-note fa-sm me-1"
                  style={{ paddingTop: "2px" }}
                ></i>
                {careShiftLogRow.NotesRating ?? "Not set"}
              </span>
            )}
          </div>
          <div className="ms-auto">
            <div className="row gx-2">
              {/* commented by hari to implement this to client details page
             {careShiftLogRow.log_type === "incident" && (
                <div className="col-auto">
                  <RAFButtonComponent
                    action={RAFButtonConstant.Details}
                    iconBtn
                    className="btn_state_warning white e-round"
                    id={`ViewShiftDetailsBtn${careShiftLogRow.UID}`}
                    {...(allowEdit === true
                      ? { onClick: detailsCareShiftLog }
                      : {})}
                    enableToolTip={false}
                  />
                </div>
              )}
               */}
              {allowEdit && (
                <div className="col-auto">
                  <RAFButtonComponent
                    action={RAFButtonConstant.Edit}
                    iconBtn
                    className="btn_state_warning white e-round"
                    id={`ViewShiftDetailsBtn${careShiftLogRow.UID}`}
                    {...(allowEdit === true
                      ? { onClick: editCareShiftLog }
                      : {})}
                    enableToolTip={false}
                  />
                </div>
              )}
              {allowDelete &&
                careShiftLogRow.title !== ShiftActivityTitle.StartedShift &&
                careShiftLogRow.title !== ShiftActivityTitle.CompletedShift && (
                  <div className="col-auto">
                    <RAFButtonComponent
                      action={RAFButtonConstant.Delete}
                      iconBtn
                      className="btn_state_danger white e-round"
                      id={`ViewShiftDetailsBtn${careShiftLogRow.UID}`}
                      onClick={() => onClickDeleteCareShiftLog()}
                      enableToolTip={false}
                    />
                  </div>
                )}
            </div>
          </div>
        </div>
      )
    );
  };
  const careShiftLogTemplate = (formTitle) => {
    let title;
    let titleClassName = "care_shift_log_header_text";
    if (careShiftLogRow.title === ShiftActivityTitle.StartedShift) {
      title = ShiftActivityTitleDisplayName.StartedShift;
      titleClassName = "care_shift_log_started_header_text";
    } else if (careShiftLogRow.title === ShiftActivityTitle.CompletedShift) {
      title = ShiftActivityTitleDisplayName.CompletedShift;
      titleClassName = "care_shift_log_completed_header_text";
    } else if (
      isNotNullAndUndefined(careShiftLogRow.log_type) &&
      careShiftLogRow.log_type === CareEsioEntity.CareShiftActivity.EntityName
    ) {
      title = careShiftLogRow.title;
    } else {
      title = formTitle;
    }
    return (
      <div className="row care_shift_log_header">
        <div className="col">
          <div className="d-flex align-items-center">
            <div className="care_shift_log_header_text">
              <span className={titleClassName}>{title}</span>
            </div>
            <div className="mx-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="3"
                height="4"
                viewBox="0 0 3 4"
                fill="none"
              >
                <circle cx="1.5" cy="2" r="1.5" fill="#A3A3A3" />
              </svg>
            </div>
            <RAFRecordInfo
              createdBy={careShiftLogRow.CreatedBy}
              createdDate={careShiftLogRow.CreatedDate}
              modifiedDate={careShiftLogRow.ModifiedDate}
              modifiedBy={careShiftLogRow.ModifiedBy}
              lastActivityDate={careShiftLogRow.LastActivityDate}
              {...(!BrowserIsDevice
                ? { content: "Updated: ", hideClockIcon: true }
                : { hideClockIcon: false })}
              dropdownBtnClassName="timeline__item__recordInfo p-0"
              spanContentClassName="content_neutral_base medium recordInfo_text"
            />
          </div>
        </div>
        {allowEdit &&
          (careShiftLogRow.title === ShiftActivityTitle.StartedShift ||
            careShiftLogRow.title === ShiftActivityTitle.CompletedShift) && (
            <div className="col-auto">
              <RAFButtonComponent
                action={RAFButtonConstant.Edit}
                iconBtn
                className={`btn_state_warning outline e-round`}
                id={`ViewShiftDetailsBtn${careShiftLogRow.UID}`}
                {...(allowEdit === true ? { onClick: editCareShiftLog } : {})}
                enableToolTip={false}
              />
            </div>
          )}
      </div>
    );
  };

  if (isNotNullAndUndefined(careShiftLogRow)) {
    let localDate = isNotNullAndUndefined(careShiftLogRow.start_date)
      ? convertUTCDateToLocalTimezone(careShiftLogRow.start_date)
      : null;
    let endDate = isNotNullAndUndefined(careShiftLogRow.end_date)
      ? convertUTCDateToLocalTimezone(careShiftLogRow.end_date)
      : null;
    let startDateTime = isNotNullAndUndefined(localDate)
      ? moment(localDate).format("hh:mm A")
      : "NA";
    let endDateTime = isNotNullAndUndefined(endDate)
      ? moment(endDate).format("hh:mm A")
      : "NA";
    let shiftDuration = getTimeDifferenceInUserReadableText(localDate, endDate);
    let logType = isNotNullAndUndefined(careShiftLogRow.log_type)
      ? careShiftLogRow.log_type
      : null;
    let colorCode;
    if (
      isNotNullAndUndefined(logType) &&
      logType === CareEsioEntity.ClientNote.EntityName &&
      isNotNullAndUndefined(careShiftLogRow) &&
      isNotNullAndUndefined(careShiftLogRow.color_code)
    ) {
      colorCode = careShiftLogRow.color_code;
    }
    let formTitle = null;
    if (isNotNullAndUndefined(allForms) && isNotNullAndUndefined(logType)) {
      let selectedForm = allForms.find((x) => x.Entity === logType);

      if (isNotNullAndUndefined(selectedForm)) {
        formTitle = selectedForm.Title;
      }
    }

    return (
      <div className="timeline__item" key={careShiftLogRow.UID}>
        <div
          className={`${careShiftLogRow.title === ShiftActivityTitle.StartedShift ? "" : ""
            }row gx-2 gx-md-3 flex-nowrap`}
        >
          <div className="col-auto pe-0">
            <div className="timeline__date__container">
              {/* <div className="timeline__date">
            {moment(localDate).format("MMM DD")}
          </div> */}
              <div className="timeline__time">
                {isNotNullAndUndefined(localDate) ? (
                  <>
                    <span>{moment(localDate).format("hh:mm")}</span>
                    <span className="secondary-text">{` ${moment(
                      localDate
                    ).format("A")}`}</span>
                  </>
                ) : (
                  <span className="body_3_dark_bold">NA</span>
                )}
                {/* {careShiftLogRow.Duration} */}
              </div>
              {careShiftLogRow.title !== ShiftActivityTitle.StartedShift &&
                careShiftLogRow.title !== ShiftActivityTitle.CompletedShift &&
                isNotNullAndUndefined(formTitle) &&
                formTitle === "Shift Activity" && (
                  <div className="timeline__min">
                    {`${getTimeDifferenceInUserReadableText(
                      careShiftLogRow.start_date,
                      careShiftLogRow.end_date
                    )}`}
                  </div>
                )}
            </div>
          </div>
          <div className="col-auto">
            <div className="timeline__item__circle"></div>
          </div>
          <div className="col">
            <CustomCardWidget
              // {...(allowEdit === true
              //   ? { onClick: editCareShiftLog }
              //   : {})}
              // {...(isReporting === false
              //   ? {
              footerTemplate={cardFooterTemplate()}
              // }
              // : {})}
              // {...(careShiftLogRow.title !== ShiftActivityTitle.StartedShift &&
              //   careShiftLogRow.title !== ShiftActivityTitle.CompletedShift
              //   ? { footerTemplate: cardFooterTemplate() }
              //   : {})}
              cardClassName={`surface_neutral_base overflow-hidden${allowEdit === true ? " pointer" : ""
                }`}
            >
              <div className="row gy-1 gx-0">
                <div className="col-12">
                  <div className="row gx-2">
                    {checkBoxComponentContent()}
                    <div className="col">{careShiftLogTemplate(formTitle)}</div>
                  </div>
                  {false && (
                    <div className="header_6 w-100">
                      {isNotNullAndUndefined(careShiftLogRow.title) ? (
                        careShiftLogRow.title ===
                          ShiftActivityTitle.StartedShift ? (
                          <div className="d-flex align-items-center justify-content-between">
                            <span className="header_6 content_state_warning_dark">
                              Started
                            </span>

                            {allowEdit && (
                              <div className="col-auto">
                                <RAFButtonComponent
                                  action={RAFButtonConstant.Edit}
                                  iconBtn
                                  className={`btn_brand_secondary outline e-round`}
                                  id={`ViewShiftDetailsBtn${careShiftLogRow.UID}`}
                                  {...(allowEdit === true
                                    ? { onClick: editCareShiftLog }
                                    : {})}
                                  enableToolTip={false}
                                />
                              </div>
                            )}
                            {/* <span
                            className="e-badge e-badge-md e-badge-btn"
                            style={{
                              color: "#fff",
                              border: `1px solid ${RAFStatusNameWithColor.Inprocess.Color}`,
                              backgroundColor:
                                RAFStatusNameWithColor.Inprocess.Color,
                            }}
                          >
                            Started
                          </span> */}
                            {/* {allowEdit === true && (
                            <RAFButtonComponent
                              action={RAFButtonConstant.Edit}
                              className="link-button ms-3"
                            ></RAFButtonComponent>
                          )} */}
                          </div>
                        ) : careShiftLogRow.title ===
                          ShiftActivityTitle.CompletedShift ? (
                          <div className="d-flex align-items-center justify-content-between">
                            <span className="header_6 content_state_success_base">
                              {RAFStatusNameWithColor.Completed.DisplayName}
                            </span>
                            {allowEdit && (
                              <div className="col-auto">
                                <RAFButtonComponent
                                  action={RAFButtonConstant.Edit}
                                  iconBtn
                                  className={`btn_state_warning transparent`}
                                  id={`ViewShiftDetailsBtn${careShiftLogRow.UID}`}
                                  {...(allowEdit === true
                                    ? { onClick: editCareShiftLog }
                                    : {})}
                                  enableToolTip={false}
                                />
                              </div>
                            )}
                            {/* <span
                            className="e-badge e-badge-md e-badge-btn"
                            style={{
                              backgroundColor:
                                RAFStatusNameWithColor.Completed.Color,
                              color: "#fff",
                            }}
                          >
                            {RAFStatusNameWithColor.Completed.DisplayName}
                          </span> */}
                            {/* {allowEdit === true && (
                            <RAFButtonComponent
                              action={RAFButtonConstant.Edit}
                              className="link-button ms-3"
                            ></RAFButtonComponent>
                          )} */}
                          </div>
                        ) : (
                          <RAFDetailsValueWithSeparator>
                            <span className="header_6">
                              {logType !==
                                CareEsioEntity.CareShiftActivity.EntityName
                                ? formTitle
                                : careShiftLogRow.title}
                            </span>
                            <div>
                              <RAFRecordInfo
                                createdBy={careShiftLogRow.CreatedBy}
                                createdDate={careShiftLogRow.CreatedDate}
                                modifiedDate={careShiftLogRow.ModifiedDate}
                                modifiedBy={careShiftLogRow.ModifiedBy}
                                lastActivityDate={
                                  careShiftLogRow.LastActivityDate
                                }
                                hideClockIcon
                                dropdownBtnClassName="timeline__item__recordInfo p-0"
                                spanContent={"updated:"}
                                spanContentClassName="content_neutral_base medium recordInfo_text"
                              />
                            </div>
                          </RAFDetailsValueWithSeparator>
                          // careShiftLogRow.title
                        )
                      ) : (
                        "Title Not set"
                      )}
                    </div>
                  )}
                </div>
                {isNotNullAndUndefined(careShiftLogRow.Instructions) ? (
                  <div className="col-12">
                    <span className="body_2 Instructions">
                      {careShiftLogRow.Instructions}
                    </span>
                  </div>
                ) : null}
                {isReporting === false && (
                  <>
                    <div className="col-12">
                      <CustomCardWidget
                        cardClassName="my-1"
                        cardContentClassName="p-2"
                      >
                        <div className="row g-3">
                          {isNotNullAndUndefined(careShiftLogRow.title) &&
                            careShiftLogRow.title !==
                            ShiftActivityTitle.StartedShift &&
                            careShiftLogRow.title !==
                            ShiftActivityTitle.CompletedShift ? (
                            <>
                              <div className="col-4">
                                <span className="profile_card_details_label">
                                  Title
                                </span>
                              </div>
                              <div className="col-8">
                                <span className="details-value">
                                  {careShiftLogRow.title}
                                </span>
                              </div>
                            </>
                          ) : (
                            ""
                          )}
                          <div className="col-4">
                            <span className="profile_card_details_label">
                              Description
                            </span>
                          </div>
                          <div className="col-8">
                            <span className="details-value">
                              <span
                                className="description-text"
                                dangerouslySetInnerHTML={{
                                  __html: IsNotNullOrWhiteSpace(
                                    careShiftLogRow.description
                                  )
                                    ? DOMPurify.sanitize(
                                      careShiftLogRow.description
                                    )
                                    : "Not set",
                                }}
                              ></span>
                            </span>
                          </div>
                          {isNotNullAndUndefined(careShiftLogRow.title) &&
                            careShiftLogRow.title !==
                            ShiftActivityTitle.StartedShift &&
                            careShiftLogRow.title !==
                            ShiftActivityTitle.CompletedShift ? (
                            <>
                              <div className="col-4">
                                <span className="profile_card_details_label">
                                  Support Level
                                </span>
                              </div>
                              <div className="col-8">
                                <span className="details-value">
                                  {careShiftLogRow.support_level ?? "Not set"}
                                </span>
                              </div>
                              <div className="col-4">
                                <span className="profile_card_details_label">
                                  Mood/Emotional State
                                </span>
                              </div>
                              <div className="col-8">
                                <RAFDetailFieldCustom
                                  value={
                                    isNotNullAndUndefined(
                                      careShiftLogRow.mood_emotional_state
                                    )
                                      ? careShiftLogRow.mood_emotional_state
                                      : "Not set"
                                  }
                                  //   displayValue={careInterventionSupportRow.Frequency}
                                  title="Mood/Emotional State"
                                  moduleName={
                                    CareEsioEntity.CareShiftLog.EntityName
                                  }
                                  field="mood_emotional_state"
                                  uiType={RAFUIType.SmileyToggle}
                                  showLabel={false}
                                />
                              </div>
                            </>
                          ) : (
                            ""
                          )}
                        </div>
                      </CustomCardWidget>
                    </div>
                    <div className="col-12">
                      {/* {careShiftLogRow.title !==
                        ShiftActivityTitle.StartedShift &&
                        careShiftLogRow.title !==
                        ShiftActivityTitle.CompletedShift && ( */}
                      <div>
                        <RAFDetailsValueWithSeparator
                          outerClassName="h-26 line-seprator"
                          rowClassName="gx-3"
                        >
                          {careShiftLogRow.title !==
                            ShiftActivityTitle.StartedShift &&
                            careShiftLogRow.title !==
                            ShiftActivityTitle.CompletedShift &&
                            formTitle === "Shift Activity" ? (
                            <div className="body_3 content_neutral_dark d-flex align-items-center">
                              {isNotNullAndUndefined(startDateTime) && (
                                <span>{startDateTime}</span>
                              )}
                              <i className="px-2 fas fa-minus fa-xxsm color_inherit line_height_inherit"></i>
                              {isNotNullAndUndefined(endDateTime) && (
                                <span>{endDateTime}</span>
                              )}
                              {IsNotNullOrWhiteSpace(shiftDuration) && (
                                <span className="content_state_warning_dark ps-2">
                                  {`(${shiftDuration})`}
                                </span>
                              )}
                            </div>
                          ) : (
                            ""
                          )}
                          {/* {careShiftLogRow.log_type ===
                            CareEsioEntity.CareShiftActivity.EntityName ? (
                            // <RAFDetailFieldCustom
                            //   value={
                            //     isNotNullAndUndefined(careShiftLogRow.status)
                            //       ? careShiftLogRow.status
                            //       : "Not set"
                            //   }
                            //   title="Status"
                            //   moduleName={
                            //     CareEsioEntity.CareShiftLog.EntityName
                            //   }
                            //   isColorOption
                            //   field="Status"
                            //   showLabel={false}
                            //   mode="opacityColorView"
                            // />
                            ""
                          ) : // <span className="e-badge e-badge-outline e-badge-primary e-badge-md">
                            //   {careShiftLogRow.status}
                            // </span>
                            isNotNullAndUndefined(logType) &&
                              logType !== CareEsioEntity.ClientNote.EntityName &&
                              careShiftLogRow.title !==
                              ShiftActivityTitle.StartedShift &&
                              careShiftLogRow.title !==
                              ShiftActivityTitle.CompletedShift ? (
                              <span className="raf_badge raf_sm raf_badge_tertiary">
                                {formTitle}
                              </span>
                            ) : (
                              ""
                            )} */}
                          {/* {careShiftLogRow.log_type !==
                                CareEsioEntity.CareShiftActivity.EntityName && (
                                  <RAFDetailFieldCustom
                                    value={
                                      isNotNullAndUndefined(careShiftLogRow.status)
                                        ? careShiftLogRow.status
                                        : "Not set"
                                    }
                                    title="Status"
                                    moduleName={
                                      CareEsioEntity.CareShiftLog.EntityName
                                    }
                                    isColorOption
                                    field="Status"
                                    showLabel={false}
                                    mode="opacityColorView"
                                  />
                                )} */}
                          {/* {isNotNullAndUndefined(
                            careShiftLogRow.support_level
                          ) &&
                            careShiftLogRow.log_type ===
                            CareEsioEntity.CareShiftActivity.EntityName && (
                              <RAFDetailFieldCustom
                                value={
                                  isNotNullAndUndefined(
                                    careShiftLogRow.support_level
                                  )
                                    ? careShiftLogRow.support_level
                                    : "Not set"
                                }
                                title="SupportLevel"
                                moduleName={
                                  CareEsioEntity.CareShiftLog.EntityName
                                }
                                isColorOption
                                field="SupportLevel"
                                showLabel={false}
                                mode="outlineView"
                              />
                            )} */}
                          {/* {isNotNullAndUndefined(
                            careShiftLogRow.mood_emotional_state
                          ) &&
                            careShiftLogRow.log_type ===
                            CareEsioEntity.CareShiftActivity.EntityName && (
                              <RAFDetailFieldCustom
                                value={
                                  isNotNullAndUndefined(
                                    careShiftLogRow.mood_emotional_state
                                  )
                                    ? careShiftLogRow.mood_emotional_state
                                    : "Not set"
                                }
                                title="Mood/Emotional State"
                                moduleName={
                                  CareEsioEntity.CareShiftLog.EntityName
                                }
                                isColorOption
                                field="MoodEmotionalState"
                                showLabel={false}
                                mode="outlineView"
                              />
                            )} */}
                          {isNotNullAndUndefined(
                            careShiftLogRow.activity_category
                          ) && (
                              <RAFDetailFieldCustom
                                value={
                                  isNotNullAndUndefined(
                                    careShiftLogRow.activity_category
                                  )
                                    ? careShiftLogRow.activity_category
                                    : "Not set"
                                }
                                title="Category"
                                moduleName={
                                  CareEsioEntity.CareShiftLog.EntityName
                                }
                                isColorOption
                                field="ActivityCategory"
                                showLabel={false}
                                mode="outlineView"
                              />
                            )}
                          {/* {careShiftLogRow.title !==
                            ShiftActivityTitle.StartedShift &&
                            careShiftLogRow.title !==
                            ShiftActivityTitle.CompletedShift && ( */}
                          <RAFRecordInfo
                            createdBy={careShiftLogRow.CreatedBy}
                            createdDate={careShiftLogRow.CreatedDate}
                            modifiedDate={careShiftLogRow.ModifiedDate}
                            modifiedBy={careShiftLogRow.ModifiedBy}
                            lastActivityDate={careShiftLogRow.LastActivityDate}
                            hideClockIcon
                            dropdownBtnClassName="timeline__item__recordInfo"
                            spanContent={"updated:"}
                            spanContentClassName="content_neutral_base medium recordInfo_text"
                          />
                          {/* )} */}
                        </RAFDetailsValueWithSeparator>
                      </div>
                      {/* )} */}
                    </div>
                  </>
                )}
                {isReporting === true && logAdditionalDetails()}
              </div>
            </CustomCardWidget>
            {false && (
              <div
                className={`row gy-1 gx-0${careShiftLogRow.title === ShiftActivityTitle.CompletedShift ||
                  careShiftLogRow.title === ShiftActivityTitle.StartedShift
                  ? " timeline__item__moveTop"
                  : ""
                  }`}
              >
                <div className="col-12">
                  <div className="d-flex align-items-baseline">
                    {checkBoxComponentContent()}
                    <span
                      className="timeline__title pointer"
                      {...(allowEdit === true
                        ? { onClick: editCareShiftLog }
                        : {})}
                    >
                      {isNotNullAndUndefined(careShiftLogRow.title) ? (
                        careShiftLogRow.title ===
                          ShiftActivityTitle.StartedShift ? (
                          <div className="d-flex align-items-center">
                            <span
                              className="e-badge e-badge-md e-badge-btn"
                              style={{
                                color: "#fff",
                                border: `1px solid ${RAFStatusNameWithColor.Inprocess.Color}`,
                                backgroundColor:
                                  RAFStatusNameWithColor.Inprocess.Color,
                              }}
                            >
                              Started
                            </span>
                            {allowEdit === true && (
                              <RAFButtonComponent
                                action={RAFButtonConstant.Edit}
                                className="link-button ms-3"
                              ></RAFButtonComponent>
                            )}
                          </div>
                        ) : careShiftLogRow.title ===
                          ShiftActivityTitle.CompletedShift ? (
                          <div className="d-flex align-items-center">
                            <span
                              className="e-badge e-badge-md e-badge-btn"
                              style={{
                                backgroundColor:
                                  RAFStatusNameWithColor.Completed.Color,
                                color: "#fff",
                              }}
                            >
                              {RAFStatusNameWithColor.Completed.DisplayName}
                            </span>
                            {allowEdit === true && (
                              <RAFButtonComponent
                                action={RAFButtonConstant.Edit}
                                className="link-button ms-3"
                              ></RAFButtonComponent>
                            )}
                          </div>
                        ) : (
                          careShiftLogRow.title
                        )
                      ) : (
                        "Title Not set"
                      )}
                    </span>
                    {/* <RAFRecordInfo
                createdBy={careShiftLogRow.CreatedBy}
                createdDate={careShiftLogRow.CreatedDate}
                modifiedDate={careShiftLogRow.ModifiedDate}
                modifiedBy={careShiftLogRow.ModifiedBy}
                lastActivityDate={careShiftLogRow.LastActivityDate}
                hideClockIcon
                dropdownBtnClassName="timeline__item__recordInfo"
              /> */}
                  </div>
                </div>
                {isReporting === false && (
                  <>
                    {IsNotNullOrWhiteSpace(careShiftLogRow.description) ? (
                      <div className="col-12">
                        <div
                          className="timeline__description"
                          style={{
                            backgroundColor: isNotNullAndUndefined(colorCode)
                              ? colorCode
                              : null,
                          }}
                        >
                          <span
                            className="description-text"
                            dangerouslySetInnerHTML={{
                              __html:
                                careShiftLogRow !== null
                                  ? DOMPurify.sanitize(
                                    careShiftLogRow.description
                                  )
                                  : undefined,
                            }}
                          ></span>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    {careShiftLogRow.title !==
                      ShiftActivityTitle.StartedShift &&
                      careShiftLogRow.title !==
                      ShiftActivityTitle.CompletedShift && (
                        <div className="col-12">
                          <RAFDetailsValueWithSeparator outerClassName="h-26">
                            {careShiftLogRow.log_type ===
                              CareEsioEntity.CareShiftActivity.EntityName ? (
                              <RAFDetailFieldCustom
                                value={
                                  isNotNullAndUndefined(careShiftLogRow.status)
                                    ? careShiftLogRow.status
                                    : "Not set"
                                }
                                title="Status"
                                moduleName={
                                  CareEsioEntity.CareShiftLog.EntityName
                                }
                                isColorOption
                                field="Status"
                                showLabel={false}
                                mode="outlineView"
                              />
                            ) : // <span className="e-badge e-badge-outline e-badge-primary e-badge-md">
                              //   {careShiftLogRow.status}
                              // </span>
                              isNotNullAndUndefined(logType) &&
                                logType !==
                                CareEsioEntity.ClientNote.EntityName ? (
                                <span className="e-badge e-badge-outline e-badge-light">
                                  {formTitle}
                                </span>
                              ) : (
                                ""
                              )}
                            {careShiftLogRow.log_type !==
                              CareEsioEntity.CareShiftActivity.EntityName && (
                                <RAFDetailFieldCustom
                                  value={
                                    isNotNullAndUndefined(careShiftLogRow.status)
                                      ? careShiftLogRow.status
                                      : "Not set"
                                  }
                                  title="Status"
                                  moduleName={
                                    CareEsioEntity.CareShiftLog.EntityName
                                  }
                                  isColorOption
                                  field="Status"
                                  showLabel={false}
                                  mode="outlineView"
                                />
                              )}
                            {isNotNullAndUndefined(
                              careShiftLogRow.support_level
                            ) &&
                              careShiftLogRow.log_type ===
                              CareEsioEntity.CareShiftActivity.EntityName && (
                                <RAFDetailFieldCustom
                                  value={
                                    isNotNullAndUndefined(
                                      careShiftLogRow.support_level
                                    )
                                      ? careShiftLogRow.support_level
                                      : "Not set"
                                  }
                                  title="SupportLevel"
                                  moduleName={
                                    CareEsioEntity.CareShiftLog.EntityName
                                  }
                                  isColorOption
                                  field="SupportLevel"
                                  showLabel={false}
                                  mode="outlineView"
                                />
                              )}
                            {isNotNullAndUndefined(
                              careShiftLogRow.mood_emotional_state
                            ) &&
                              careShiftLogRow.log_type ===
                              CareEsioEntity.CareShiftActivity.EntityName && (
                                <RAFDetailFieldCustom
                                  value={
                                    isNotNullAndUndefined(
                                      careShiftLogRow.mood_emotional_state
                                    )
                                      ? careShiftLogRow.mood_emotional_state
                                      : "Not set"
                                  }
                                  title="Mood/Emotional State"
                                  moduleName={
                                    CareEsioEntity.CareShiftLog.EntityName
                                  }
                                  isColorOption
                                  field="MoodEmotionalState"
                                  showLabel={false}
                                  mode="outlineView"
                                />
                              )}
                            {isNotNullAndUndefined(
                              careShiftLogRow.activity_category
                            ) && (
                                <RAFDetailFieldCustom
                                  value={
                                    isNotNullAndUndefined(
                                      careShiftLogRow.activity_category
                                    )
                                      ? careShiftLogRow.activity_category
                                      : "Not set"
                                  }
                                  title="Category"
                                  moduleName={
                                    CareEsioEntity.CareShiftLog.EntityName
                                  }
                                  isColorOption
                                  field="ActivityCategory"
                                  showLabel={false}
                                  mode="outlineView"
                                />
                              )}
                            {careShiftLogRow.title !==
                              ShiftActivityTitle.StartedShift &&
                              careShiftLogRow.title !==
                              ShiftActivityTitle.CompletedShift && (
                                <RAFRecordInfo
                                  createdBy={careShiftLogRow.CreatedBy}
                                  createdDate={careShiftLogRow.CreatedDate}
                                  modifiedDate={careShiftLogRow.ModifiedDate}
                                  modifiedBy={careShiftLogRow.ModifiedBy}
                                  lastActivityDate={
                                    careShiftLogRow.LastActivityDate
                                  }
                                  hideClockIcon
                                  dropdownBtnClassName="timeline__item__recordInfo"
                                />
                              )}
                          </RAFDetailsValueWithSeparator>
                        </div>
                      )}
                  </>
                )}

                {isReporting === true && logAdditionalDetails()}
              </div>
            )}
            {false && (
              <div className="detailsLeftCard-second-title">
                <div className="w-100">
                  <div className="row gx-2 align-items-center">
                    {/* {isNotNullAndUndefined(careShiftLogRow.status) &&
                    careShiftLogRow.status === RAFActionStatus.Planned && (
                      <div className="col-auto detailsLeftCard-second-title-col">
                        <div className="detailsLeftCard-second-title-item">
                          <span className="e-badge e-badge-outline e-badge-primary">
                            {careShiftLogRow.status}
                          </span>
                        </div>
                      </div>
                    )} */}
                    {isNotNullAndUndefined(formTitle) && (
                      <div className="col-auto detailsLeftCard-second-title-col">
                        <div className="detailsLeftCard-second-title-item">
                          <span className="e-badge e-badge-outline e-badge-light">
                            {formTitle}
                          </span>
                        </div>
                      </div>
                    )}
                    {isNotNullAndUndefined(careShiftLogRow.support_level) && (
                      <div className="col-auto detailsLeftCard-second-title-col">
                        <div className="detailsLeftCard-second-title-item">
                          <RAFDetailFieldCustom
                            value={
                              isNotNullAndUndefined(
                                careShiftLogRow.support_level
                              )
                                ? careShiftLogRow.support_level
                                : "Not set"
                            }
                            title="SupportLevel"
                            moduleName={CareEsioEntity.CareShiftLog.EntityName}
                            isColorOption
                            field="SupportLevel"
                            showLabel={false}
                          />
                        </div>
                      </div>
                    )}

                    {/* {isNotNullAndUndefined(careShiftLogRow.status) && (isNotNullAndUndefined(careShiftLogRow.log_type) && careShiftLogRow.log_type === CareEsioEntity.CareShiftActivity.EntityName) &&  (
                    <div className="col-auto detailsLeftCard-second-title-col">
                      <div className="detailsLeftCard-second-title-item">
                        <span
                          className={
                            careShiftLogRow.status === RAFActionStatus.Planned
                              ? "e-badge e-badge-info"
                              : careShiftLogRow.status === RAFActionStatus.Done
                              ? "e-badge e-badge-success"
                              : "e-badge e-badge-grey"
                          }
                        >
                          {careShiftLogRow.status}
                        </span>
                      </div>
                    </div>
                  )} */}

                    {isNotNullAndUndefined(
                      careShiftLogRow.activity_category
                    ) && (
                        <div className="col-auto detailsLeftCard-second-title-col">
                          <div className="detailsLeftCard-second-title-item">
                            <RAFDetailFieldCustom
                              value={
                                isNotNullAndUndefined(
                                  careShiftLogRow.activity_category
                                )
                                  ? careShiftLogRow.activity_category
                                  : "Not set"
                              }
                              title="Category"
                              moduleName={CareEsioEntity.CareShiftLog.EntityName}
                              isColorOption
                              field="ActivityCategory"
                              showLabel={false}
                            />
                          </div>
                        </div>
                      )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        {false && (
          <CustomCardWidget cardContentClassName="px-2 px-md-3 py-1 py-md-2">
            {carePlanSectionCustomTitle()}
          </CustomCardWidget>
        )}
        {false && (
          <div className="e-card">
            <div className="e-card-content p-0">
              <RAFCollapse
                //title={careShiftLogRow.Category}
                headerRowClassName="with-height"
                contentCardClassName="relatedListOuterDiv py-2"
                // customButton={carePlanSectionCustomButton()}
                customTitle={carePlanSectionCustomTitle()}
                removeContentCardPadding
                showCustomButtonOnHover
                IsCollapsed={props.IsCollapsed}
              >
                <div className="p-2 p-md-3">
                  <div className="row gy-3 gx-0">
                    <div className="col-12"></div>
                  </div>
                </div>
              </RAFCollapse>
            </div>
          </div>
        )}

        {state.showUpdateCareShiftLogContent && (
          <DialogComponent
            //header={`Update ${CareEsioEntity.CareShiftLog.DisplayName}`}
            //showCloseIcon
            visible={state.showUpdateCareShiftLogContent}
            cssClass="rightDialog rightDialog-md createEditForm full-height form-center-dialog dlg-new-style"
            id={`dlg_update_${careShiftLogModule}`}
            content={updateCareShiftLogContent.bind(this)}
            isModal
            target="body"
            closeOnEscape={false}
            close={closeUpdateCareShiftLogDialog.bind(this)}
            open={PreventFocusOnDialogOpen}
            zIndex={1200}
          ></DialogComponent>
        )}
        {state.showCareShiftLogDetailsContent && (
          <DialogComponent
            //header={`Update ${CareEsioEntity.CareShiftLog.DisplayName}`}
            //showCloseIcon
            visible={state.showCareShiftLogDetailsContent}
            cssClass="rightDialog createEditForm full-height form-center-dialog dlg-new-style"
            id={`dlg_update_${careShiftLogModule}`}
            content={careShiftLogDetailsContent.bind(this)}
            isModal
            target="body"
            closeOnEscape={false}
            close={closeCareShiftLogDetailsDialog.bind(this)}
            open={PreventFocusOnDialogOpen}
            zIndex={1200}
          ></DialogComponent>
        )}
        {state.showUpdateClockContent && (
          <DialogComponent
            header={state.taskAction}
            showCloseIcon
            visible={state.showUpdateClockContent}
            cssClass={
              state.taskAction === "Clock Out"
                ? "rightDialog createEditForm full-height dlg-new-style"
                : "rightDialog createEditForm full-height dlg-new-style"
              // : "centerDialog-sm createEditForm form-center-dialog dlg-new-style fixed-header"
            }
            id="mangeShiftClock_dialog"
            content={shiftClockContent.bind(this)}
            isModal
            target="body"
            closeOnEscape={false}
            close={taskClockActionDialogClose.bind(this)}
            open={PreventFocusOnDialogOpen}
            zIndex={1200}
          ></DialogComponent>
        )}
        {state.showUpdateClientNoteContent && (
          <DialogComponent
            // header={"Update Note"}
            // showCloseIcon={false}
            visible={state.showUpdateClientNoteContent}
            cssClass={"rightDialog createEditForm full-height dlg-new-style"}
            id="manageupdate_clientnote_dialog"
            content={updateClientNoteContent.bind(this)}
            isModal
            target="body"
            closeOnEscape={false}
            close={closeUpdateClientNoteDialog.bind(this)}
            open={PreventFocusOnDialogOpen}
            zIndex={1200}
          ></DialogComponent>
        )}
      </div>
    );
  } else {
    return <div></div>;
  }
}

export default React.memo(CareShiftLogSection);
