import * as R from "ramda";
import React, { PropsWithChildren } from "react";
import { FormRenderProps } from "react-final-form";
import RAFChoiceOption from "../../../../../RAFComponents/Inputs/RAFChoiceOption";
import RAFDateTimePicker from "../../../../../RAFComponents/Inputs/RAFDateTimePicker";
import RAFDropdownCC from "../../../../../RAFComponents/Inputs/RAFDropdownCC";
import RAFForm from "../../../../../RAFComponents/Inputs/RAFForm";
import RAFNumber from "../../../../../RAFComponents/Inputs/RAFNumber";
import RAFButtonComponent from "../../../../../RAFComponents/Navigation/RAFButtonComponent";
import RAFDeletedRecordState from "../../../../../RAFComponents/Navigation/RAFDeletedRecordState";
import RAFAttributeRelatedListProvider, {
  RAFAttributesContext,
} from "../../../../../RAFComponents/Providers/RAFAttributeRelatedListProvider";
import {
  showSuccessToast,
  showWarningToast,
} from "../../../../../RAFComponents/Utility/RAFToastComponent";
import {
  RetrieveRecord,
  hideProgress,
  showProgress,
} from "../../../../../RAFComponents/helpers/AppHelper";
import RAFPermissionRender from "../../../../../RAFComponents/helpers/PermissionHelper";
import {
  IDialogProps,
  IsNotNullOrWhiteSpace,
  getTimeDifferenceInMinutes,
  isNotNullAndUndefined,
  propertyOf,
} from "../../../../../RAFComponents/helpers/utils";
import { EvaluateScore } from "../../../../../RAFMaster/helpers/RMutils";
import { InvoiceTransactionPermissionConstants } from "../../../../../constants/CareESIO/CareESIOPermissionConstant";
import {
  Constants,
  RAFButtonConstant,
  RAFLayout,
  RAFShiftActionStatus,
} from "../../../../../constants/Common/Constants";
import { RAFEntityName } from "../../../../../constants/Common/EntityConstants";
import { TimeSheetSummaryModuleName } from "../../../../ActiveContacts/ServiceTransaction/ServiceTimesheetSummary/ServiceTimesheetSummaryHelper";
import { ServiceTransactionRow } from "../../../../ActiveContacts/ServiceTransaction/ServiceTransactionRow";
import RAFEntityProvider, {
  RAFEntityContext,
} from "../../../../Common/Providers/RAFEntityProvider";
import RAFObjectContextProvider, {
  RAFObjectContext,
} from "../../../../Common/Providers/RAFObjectContextProvider";
import { InvoiceTransactionRow } from "./InvoiceTransactionRow";

interface IProps {
  onDelete?: () => void;
  objectUID: string;
}

function UpdateInvoiceShiftItem({
  ...props
}: PropsWithChildren<IProps & IDialogProps>) {
  let rafForm: FormRenderProps | null;

  const moduleName = TimeSheetSummaryModuleName.InvoiceTransaction;

  const onSubmitObject = async (
    values,
    entity,
    queryAttributes,
    saveObject: (
      objectData?: any
    ) => Promise<{ objectUID?: string; objectName?: string }>,
    initialValues: InvoiceTransactionRow
  ) => {
    let shiftUpdate: InvoiceTransactionRow = R.clone(values);

    const initialBillingStatus = initialValues.BillingStatus;
    const currentBillingStatus = shiftUpdate.BillingStatus;
    if (initialBillingStatus !== currentBillingStatus) {
      let progressDiv = showProgress(`#raf_dlg_Outer_Div_${moduleName}`);
      const serviceTransaction: ServiceTransactionRow = await RetrieveRecord(
        shiftUpdate.ServiceTransactionUID,
        RAFEntityName.ServiceTransaction
      );

      if (
        isNotNullAndUndefined(serviceTransaction) &&
        isNotNullAndUndefined(serviceTransaction.UID)
      ) {
        if (
          serviceTransaction.Status !== RAFShiftActionStatus.Unassigned &&
          serviceTransaction.Status !== RAFShiftActionStatus.Scheduled &&
          serviceTransaction.Status !== RAFShiftActionStatus.InProgress
        ) {
          if (shiftUpdate.BillingStatus === RAFShiftActionStatus.Ready) {
            if (shiftUpdate.Status === RAFShiftActionStatus.Approved) {
              shiftUpdate.BillingStatus = RAFShiftActionStatus.Approved;
            } else if (shiftUpdate.Status === RAFShiftActionStatus.Cancelled) {
              shiftUpdate.BillingStatus = RAFShiftActionStatus.Cancelled;
            }
            // else {
            //   hideProgress(progressDiv);
            //   showWarningToast(
            //     "Please approve the shift before sending invoice"
            //   );
            //   return;
            // }
          } else {
            shiftUpdate.BillingStatus = shiftUpdate.BillingStatus;
          }
        } else {
          hideProgress(progressDiv);
          showWarningToast(
            "Please complete this shift before updating billing status"
          );
          return;
        }
      }
      hideProgress(progressDiv);
    }

    if (
      isNotNullAndUndefined(entity) &&
      isNotNullAndUndefined(entity.EntitySettings) &&
      isNotNullAndUndefined(entity.EntitySettings.EnableScore) &&
      entity.EntitySettings.EnableScore === true
    ) {
      shiftUpdate = EvaluateScore(values, queryAttributes);
    }

    const duration = getTimeDifferenceInMinutes(
      shiftUpdate.StartDate,
      shiftUpdate.EndDate
    );
    shiftUpdate.Duration = duration;

    saveObject(shiftUpdate)
      .then((response) => {
        if (
          isNotNullAndUndefined(response) &&
          isNotNullAndUndefined(response.objectUID)
        ) {
          showSuccessToast("Saved successfully");

          if (props.onSave)
            props.onSave(response.objectUID, response.objectName);
        } else {
          showWarningToast(
            "Apologies, we're unable to update the record at the moment. Please try again later.!"
          );
        }
      })
      .catch((error) => error);
  };

  const isStartDateGreaterThanEndDate = (endDateFieldValue, allFormValue) => {
    let hasError = false,
      error = "";
    if (IsNotNullOrWhiteSpace(allFormValue)) {
      const startDateFieldValue = allFormValue["StartDate"];
      if (
        isNotNullAndUndefined(startDateFieldValue) &&
        IsNotNullOrWhiteSpace(endDateFieldValue)
      ) {
        const startDate = new Date(startDateFieldValue);
        const endDate = new Date(endDateFieldValue);

        if (
          isNotNullAndUndefined(startDate) &&
          isNotNullAndUndefined(endDate)
        ) {
          if (startDate > endDate) {
            hasError = true;
            error = "End date should be greater than start date";
          }
        }
      }
    }

    return hasError ? error : undefined;
  };

  const isEndDateLessThan24hrs = (endDateFieldValue, allFormValue) => {
    let hasError = false,
      error = "";
    if (IsNotNullOrWhiteSpace(allFormValue)) {
      const startDateFieldValue = allFormValue["StartDate"];
      if (
        isNotNullAndUndefined(startDateFieldValue) &&
        IsNotNullOrWhiteSpace(endDateFieldValue)
      ) {
        const startDate = new Date(startDateFieldValue);
        const endDate = new Date(endDateFieldValue);

        if (
          isNotNullAndUndefined(startDate) &&
          isNotNullAndUndefined(endDate)
        ) {
          if (getTimeDifferenceInMinutes(startDate, endDate) > 1440) {
            hasError = true;
            error = "End date should be within 24 hours from start date";
          }
        }
      }
    }

    return hasError ? error : undefined;
  };

  const getInputFormContent = () => {
    return (
      <div className="row gx-0 gy-3">
        <div className="col-md-12">
          <RAFDateTimePicker
            field={propertyOf<InvoiceTransactionRow>("StartDate")}
            label="Start Date"
            required
            interval={15}
            roundOff
          />
        </div>
        <div className="col-md-12">
          <RAFDateTimePicker
            field={propertyOf<InvoiceTransactionRow>("EndDate")}
            label="End Date"
            required
            interval={15}
            roundOff
            validators={[isStartDateGreaterThanEndDate, isEndDateLessThan24hrs]}
          />
        </div>
        {/* <div className="col-md-12">
          <RAFNumber
            field={propertyOf<InvoiceTransactionRow>("InvoiceAmount")}
            label="Invoice Amount"
            disabled
            decimalsPoints={2}
          />
        </div> */}
        <div className="col-md-12">
          <RAFDropdownCC
            field={propertyOf<InvoiceTransactionRow>("BillingStatus")}
            label="Status"
            //required
            disabled
          >
            <RAFChoiceOption
              label={RAFShiftActionStatus.Ready}
              value={RAFShiftActionStatus.Ready}
            />
            <RAFChoiceOption
              label={RAFShiftActionStatus.AddedtoInvoice}
              value={RAFShiftActionStatus.AddedtoInvoice}
            />
            <RAFChoiceOption
              label={RAFShiftActionStatus.InvoicedDirectly}
              value={RAFShiftActionStatus.InvoicedDirectly}
            />
            <RAFChoiceOption
              label={RAFShiftActionStatus.InvoicedtoXero}
              value={RAFShiftActionStatus.InvoicedtoXero}
            />
          </RAFDropdownCC>
        </div>
      </div>
    );
  };

  if (props.isActive) {
    return (
      <div
        className="h-100"
        key={moduleName}
        id={`raf_dlg_Outer_Div_${moduleName}`}
      >
        <RAFEntityProvider moduleName={moduleName}>
          <RAFAttributeRelatedListProvider moduleName={moduleName}>
            <RAFAttributesContext.Consumer>
              {({ queryAttributes }) => {
                return (
                  <RAFEntityContext.Consumer>
                    {({ entity, formLibrary }) => {
                      return (
                        <RAFObjectContextProvider
                          moduleName={moduleName}
                          objectId={props.objectUID}
                          progressDivId={`#raf_dlg_Outer_Div_${moduleName}`}
                        >
                          <RAFObjectContext.Consumer>
                            {({ rafObject, error, saveObject }) => {
                              const initialValues =
                                isNotNullAndUndefined(rafObject) &&
                                isNotNullAndUndefined(rafObject.UID)
                                  ? rafObject
                                  : null;

                              if (isNotNullAndUndefined(initialValues)) {
                                return (
                                  <RAFForm
                                    initialValues={initialValues}
                                    formRef={(g) => (rafForm = g)}
                                    layout={RAFLayout.TwoColumnLayout}
                                    onSubmit={(values) =>
                                      onSubmitObject(
                                        values,
                                        entity,
                                        queryAttributes,
                                        saveObject,
                                        initialValues
                                      )
                                    }
                                    primaryKey={initialValues.UID}
                                    className="h-100"
                                  >
                                    <div className="e-dlg-content-outer">
                                      <div className="e-dlg-body-content">
                                        {getInputFormContent()}
                                      </div>
                                      <div className="e-dlg-footerContent">
                                        <div className="w-100">
                                          <div className="row gx-2">
                                            <div className="col-auto ms-auto">
                                              <RAFPermissionRender
                                                permissionName={
                                                  InvoiceTransactionPermissionConstants.InvoiceTransactionManageBilling
                                                }
                                              >
                                                <RAFButtonComponent
                                                  isPrimary
                                                  action={
                                                    RAFButtonConstant.Save
                                                  }
                                                  onClick={() =>
                                                    rafForm &&
                                                    rafForm.form.submit()
                                                  }
                                                  idString="EditContent"
                                                />
                                              </RAFPermissionRender>
                                            </div>
                                            <div className="col-auto">
                                              <RAFButtonComponent
                                                action={
                                                  RAFButtonConstant.Cancel
                                                }
                                                onClick={props.onClose}
                                                idString="EditContent"
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </RAFForm>
                                );
                              } else {
                                if (error === Constants.RAFObject_NoContent) {
                                  return (
                                    <div className="container-fluid px-0">
                                      <RAFDeletedRecordState title="This item is either deleted or You do not have sufficient privileges to view this item." />
                                    </div>
                                  );
                                } else {
                                  return (
                                    <div
                                      className="container-fluid px-0"
                                      style={{ background: "transparent" }}
                                    >
                                      {/* <ACLoadingPanel loadingText="Loading..." /> */}
                                    </div>
                                  );
                                }
                              }
                            }}
                          </RAFObjectContext.Consumer>
                        </RAFObjectContextProvider>
                      );
                    }}
                  </RAFEntityContext.Consumer>
                );
              }}
            </RAFAttributesContext.Consumer>
          </RAFAttributeRelatedListProvider>
        </RAFEntityProvider>
      </div>
    );
  } else {
    return <div></div>;
  }
}

export default React.memo(UpdateInvoiceShiftItem);
