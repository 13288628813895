import { Fragment, useEffect, useRef, useState } from "react";
import { LookUpRow } from "../../../RAFComponents/models/CompositeTypes/LookUpRow";
import {
  getFileExtension,
  isNotEmptyArray,
  isNotNullAndUndefined,
} from "../../../RAFComponents/helpers/utils";
import { downloadAndGetPredifinedURL } from "../../../RAFMaster/helpers/RMutils";
import React from "react";
import { RAFFilePreSignedURL } from "../../../constants/Common/Constants";
import { getFileSize } from "../../ActiveContacts/Document/Library/DocumentHelper";
import {
  DialogComponent,
  TooltipComponent,
} from "@syncfusion/ej2-react-popups";
import CustomCardWidget from "../../../RAFComponents/Navigation/CustomCardWidget";
import { RafClearDialogContent } from "../../../RAFComponents/helpers/AppHelper";

interface CareShiftLogAttachmentsProps {
  attachments?: LookUpRow[];
}

const CareShiftLogAttachments: React.FC<CareShiftLogAttachmentsProps> = ({
  attachments,
}) => {
  const [downloadFileURLs, setDownloadFileURLs] = useState(null);
  const [downloadFileURL, setDownloadFileURL] = useState(null);
  const [showViewAttachmentContent, setViewAttachmentContent] = useState(false);
  const imageFileAttachmentRef = useRef<DialogComponent>(null);

  useEffect(() => {
    const fetchURLs = async () => {
      let recordIds = [];
      if (isNotEmptyArray(attachments)) {
        attachments.forEach((attachment) => {
          recordIds.push(attachment.UID);
        });
        downloadAndGetPredifinedURL(recordIds).then((fileUrls) => {
          setDownloadFileURLs(fileUrls);
        });
      }
    };

    fetchURLs();
  }, [attachments]);

  const downloadFile = (downloadURL, fileName) => {
    fetch(downloadURL)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = fileName; // or any other filename
        a.click();
      });
  };

  const generateThumbnail = () => {
    let downloadURLs: RAFFilePreSignedURL[] = downloadFileURLs;
    if (isNotEmptyArray(downloadURLs)) {
      let downloadFiles = downloadURLs.filter((x) => x.DocumentType === "File");
      let thumbnailFiles = downloadURLs.filter(
        (x) => x.DocumentType === "Thumbnail"
      );
      return (
        <div className="row g-2">
          {downloadFiles.map((item, index) => {
            let fileURL = null;
            let fileSize = getFileSize(item.FileSize) ?? 0;
            let fileName = item.ObjectName;
            let downloadURL;
            let thumbnailFile = thumbnailFiles.find(
              (x) => x.EntityId === item.EntityId
            );

            let fileClassName = "fiv-viv-lg fiv-viv fiv-icon-blank";
            if (item.DocumentType === "File") {
              downloadURL = item.UploadURL;
            }
            if (
              isNotNullAndUndefined(thumbnailFile) &&
              thumbnailFile.MimeType.startsWith("image/")
            ) {
              fileURL = item.UploadURL;
            } else {
              fileClassName =
                "fiv-viv-lg fiv-viv fiv-icon-blank fiv-icon-" +
                getFileExtension(fileName);
            }

            if (item.DocumentType === "File") {
              return (
                <Fragment
                  key={fileName + "_" + index}
                >
                  <div
                    className="sd-file__preview-item col-md-3 mx-2 my-3"
                  >
                    <div
                      className="sd-file__image-wrapper"
                    //onClick={() => downloadFile(downloadURL, fileName)}
                    >
                      {isNotNullAndUndefined(fileURL) ? (
                        <TooltipComponent title={"Click to preview"}>
                          {" "}
                          <img
                            src={fileURL}
                            alt={fileName}
                            onClick={() => onclickPreviewFile(downloadURL)}
                          />
                        </TooltipComponent>
                      ) : (
                        <div className="fiv-viv-outer">
                          <span className={fileClassName}></span>
                        </div>
                      )}
                    </div>
                    <div
                      className="sd-file__sign"
                      onClick={() => downloadFile(downloadURL, fileName)}
                    >
                      <TooltipComponent title={"Download"}>
                        {" "}
                        <a>{fileName}</a>
                      </TooltipComponent>
                    </div>
                  </div>
                </Fragment>
              );
            }
          })}
        </div>
      );
    }
    return <></>;
  };

  //view preview of attachment starts
  const onclickPreviewFile = (url) => {
    setViewAttachmentContent(true);
    setDownloadFileURL(url);
  };

  const viewAttachmentDlgContent = () => {
    if (showViewAttachmentContent) {
      return (
        <div>
          <CustomCardWidget cardClassName="img_card" removeContentPadding>
            <div
              className="img_wrapper raf-h-xl"
              style={{
                backgroundImage: `url(${downloadFileURL})`,
              }}
            ></div>
          </CustomCardWidget>
        </div>
      );
    } else {
      return <div></div>;
    }
  };

  const closeViewAttachmentDlg = async () => {
    await RafClearDialogContent(imageFileAttachmentRef);
    setViewAttachmentContent(false);
  };
  //view preview of attachment ends

  return (
    <>
      {generateThumbnail()}
      {showViewAttachmentContent && (
        <DialogComponent
          header={"Preview"}
          cssClass="centerDialog-lg full-height form-center-dialog"
          visible={showViewAttachmentContent}
          content={viewAttachmentDlgContent.bind(this)}
          isModal
          target="#root"
          closeOnEscape
          close={closeViewAttachmentDlg.bind(this)}
          overlayClick={closeViewAttachmentDlg.bind(this)}
          showCloseIcon={true}
          ref={imageFileAttachmentRef}
        />
      )}
    </>
  );
};

export default React.memo(CareShiftLogAttachments);
