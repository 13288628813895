import { CheckBoxComponent } from "@syncfusion/ej2-react-buttons";
import React, { PropsWithChildren } from "react";
import CustomCardWidget from "../../../RAFComponents/Navigation/CustomCardWidget";
import {
  IsNotNullOrWhiteSpace,
  isNotNullAndUndefined,
} from "../../../RAFComponents/helpers/utils";
import RAFDetailFieldCustom from "../../../RAFComponents/Navigation/RAFDetailFieldCustom";
import { RAFEntityName } from "../../../constants/Common/EntityConstants";
import { getTaskDisplayDateAndBadge } from "../../ActiveContacts/Task/TaskHelper";

interface IProps {
  shiftActivityRow: any;
  isChecked: boolean;
  showCheckBox: boolean;
  onChangeCheckBoxValue: (isChecked: boolean) => void;
}

function ShiftActivityCardContent({
  shiftActivityRow,
  ...props
}: PropsWithChildren<IProps>) {
  const onChangeCheckBoxValue = (isChecked: boolean) => {
    if (props.onChangeCheckBoxValue) {
      props.onChangeCheckBoxValue(isChecked);
    }
  };

  function shiftActivityCustomTitle() {
    const taskTitle = shiftActivityRow.Title;

    const taskDisplayDateAndBadge = getTaskDisplayDateAndBadge(
      isNotNullAndUndefined(shiftActivityRow) ? shiftActivityRow.TaskDate : null,
      isNotNullAndUndefined(shiftActivityRow) ? shiftActivityRow.TaskType : null,
      true
    );
    let taskDate = taskDisplayDateAndBadge.taskDate;
    let dateBadge = taskDisplayDateAndBadge.dateBadge;
    let taskTextColor = taskDisplayDateAndBadge.taskTextColor;

    return (
      <div className="row gx-2 gx-md-3 gy-2">
        <div className="col-12">
          <div className="row gx-2 gx-md-3">
            <div className="col">
              <RAFDetailFieldCustom
                value={shiftActivityRow.TaskType}
                displayValue={shiftActivityRow.TaskType}
                title="Type"
                moduleName={RAFEntityName.Task}
                isColorOption
                mode="outlineView"
                field="TaskType"
                showLabel={false}
                valueClassName="raf_badge raf_badge_sm"
              />
            </div>
            {props.showCheckBox && (
              <div
                key={props.isChecked ? props.isChecked.toString() : ""}
                className="col-auto mt-2"
              >
                <CheckBoxComponent
                  name={shiftActivityRow.UID}
                  value={shiftActivityRow.UID}
                  cssClass="e-checkbox-light"
                  change={(e) => onChangeCheckBoxValue(e.checked)}
                  checked={props.isChecked ?? false}
                />
              </div>
            )}
          </div>
        </div>
        <div className="col-12">
          <span className="secondary-header-bold-text">{taskTitle}</span>
        </div>
        {IsNotNullOrWhiteSpace(shiftActivityRow.Description) && (<div className="col-12">
          <div className="secondary-header-text-dark p-2 bg-grey">{shiftActivityRow.Description}</div>
        </div>)
        }
        <div className="col-12">
          <div className="row gx-2">
            <div className="col">
              <span className="body_3_light">Date:</span>
            </div>
            <div className="col-auto">
              <span className={`body_3 medium content_${taskTextColor}_base`}>{taskDate}</span>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (isNotNullAndUndefined(shiftActivityRow)) {
    return (
      <div className="col-12" key={shiftActivityRow.UID}>
        <CustomCardWidget
        >
          {shiftActivityCustomTitle()}
        </CustomCardWidget>
      </div>
    );
  } else {
    return null;
  }
}

export default React.memo(ShiftActivityCardContent);
