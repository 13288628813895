import { DialogComponent } from "@syncfusion/ej2-react-popups";
import moment from "moment";
import React, {
  PropsWithChildren,
  ReactElement,
  Reducer,
  useContext,
  useEffect,
  useReducer
} from "react";
import { msalInstance } from "../../..";
import { RolePermissionsContext } from "../../../RAFAuthentication/RAFRolePermissionsContextProvider";
import { PreventFocusOnDialogOpen } from "../../../RAFComponents/Dialog/SFDialogUtils";
import CustomCardWidget from "../../../RAFComponents/Navigation/CustomCardWidget";
import RAFButtonComponent from "../../../RAFComponents/Navigation/RAFButtonComponent";
import RAFCollapseWithPlusIcon from "../../../RAFComponents/Navigation/RAFCollapseWithPlusIcon";
import { showWarningToast } from "../../../RAFComponents/Utility/RAFToastComponent";
import {
  getAllFormsByCategoryTypes
} from "../../../RAFComponents/helpers/AppHelper";
import {
  hasPermission,
  hasPermissions,
} from "../../../RAFComponents/helpers/PermissionHelper";
import {
  convertUTCDateToLocalTimezone,
  getTimeDifferenceInUserReadableText,
  getUTCDateValue,
  isNotEmptyArray,
  isNotNullAndUndefined,
} from "../../../RAFComponents/helpers/utils";
import { FormLibraryRow } from "../../../RAFComponents/models/Common/FormLibraryRow";
import ACLoadingPanel from "../../../components/shared/ACLoadingPanel";
import { ServiceTransactionPermissionConstants } from "../../../constants/CareESIO/CareESIOPermissionConstant";
import { CareEsioEntity } from "../../../constants/CareESIO/CareEsioConstants";
import {
  BrowserIsDevice,
  MomentFormats,
  RAFActionStatus,
  RAFButtonConstant,
  RAFShiftActionStatus,
} from "../../../constants/Common/Constants";
import "../../../styles/timeline-layout.scss";
import { ActivityRow } from "../../ActiveContacts/Activity/ActivityRow";
import { ServiceTransactionStates } from "../../ActiveContacts/ServiceTransaction/ServiceTransactionHelper";
import { ServiceTransactionRow } from "../../ActiveContacts/ServiceTransaction/ServiceTransactionRow";
import RAFTaskCustomCardContent from "../../ActiveContacts/Task/RelatedTask/RAFTaskCustomCardContent";
import { getAllRelatedTasks } from "../../ActiveContacts/Task/TaskHelper";
import AddCareShiftLog from "./AddCareShiftLog";
import {
  ShiftActivityTitle,
  getCareShiftLogsList
} from "./CareShiftLogHelper";
import CareShiftLogSection from "./CareShiftLogSection";
import RAFRelatedNoteList from "./ClientNote/RAFRelatedNoteList";
//const RAFGrid4 = React.lazy(() => import('../../../RAFComponents/Grid/RAFGrid4'));

interface IProps {
  careRecipientName?: string;
  careRecipientUID?: string;

  showHeaderTitle?: boolean;
  careEventRow?: ServiceTransactionRow;
  onClickClockActions?: (action: "Clock In" | "Clock Out") => void;
  mode?: "CollapsePanel" | "CardPanel" | "default";
  plannedActivitySectionDivKey?: number;

  selectedEntity?: string;
  isSupportLog?: boolean;
  headerString?: { primaryHeader: string; secondaryHeader?: string; };
  taskRow?: ServiceTransactionRow;
  onSave?: () => void;
  groupBtnContent?: ReactElement;

}

interface IState {
  isLoading?: boolean;
  careShiftLogSectionUID?: string;
  careShiftLogs?: any;
  careShiftLogRow?: any;
  showRelatedSections?: boolean;
  showAddShiftFormContent?: boolean;
  formLibrary?: FormLibraryRow;
  allForms?: FormLibraryRow[];
  allRelatedTasks?: any;
  allRelatedNotes?: ActivityRow[];

}

function CareShiftLogList({
  careEventRow,
  careRecipientUID,
  showHeaderTitle = false,
  ...props
}: PropsWithChildren<IProps>) {
  // function CareShiftLogList({ ...props }: PropsWithChildren<IProps>) {
  const careShiftLogModule = CareEsioEntity.CareShiftLog.EntityName;

  const rolePermissionsContext = useContext(RolePermissionsContext);
  const permissionValue = isNotNullAndUndefined(rolePermissionsContext)
    ? rolePermissionsContext.permissionValue
    : null;

  const [state, setState] = useReducer<Reducer<IState, Partial<IState>>>(
    (state, newState) => ({ ...state, ...newState }),
    {
      isLoading: true,
      careShiftLogSectionUID: null,
      careShiftLogs: null,
      careShiftLogRow: null,
      showRelatedSections: false,
      showAddShiftFormContent: false,
      formLibrary: null,
      allForms: null,
      allRelatedTasks: null,
      allRelatedNotes: null,
    }
  );

  useEffect(() => {
    refresh();
  }, [careEventRow, props.plannedActivitySectionDivKey]);

  const refresh = async () => {
    setState({
      isLoading: true,
      showAddShiftFormContent: false,
    });

    if (isNotNullAndUndefined(careEventRow)) {
      //get all shift related notes
      //get all shift activity logs
      //get all shift related tasks

      const [careShiftLogsList, allRelatedTasks,
        //activityItems,
        allForms] =
        await Promise.all([
          getCareShiftLogsList(careEventRow.UID, props.selectedEntity),
          getAllRelatedTasks(careRecipientUID, careEventRow.UID),
          //getCareShiftActivityItems(careEventRow.UID, careRecipientUID),
          getAllFormsByCategoryTypes(
            [
              CareEsioEntity.CareShiftLog.DisplayName,
              CareEsioEntity.CareRecipientCQIRegister.DisplayName,
            ],
            CareEsioEntity.CareShiftLog.EntityName
          ),
        ]);

      const formLibrary =
        allForms &&
        allForms.find(
          (x) => x.Entity === CareEsioEntity.CareShiftActivity.EntityName
        );

      const shiftStartDate = isNotNullAndUndefined(careEventRow.StartDate) ? new Date(careEventRow.StartDate) : new Date();

      const taskSortTaskItems = isNotEmptyArray(allRelatedTasks) ?
        allRelatedTasks.map((x) => ({ UID: x.UID, StartTime: isNotNullAndUndefined(x.TaskDate) ? new Date(x.TaskDate) : shiftStartDate })) : [];

      const careSortShiftLogsList = isNotEmptyArray(careShiftLogsList) ?
        careShiftLogsList.map((x) => ({ UID: x.UID, StartTime: isNotNullAndUndefined(x.start_date) ? new Date(x.start_date) : shiftStartDate })) : [];

      // Combine both lists
      const combinedList = [...taskSortTaskItems, ...careSortShiftLogsList];

      // Sort the combined list based on StartTime
      const sortedCombinedList = combinedList.sort((a, b) => a.StartTime.getTime() - b.StartTime.getTime());

      const newCareShiftLogsList = [];

      sortedCombinedList.forEach(sortedItem => {
        const careShiftLog = isNotEmptyArray(careShiftLogsList) ? careShiftLogsList.find(log => log.UID === sortedItem.UID) : null;
        const relatedTask = isNotEmptyArray(allRelatedTasks) ? allRelatedTasks.find(task => task.UID === sortedItem.UID) : null;

        if (careShiftLog) {
          newCareShiftLogsList.push(careShiftLog);
        }
        if (relatedTask) {
          newCareShiftLogsList.push(relatedTask);
        }
      });

      setState({
        isLoading: false,
        //careShiftLogs: careShiftLogsList,
        careShiftLogs: newCareShiftLogsList,
        showRelatedSections: false,
        formLibrary,
        allForms,
        allRelatedTasks,
      });
    }
  };

  const refreshOnUpdate = () => { };


  const onClickClockActions = (action: "Clock In" | "Clock Out") => {
    const hasPermissionToDoClockActions =
      careEventRow.AssigneeUID === msalInstance.currentUserId
        ? true
        : hasPermission(
          permissionValue,
          ServiceTransactionPermissionConstants.ServiceTransactionMyShiftsTeamShiftActions
        );

    if (!hasPermissionToDoClockActions) {
      showWarningToast(
        `Apologies, this shift is assigned to ${careEventRow.Assignee}. You cannot ${action} a shift that is not assigned to you.`
      );
      return;
    }
    if (isNotNullAndUndefined(props.onClickClockActions)) {
      if (action === "Clock In") {
        props.onClickClockActions(action);
      } else {
        props.onClickClockActions(action);
      }
    }
  };

  const taskStatus = isNotNullAndUndefined(careEventRow)
    ? careEventRow.Status
    : null;

  const getClockActionButton = (type: "start" | "end") => {
    if (type === "start") {
      const taskState = isNotNullAndUndefined(careEventRow)
        ? careEventRow.State
        : null;
      const clockBtnText = isNotNullAndUndefined(taskStatus)
        ? taskStatus !== RAFShiftActionStatus.InProgress &&
          taskStatus !== RAFShiftActionStatus.Completed
          ? !BrowserIsDevice
            ? "Start Shift"
            : "Start Shift"
          : !BrowserIsDevice
            ? "Shift Started"
            : "Started"
        : null;

      const clockFunText = isNotNullAndUndefined(taskStatus)
        ? taskStatus !== RAFShiftActionStatus.InProgress &&
          taskStatus !== RAFShiftActionStatus.Completed
          ? "Clock In"
          : null
        : null;

      return (
        <RAFButtonComponent
          isPrimary
          className={
            isNotNullAndUndefined(clockFunText)
              ? ""
              : "success-custom-button active"
          }
          btnContent={clockBtnText}
          onClick={() => {
            if (taskState === ServiceTransactionStates.Draft) {
              showWarningToast(
                "You cannot start the shift until it is published."
              );
            } else if (taskStatus === RAFShiftActionStatus.Unassigned) {
              showWarningToast(
                "You cannot start the shift until it is assigned."
              );
            } else if (taskStatus === RAFShiftActionStatus.OfferSent) {
              showWarningToast(
                'You cannot start the shift until the offer is accepted.'
              );
            } else if (taskState === ServiceTransactionStates.Published) {
              onClickClockActions(clockFunText);
            }
          }}
          action={RAFButtonConstant.Start}
          disabled={isNotNullAndUndefined(clockFunText) ? false : true}
          showIcon={false}
        />
      );
    } else {
      const clockBtnText = isNotNullAndUndefined(taskStatus)
        ? taskStatus === RAFShiftActionStatus.Completed
          ? !BrowserIsDevice
            ? "Shift Completed"
            : "Completed"
          : !BrowserIsDevice
            ? "Complete Shift"
            : "Complete Shift"
        : null;

      const clockFunText = isNotNullAndUndefined(taskStatus)
        ? taskStatus !== RAFShiftActionStatus.InProgress &&
          taskStatus !== RAFShiftActionStatus.Completed
          ? null
          : taskStatus === RAFShiftActionStatus.InProgress
            ? "Clock Out"
            : null
        : null;

      return (
        <RAFButtonComponent
          className={
            isNotNullAndUndefined(clockFunText)
              ? ""
              : "success-custom-button active"
          }
          isPrimary
          btnContent={clockBtnText}
          onClick={() => onClickClockActions(clockFunText)}
          action={RAFButtonConstant.End}
          disabled={isNotNullAndUndefined(clockFunText) ? false : true}
          showIcon={false}
        />
      );
    }
  };

  const OnCreateBtnClick = () => {
    if (isNotNullAndUndefined(careEventRow.ActualStartDate)) {
      if (hasPermissions(permissionValue, [
        ServiceTransactionPermissionConstants.ServiceTransactionMyShiftsEnableActivities,
        ServiceTransactionPermissionConstants.ServiceTransactionMyShiftsEnableForms
      ])) {
        setState({ showAddShiftFormContent: true });
      }
    } else {
      showWarningToast("Start the shift to add activity.");
    }
  };

  const careShiftLogSectionCardContent = () => {
    const { careShiftLogs, showRelatedSections } = state;
    const taskStatus = isNotNullAndUndefined(careEventRow)
      ? careEventRow.Status
      : null;

    //let localDate = convertUTCDateToLocalTimezone(careEventRow.TaskDate);
    const actualStartDate = convertUTCDateToLocalTimezone(
      careEventRow.ActualStartDate
    );
    const completedDate = convertUTCDateToLocalTimezone(
      careEventRow.ActualEndDate
    );

    const startedShiftExist =
      isNotEmptyArray(careShiftLogs) &&
        isNotNullAndUndefined(
          careShiftLogs.find((x) => x.title === ShiftActivityTitle.StartedShift)
        )
        ? true
        : false;
    const completedShiftExist =
      isNotEmptyArray(careShiftLogs) &&
        isNotNullAndUndefined(
          careShiftLogs.find((x) => x.title === ShiftActivityTitle.CompletedShift)
        )
        ? true
        : false;

    if (state.isLoading === false) {
      const hasPermissionToAdd = hasPermissions(permissionValue, [
        ServiceTransactionPermissionConstants.ServiceTransactionMyShiftsEnableActivities,
        ServiceTransactionPermissionConstants.ServiceTransactionMyShiftsEnableForms
      ]);

      return (
        <ul
          className={
            taskStatus === RAFShiftActionStatus.NotReady ||
              taskStatus === RAFShiftActionStatus.Ready ||
              taskStatus === RAFShiftActionStatus.InvoiceSent ||
              taskStatus === RAFShiftActionStatus.PaymentReceived ||
              taskStatus === RAFShiftActionStatus.Approved ||
              taskStatus === RAFShiftActionStatus.Rejected ||
              taskStatus === RAFShiftActionStatus.Paid ||
              taskStatus === RAFShiftActionStatus.InProgress ||
              taskStatus === RAFShiftActionStatus.Completed
              ? "timeline__ul timeline__container timeline__ul__active"
              : "timeline__ul timeline__container"
          }
        >
          {startedShiftExist === false && (
            <li
              className={
                taskStatus === RAFShiftActionStatus.NotReady ||
                  taskStatus === RAFShiftActionStatus.Ready ||
                  taskStatus === RAFShiftActionStatus.InvoiceSent ||
                  taskStatus === RAFShiftActionStatus.PaymentReceived ||
                  taskStatus === RAFShiftActionStatus.InProgress ||
                  taskStatus === RAFShiftActionStatus.Completed
                  ? "timeline__li timeline__li__completed"
                  : "timeline__li"
              }
            >
              <div className="timeline__item">
                <div className="row gx-md-3 gx-2">
                  <div className="col-auto pe-0">
                    <div className="timeline__date__container">
                      {(taskStatus === RAFShiftActionStatus.InProgress ||
                        taskStatus === RAFShiftActionStatus.Completed) &&
                        isNotNullAndUndefined(actualStartDate) ? (
                        <div className="timeline__time">
                          <span>{moment(actualStartDate).format("hh:mm")}</span>
                          <span className="secondary-text">{` ${moment(
                            actualStartDate
                          ).format("A")}`}</span>
                        </div>
                      ) : (
                        <div className="timeline__time">
                          <span className="body_3_dark_bold">NA</span>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-auto">
                    <div className="timeline__item__circle mt-0"></div>
                  </div>
                  <div className="col d-flex align-items-center">
                    {/* <span className="header-text-sm me-2">Not Started</span> */}
                    <div>{getClockActionButton("start")}</div>
                  </div>
                </div>
              </div>
            </li>
          )}
          {isNotEmptyArray(careShiftLogs) &&
            careShiftLogs
              .sort((a, b) => {
                if (a.title === ShiftActivityTitle.StartedShift) return -1;
                if (b.title === ShiftActivityTitle.StartedShift) return 1;
                if (a.title === ShiftActivityTitle.CompletedShift) return 1;
                if (b.title === ShiftActivityTitle.CompletedShift) return -1;

                return 0;
              })
              .map((item, index) => {
                const hasPermissionToDoShiftActions =
                  item.CreatedByUID === msalInstance.currentUserId ||
                    careEventRow.AssigneeUID === msalInstance.currentUserId ||
                    hasPermission(
                      permissionValue,
                      ServiceTransactionPermissionConstants.ServiceTransactionMyShiftsTeamShiftActions
                    )
                    ? true
                    : false;

                if (item.hasOwnProperty("start_date")) {
                  return (
                    <li
                      key={item.UID}
                      className={
                        (item.status === RAFActionStatus.Done ||
                          item.status === RAFActionStatus.Resolved ||
                          item.status === RAFActionStatus.InProgress ||
                          item.status === RAFActionStatus.Submitted ||
                          item.status === RAFActionStatus.Approved ||
                          item.status === RAFActionStatus.Rejected ||
                          (isNotNullAndUndefined(item.form_library) && item.form_library !== CareEsioEntity.CareShiftLog.DisplayName && isNotNullAndUndefined(item.form_libraryuid))
                        ) &&
                          (taskStatus === RAFShiftActionStatus.NotReady ||
                            taskStatus === RAFShiftActionStatus.Ready ||
                            taskStatus === RAFShiftActionStatus.InvoiceSent ||
                            taskStatus === RAFShiftActionStatus.PaymentReceived ||
                            taskStatus === RAFShiftActionStatus.Approved ||
                            taskStatus === RAFShiftActionStatus.Rejected ||
                            taskStatus === RAFShiftActionStatus.Paid ||
                            taskStatus === RAFShiftActionStatus.InProgress ||
                            taskStatus === RAFShiftActionStatus.Completed)
                          ? "timeline__li timeline__li__completed"
                          : "timeline__li"
                      }
                    >
                      <CareShiftLogSection
                        key={item.UID}
                        careEventRow={careEventRow}
                        careShiftLogUID={item.UID}
                        careShiftLogRow={item}
                        careRecipientUID={careRecipientUID}
                        //onSave={refresh}
                        onSave={refreshOnUpdateShift.bind(this)}
                        showRelatedSections={showRelatedSections}
                        IsCollapsed={false}
                        allForms={state.allForms}
                        allowDelete={hasPermissionToDoShiftActions}
                        allowEdit={hasPermissionToDoShiftActions}
                        isReporting
                      />
                    </li>
                  );
                } else if (item.hasOwnProperty("TaskType")) {
                  return (
                    <li
                      key={item.UID}
                      className={
                        item.TaskStatus === RAFActionStatus.Completed
                          ? "timeline__li timeline__li__completed"
                          : "timeline__li"
                      }
                    >
                      <RAFTaskCustomCardContent
                        key={item.UID}
                        taskRow={item}
                        onSave={refresh}
                        relatedToUID={careRecipientUID}
                        relatedToType={CareEsioEntity.CareRecipient.EntityName}
                        shiftStatus={careEventRow.Status}
                        allowDelete={hasPermissionToDoShiftActions}
                        allowEdit={hasPermissionToDoShiftActions}
                      />
                    </li>
                  );
                } else if (item.hasOwnProperty("CommentType")) {
                  return (
                    <li
                      key={item.UID}
                      className={"timeline__li timeline__li__completed"}
                    >
                      <RAFRelatedNoteList
                        careRecipientUID={careRecipientUID}
                        relatedToType={CareEsioEntity.CareRecipient.EntityName}
                        shiftUID={careEventRow.UID}
                        mode={"PinnedNotesOrShiftNotes"}
                        isRelatedSection
                        displayStyle="TextOnly"
                        activityItem={item}
                        onSave={refresh}
                      />
                    </li>
                  );
                }
              })}

          {completedShiftExist === false &&
            (taskStatus === RAFShiftActionStatus.NotReady ||
              taskStatus === RAFShiftActionStatus.Ready ||
              taskStatus === RAFShiftActionStatus.InvoiceSent ||
              taskStatus === RAFShiftActionStatus.PaymentReceived ||
              taskStatus === RAFShiftActionStatus.InProgress ||
              taskStatus === RAFShiftActionStatus.Completed) && (
              <li
                className={
                  taskStatus === RAFShiftActionStatus.NotReady ||
                    taskStatus === RAFShiftActionStatus.Ready ||
                    taskStatus === RAFShiftActionStatus.InvoiceSent ||
                    taskStatus === RAFShiftActionStatus.PaymentReceived ||
                    taskStatus === RAFShiftActionStatus.Completed
                    ? "timeline__li timeline__li__completed"
                    : "timeline__li"
                }
              >
                <div className="timeline__item">
                  <div className="row gx-2 gx-md-3">
                    <div className="col-auto pe-0">
                      <div className="timeline__date__container">
                        {taskStatus === RAFShiftActionStatus.Completed &&
                          isNotNullAndUndefined(completedDate) ? (
                          <div className="timeline__time">
                            <span>
                              {moment(actualStartDate).format("hh:mm")}
                            </span>
                            <span className="content_neutral_base regular">{` ${moment(
                              actualStartDate
                            ).format("A")}`}</span>
                          </div>
                        ) : (
                          <div className="timeline__time">
                            <span className="body_3_dark_bold">NA</span>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-auto">
                      <div className="timeline__item__circle mt-0"></div>
                    </div>
                    <div className="col d-flex align-items-center">
                      {/* <span className="header-text-sm me-2">Not Completed</span> */}
                      <div>{getClockActionButton("end")}</div>
                    </div>
                  </div>
                </div>
              </li>
            )}
        </ul>
      );
    } else {
      return (
        <div className="container-fluid px-0">
          <ACLoadingPanel loadingText="Loading..." />
        </div>
      );
    }
  };

  //create shift form start
  function onAddShiftFormSectionContent() {
    if (state.showAddShiftFormContent === true) {
      return (
        <AddCareShiftLog
          allForms={state.allForms}
          formLibrary={state.formLibrary}
          selectedServiceTransactionRow={careEventRow}
          careRecipientUID={careRecipientUID}
          careRecipientName={props.careRecipientName}
          careShiftLogs={state.careShiftLogs}
          onSave={refreshOnUpdate.bind(this)}
          onClose={onAddShiftFormDialogClose.bind(this)}
          isActive
          {...props}
        />
      );
    } else {
      return <div></div>;
    }
  }

  const onAddShiftFormDialogClose = () => {
    setState({ showAddShiftFormContent: false });
  };

  //create shift activity end

  const refreshOnUpdateShift = () => {
    //refresh();
    if (isNotNullAndUndefined(props.onSave)) {
      props.onSave();
    }
  };

  const onExpandClicked = (expanded?: boolean) => {
    if (expanded) {
      refresh();
    }
  };
  const headerTemplate = () => {
    const taskRow = props.taskRow;
    return (
      <div className="" style={{ paddingLeft: "12px" }}>
        <span className="subtitle_1">
          {isNotNullAndUndefined(props.headerString) &&
            isNotNullAndUndefined(props.headerString.primaryHeader)
            ? props.headerString.primaryHeader
            : CareEsioEntity.CareShiftLog.CollectionName}
        </span>
        {isNotNullAndUndefined(taskRow) &&
          isNotNullAndUndefined(taskRow.StartDate) &&
          isNotNullAndUndefined(taskRow.EndDate) ? (
          <div className="mt-2 d-flex align-items-center">
            <div className="body_3 pe-1">
              <span>Scheduled:</span>
            </div>
            <div className="subtitle_3">
              <span>{`${getUTCDateValue(
                taskRow.StartDate,
                MomentFormats.TIME
              )} - ${getUTCDateValue(
                taskRow.EndDate,
                MomentFormats.TIME
              )} (${getTimeDifferenceInUserReadableText(
                taskRow.StartDate,
                taskRow.EndDate
              )})`}</span>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    );
  };

  const careShiftLogSectionContent = () => {
    return (
      <div
      //className="d-flex align-items-center justify-content-center"
      >
        {/* <CustomCardWidget removeContentPadding cardClassName="grey_card"> */}
        {props.mode === "CollapsePanel" ? (
          <RAFCollapseWithPlusIcon
            // customTitle={overviewHeaderTemplate()}
            toggleArrowIcon="Arrow"
            // title={
            //   isNotNullAndUndefined(props.headerString) &&
            //     isNotNullAndUndefined(props.headerString.primaryHeader)
            //     ? props.headerString.primaryHeader
            //     : CareEsioEntity.CareShiftLog.CollectionName
            // }
            // customTitle={headerTemplate()}
            collapsePanelHeaderClass={"p-0"}
            allowFullRowClick
            collapsePanelContentClassname="p-0"
            collapsePanelRowClass="g-0"
            collapsePanelHeaderSpanClass=""
            onExpandedSection={(expanded) => onExpandClicked(expanded)}
            showSeparator={false}
            collapsible={false}
          >
            <div>
              {state.isLoading === false ? (
                careShiftLogSectionCardContent()
              ) : (
                <div className="container-fluid px-0">
                  <ACLoadingPanel loadingText="Loading..." />
                </div>
              )}
            </div>
          </RAFCollapseWithPlusIcon>
        ) : props.mode === "CardPanel" ? (
          <CustomCardWidget
            title="Activities"
            // cardHeaderClassName="custom__card__header__sm"
            cardContentClassName="p-0 pt-3"
            // headerTemplate={headerTemplate()}
            cardClassName="shadow"
          >
            {careShiftLogSectionCardContent()}
          </CustomCardWidget>
        ) : (
          <div>{careShiftLogSectionCardContent()}</div>
        )}
        {state.showAddShiftFormContent === true && (
          <DialogComponent
            // header={"Add Support Task"}
            // showCloseIcon
            visible={state.showAddShiftFormContent}
            //cssClass="rightDialog createEditForm full-height dlg-new-style"
            cssClass="rightDialog rightDialog-md createEditForm full-height dlg-new-style"
            id="add_shift_activity_dlg_outerDiv"
            content={onAddShiftFormSectionContent.bind(this)}
            isModal
            target="body"
            closeOnEscape={false}
            close={onAddShiftFormDialogClose.bind(this)}
            open={PreventFocusOnDialogOpen}
            zIndex={1200}
          ></DialogComponent>
        )}
      </div>
    );
  };

  if (isNotNullAndUndefined(careEventRow)) {
    const hasPermissionToAdd = hasPermissions(permissionValue, [
      ServiceTransactionPermissionConstants.ServiceTransactionMyShiftsEnableActivities,
      ServiceTransactionPermissionConstants.ServiceTransactionMyShiftsEnableForms
    ]);

    return (
      <div className="row gx-0 gy-3">
        <div className="col-12">
          <div className="row gx-2 gx-md-3 flex-nowrap">
            <div className="col">
              {hasPermissionToAdd && (
                <RAFButtonComponent
                  action={RAFButtonConstant.Add}
                  iconPosition="right"
                  btnContent={
                    "Add Shift Log"
                  }
                  className="btn_brand_primary semi_dark"
                  showIcon={true}
                  onClick={OnCreateBtnClick}
                />
              )}
            </div>
            {props.groupBtnContent ? <div className="col-auto">{props.groupBtnContent}</div> : ''}
          </div>
        </div>
        <div className="col-12">{careShiftLogSectionContent()}</div>
      </div>
    );
  } else {
    return null;
  }
}

export default React.memo(CareShiftLogList);
