import * as R from "ramda";
import React, {
    PropsWithChildren,
    Reducer,
    useEffect,
    useReducer,
    useRef,
} from "react";
import { FormRenderProps } from "react-final-form";
import { ITheme, Model } from "survey-core";
import "survey-core/defaultV2.min.css";
import { Survey } from "survey-react-ui";
import RAFForm from "../../../RAFComponents/Inputs/RAFForm";
import RAFImageFileAttachmentInput from "../../../RAFComponents/Inputs/RAFImageFileAttachmentInput";
import CustomCardWidget from "../../../RAFComponents/Navigation/CustomCardWidget";
import RAFButtonComponent from "../../../RAFComponents/Navigation/RAFButtonComponent";
import RAFDeletedRecordState from "../../../RAFComponents/Navigation/RAFDeletedRecordState";
import RAFIconImage from "../../../RAFComponents/Navigation/RAFIconImage";
import RAFAttributeRelatedListProvider, {
    RAFAttributesContext,
} from "../../../RAFComponents/Providers/RAFAttributeRelatedListProvider";
import { RAFCustomFilter, RAFCustomOperator } from "../../../RAFComponents/RAFViewPanels/RAFFilterColumn/RAFCustomFilter";
import {
    showSuccessToast,
    showWarningToast,
} from "../../../RAFComponents/Utility/RAFToastComponent";
import {
    RAFActionMessage,
    hideProgress,
    showProgress,
} from "../../../RAFComponents/helpers/AppHelper";
import { getLookUpItems } from "../../../RAFComponents/helpers/AutoCompleteMUIHelper";
import RAFPermissionRender from "../../../RAFComponents/helpers/PermissionHelper";
import {
    IDialogProps,
    IsNotNullOrWhiteSpace,
    getSaveRequest,
    getTimeDifferenceInMinutes,
    isNotEmptyArray,
    isNotNullAndUndefined,
    isNullOrUndefined
} from "../../../RAFComponents/helpers/utils";
import { FormLibraryRow } from "../../../RAFComponents/models/Common/FormLibraryRow";
import { LookUpRow } from "../../../RAFComponents/models/CompositeTypes/LookUpRow";
import { customTheme } from "../../../RAFMaster/RMModules/FormLibrary/components/custom-theme";
import { getPredefinedURLAndUplodToS3 } from "../../../RAFMaster/helpers/RMutils";
import ACLoadingPanel from "../../../components/shared/ACLoadingPanel";
import { CareShiftLogPermissionConstants } from "../../../constants/CareESIO/CareESIOPermissionConstant";
import { CareEsioEntity } from "../../../constants/CareESIO/CareEsioConstants";
import {
    RAFActionStatus,
    RAFButtonConstant,
    RAFLayout,
} from "../../../constants/Common/Constants";
import { RAFEntityName } from "../../../constants/Common/EntityConstants";
import { NavigateParamsProps } from "../../../router";
import {
    DocumentImageAttachmentRow,
    uploadImageAttachment,
} from "../../ActiveContacts/Document/Library/DocumentHelper";
import { checkHasPermissionToAddOrEditActivitylog } from "../../ActiveContacts/ServiceTransaction/ServiceTransactionHelper";
import { ServiceTransactionRow } from "../../ActiveContacts/ServiceTransaction/ServiceTransactionRow";
import CreateContent from "../../Common/Create/CreateContent";
import RAFEntityProvider, {
    RAFEntityContext,
} from "../../Common/Providers/RAFEntityProvider";
import { SaveShiftActivity } from "../CareShiftLog/CareShiftLogHelper";
import { getUserTenantSettings } from "../../Common/TenantSettings/TenantSettingsHelper";
import { TenantSettingsRow } from "../../Common/TenantSettings/TenantSettingsRow";

export const isValidActivityStarEndTimeRange = (
    formStartDateFormValue: Date,
    formEndDateFormValue: Date,
    shiftStartTime: Date,
    shiftEndTime: Date
) => {
    const formStartDateValue = isNotNullAndUndefined(formStartDateFormValue)
        ? new Date(formStartDateFormValue)
        : null;
    const formEndDateValue = isNotNullAndUndefined(formEndDateFormValue)
        ? new Date(formEndDateFormValue)
        : null;

    // 1. Check if start time is less than end time
    if (
        isNotNullAndUndefined(formStartDateValue) &&
        isNotNullAndUndefined(formEndDateValue)
    ) {
        if (formStartDateValue >= formEndDateValue) {
            showWarningToast("Start time must be less than end time.");
            return false;
        }
    }

    // 2. Check if start time is greater than shift start time minus 2 hours
    if (
        isNotNullAndUndefined(formStartDateValue) &&
        isNotNullAndUndefined(shiftStartTime)
    ) {
        const startTimeMinValue = new Date(shiftStartTime);
        startTimeMinValue.setHours(startTimeMinValue.getHours() - 2); // Subtract 2 hours
        if (formStartDateValue < startTimeMinValue) {
            showWarningToast(
                "Activity start time should be within 2 hours of shift start date."
            );
            return false;
        }
    }

    //3. Check if start time is should not exceded +2 hrs from Shift end Date
    if (
        isNotNullAndUndefined(formStartDateValue) &&
        isNotNullAndUndefined(shiftEndTime)
    ) {
        const startTimeMaxValue = new Date(shiftEndTime);
        startTimeMaxValue.setHours(startTimeMaxValue.getHours() + 2); // Add 2 hours
        if (formStartDateValue > startTimeMaxValue) {
            showWarningToast(
                "Activity start time should be within 2 hours of shift end date."
            );
            return false;
        }
    }

    // 4. Check if end time is less than shift end time plus 2 hours
    if (
        isNotNullAndUndefined(formEndDateValue) &&
        isNotNullAndUndefined(shiftEndTime)
    ) {
        const startTimeMaxValue = new Date(shiftEndTime);
        startTimeMaxValue.setHours(startTimeMaxValue.getHours() + 2); // Add 2 hours
        if (formEndDateValue > startTimeMaxValue) {
            showWarningToast(
                "Activity end time should be within 2 hours of shift start date."
            );
            return false;
        }
    }

    // If all checks pass
    return true;
};

export const isStartDateGreaterThanEqualToShiftDate = (
    shiftStartDate,
    activityStartDate,
    activityEndDate
) => {
    if (IsNotNullOrWhiteSpace(shiftStartDate)) {
        const startDate = IsNotNullOrWhiteSpace(activityStartDate)
            ? new Date(activityStartDate)
            : null;
        const endDate = IsNotNullOrWhiteSpace(activityEndDate)
            ? new Date(activityEndDate)
            : null;
        const shiftDate = new Date(shiftStartDate);

        if (startDate && startDate < shiftDate) {
            showWarningToast(
                "Activity start date and end date should be greater than or equal to shift start date."
            );
            return false;
        }

        if (endDate && endDate < shiftDate) {
            showWarningToast(
                "Activity start date and end date should be greater than or equal to shift start date."
            );
            return false;
        }
    }

    return true;
};

interface IProps {
    shiftActivityInitialValues: any;
    careRecipientUID: string;
    allForms: FormLibraryRow[];
    selectedServiceTransactionRow: ServiceTransactionRow;
    formLibrary: FormLibraryRow;
    onUpdateActiveTabIndex: (activeTabIndex: number) => void;
}

interface IState {
    isLoading: boolean;
    isActive: boolean;
    shiftActivityFormValue: any;
    filteredForms: FormLibraryRow[];
    selectedFormLibrary: FormLibraryRow;
    selectedFormEntityName: string;
    selectedSubFormLibrary: FormLibraryRow;
    selectedSubFormEntityName: string;
    selectedIndex: 0 | 1;
    selectedActivityValue: string;
    standardizedLibraryLookupItems: LookUpRow[];
    tenantSettings: TenantSettingsRow;
}

function AddShiftActivityFormContent({
    ...props
}: PropsWithChildren<IProps & NavigateParamsProps & IDialogProps>) {
    let rafShiftActivityDetailsForm: FormRenderProps | null;
    const moduleName = CareEsioEntity.CareShiftActivity.EntityName;

    const [state, setState] = useReducer<Reducer<IState, Partial<IState>>>(
        (state, newState) => ({ ...state, ...newState }),
        {
            isLoading: true,
            isActive: false,
            shiftActivityFormValue: null,
            filteredForms: null,
            selectedFormEntityName: isNotNullAndUndefined(props.formLibrary)
                ? props.formLibrary.Entity
                : null,
            selectedFormLibrary: isNotNullAndUndefined(props.formLibrary)
                ? props.formLibrary
                : null,
            selectedIndex: 0,
            selectedSubFormLibrary: null,
            selectedSubFormEntityName: null,
            tenantSettings: null,
            selectedActivityValue: null,
            standardizedLibraryLookupItems: []
        }
    );

    useEffect(() => {
        refresh();
    }, []);

    const refresh = async () => {
        if (props.isActive) {
            setState({ isActive: true });
            let shiftActivityFormValue = isNotNullAndUndefined(props.shiftActivityInitialValues)
                ? props.shiftActivityInitialValues
                : null;

            // let db = await RAFDatabase.get();

            // db = createTableByModuleName(db, moduleName);
            // shiftActivityFormValue = await saveDataByModuleName(moduleName, shiftActivityFormValue);

            // SaveFileToDB(moduleName, shiftActivityFormValue.UID, shiftActivityFormValue.Attachments);

            let allForms = isNotNullAndUndefined(props.allForms)
                ? props.allForms
                : null;

            let filteredForms: FormLibraryRow[] = [];
            if (isNotEmptyArray(allForms)) {
                filteredForms = allForms.filter(
                    (x) =>
                        x.Entity !== CareEsioEntity.ClientNote.EntityName &&
                        x.Entity !== CareEsioEntity.CareCompleteShift.EntityName &&
                        x.Entity !== "completion_feedback"
                    //x.Entity !== "suggestion_form" &&
                    //x.Entity !== "shift_log_form" &&
                    //x.Entity !== "concern_form"
                );
                let shiftActivityIndex = filteredForms.findIndex(
                    (x) => x.Entity === moduleName
                );

                if (shiftActivityIndex !== -1) {
                    let shiftActivity = filteredForms.splice(shiftActivityIndex, 1)[0];
                    //filteredForms.unshift(shiftActivity);
                }
            }

            let standardizedLibrarycustomFilter: RAFCustomFilter = {};
            standardizedLibrarycustomFilter.Condition = "and";
            standardizedLibrarycustomFilter.Rules = [];
            let standardizedLibraryfilter: RAFCustomFilter = {};
            let standardizedLibraryfilterVal: string[] = [];
            //standardizedLibraryfilterVal.push("Routines");
            standardizedLibraryfilterVal.push("Activity");
            standardizedLibraryfilter.Operator = RAFCustomOperator.Equal;
            standardizedLibraryfilter.Value = standardizedLibraryfilterVal;
            standardizedLibraryfilter.Field = "Type";
            standardizedLibrarycustomFilter.Rules.push(standardizedLibraryfilter);

            const [tenantSettings, standardizedLibraryLookupItems] = await Promise.all([
                getUserTenantSettings(),
                getLookUpItems(
                    'StandardizedLibrary/Lookup',
                    0,
                    500,
                    null,
                    RAFEntityName.StandardizedLibrary,
                    standardizedLibrarycustomFilter,
                    null,
                )
            ]);

            if (isNotNullAndUndefined(shiftActivityFormValue)) {
                // shiftActivityFormValue.Duration = 60;
                // shiftActivityFormValue.Assignee = msalInstance.currentUserName;
                // shiftActivityFormValue.AssigneeUID = msalInstance.currentUserId;
                shiftActivityFormValue.ImageAttachments = null;
            }

            setState({
                shiftActivityFormValue,
                filteredForms,
                isLoading: false,
                tenantSettings,
                standardizedLibraryLookupItems
            });
        } else {
            setState({ isActive: false, isLoading: false });
        }
    };

    const onUpdateActiveTabIndex = (activeTabIndex: number) => {
        if (props.onUpdateActiveTabIndex) {
            props.onUpdateActiveTabIndex(activeTabIndex);
        }
    };

    const closeDialog = () => {
        if (props.onClose) {
            props.onClose();
        }
    };

    const onSubmitShiftActivity = (
        shiftActivityFormValue: any,
        formModelData: any,
        hasCustomForm: boolean
    ) => {
        const { selectedServiceTransactionRow } = props;
        const shiftStartDate = isNotNullAndUndefined(selectedServiceTransactionRow)
            ? selectedServiceTransactionRow.StartDate
            : null;

        const shiftEndDate = isNotNullAndUndefined(selectedServiceTransactionRow)
            ? selectedServiceTransactionRow.EndDate
            : null;

        const { selectedFormLibrary, selectedFormEntityName } = state;
        // let shiftActivityInitialValues = R.clone(shiftActivityFormValue);
        let updatedValues = R.clone(shiftActivityFormValue);

        if (hasCustomForm) {
            let isFormValid = formModelData.validate(true, true);

            if (isFormValid) {
                const formDataValue = formModelData.data;
                //commented to remove date validation by hari
                //check is valid date range
                /*const isValidDateRange = isValidActivityStarEndTimeRange(
                  formDataValue.start_date,
                  formDataValue.end_date,
                  shiftStartDate,
                  shiftEndDate
                );
        
                
                if (!isValidDateRange) {
                  return;
                }*/

                let progressDiv = showProgress(`#add_shift_activity_dlg_outerDiv`);
                //commented for use s3 upload by hari
                //uploadFileAndGetFormData(formModelData, moduleName, "create").then(
                getPredefinedURLAndUplodToS3(formModelData, moduleName, "create").then(
                    (formData) => {
                        //set default values
                        if (
                            selectedFormLibrary.CategoryType ===
                            CareEsioEntity.CareRecipientCQIRegister.DisplayName
                        ) {
                            formData["add_to_cqi"] = true;
                            formData["care_shift_type"] = selectedFormLibrary.Title;
                            if (isNullOrUndefined(formData["title"])) {
                                formData["title"] = selectedFormLibrary.Title;
                            }
                        }
                        formData["status"] = RAFActionStatus.Done;

                        updatedValues = { ...updatedValues, ...formData };
                        // console.log("updatedValues", updatedValues);
                        // return;
                        let saveRequestData = getSaveRequest(
                            updatedValues,
                            updatedValues.UID
                        );
                        saveRequestData.EntityName = isNotNullAndUndefined(
                            selectedFormEntityName
                        )
                            ? selectedFormEntityName
                            : moduleName;

                        let url = "DataList/SaveForm";
                        SaveShiftActivity(saveRequestData, url).then(async (response) => {
                            if (isNotNullAndUndefined(response)) {
                                showSuccessToast(RAFActionMessage.RecordSaved);
                                hideProgress(progressDiv);
                                if (props.onSave) {
                                    props.onSave();
                                }
                            } else {
                                showWarningToast(RAFActionMessage.RecordNotSaved);
                                closeDialog();
                                hideProgress(progressDiv);
                            }
                        });
                    }
                );
            }
        } else {
            const { selectedSubFormEntityName, selectedSubFormLibrary } = state;

            const startDate = updatedValues.StartDate;
            const endDate = updatedValues.EndDate;

            /*const isValidDateRange = isValidActivityStarEndTimeRange(
              startDate,
              endDate,
              shiftEndDate,
              shiftEndDate
            );
      
            if (!isValidDateRange) {
              return;
            }*/

            updatedValues.Duration = getTimeDifferenceInMinutes(startDate, endDate);

            if (isNullOrUndefined(updatedValues.Title)) {
                updatedValues.Title = selectedFormLibrary.Title;
            }

            if (
                selectedFormLibrary.CategoryType ===
                CareEsioEntity.CareRecipientCQIRegister.DisplayName
            ) {
                updatedValues.AddToCqi = true;
                updatedValues.CareShiftType = selectedFormLibrary.Title;
                if (isNullOrUndefined(updatedValues.Title)) {
                    updatedValues.Title = selectedFormLibrary.Title;
                }
            }

            if (
                isNotNullAndUndefined(selectedSubFormLibrary) &&
                isNotNullAndUndefined(selectedSubFormEntityName)
            ) {
                if (
                    updatedValues.AddAdditionalForm === "Yes" &&
                    updatedValues.CareShiftType !== "None" &&
                    updatedValues.CareShiftType === selectedSubFormLibrary.Title
                ) {
                    for (let key in updatedValues) {
                        if (
                            key.startsWith("SubForm") &&
                            key !== "SubForm" + selectedSubFormEntityName
                        ) {
                            delete updatedValues[key];
                        }
                    }

                    let subFormValues =
                        updatedValues["SubForm" + selectedSubFormEntityName];
                    if (
                        selectedSubFormLibrary.CategoryType ===
                        CareEsioEntity.CareRecipientCQIRegister.DisplayName
                    ) {
                        if (isNotNullAndUndefined(subFormValues)) {
                            subFormValues.AddToCqi = true;
                            //subFormValues.CareShiftType = selectedSubFormLibrary.Title;
                            if (isNullOrUndefined(updatedValues.Title)) {
                                updatedValues.Title = selectedSubFormLibrary.Title;
                            }
                        }
                    }
                }
            }

            if (
                updatedValues.AddAdditionalForm === "No" ||
                updatedValues.CareShiftType === "None"
            ) {
                updatedValues.CareShiftType = null;
                for (let key in updatedValues) {
                    if (key.includes("SubForm")) {
                        delete updatedValues[key];
                    }
                }
            }

            const imageAttachments: DocumentImageAttachmentRow[] =
                updatedValues.ImageAttachments;
            const attachments: LookUpRow[] = isNotEmptyArray(imageAttachments)
                ? imageAttachments.map((item) => {
                    return {
                        UID: item.UID,
                        Value: item.Name,
                    };
                })
                : [];
            updatedValues.Attachments = attachments;
            delete updatedValues.ImageAttachments;

            let saveRequestData = getSaveRequest(updatedValues, updatedValues.UID);
            saveRequestData.EntityName = isNotNullAndUndefined(selectedFormEntityName)
                ? selectedFormEntityName
                : moduleName;

            //console.log("onSubmitShiftActivity saveRequestData", saveRequestData);
            //return;
            let progressDiv = showProgress(`#add_shift_activity_dlg_outerDiv`);

            SaveShiftActivity(saveRequestData).then(async (response) => {
                if (isNotNullAndUndefined(response)) {
                    const onUploadAttachment = await uploadImageAttachment(
                        imageAttachments,
                        null,
                        response.entityId,
                        response.objectName,
                        isNotNullAndUndefined(selectedFormEntityName)
                            ? selectedFormEntityName
                            : moduleName,
                        updatedValues.CareRecipientUID,
                        updatedValues.CareRecipient,
                        CareEsioEntity.CareRecipient.EntityName
                    );
                    showSuccessToast(RAFActionMessage.RecordSaved);
                    hideProgress(progressDiv);
                    if (props.onSave) {
                        props.onSave();
                    }
                } else {
                    showWarningToast(RAFActionMessage.RecordNotSaved);
                    closeDialog();
                    hideProgress(progressDiv);
                }
            });
        }
    };

    const onCloseDialogClick = () => {
        if (props.onClose) {
            props.onClose();
        }
    };

    const getImageAttachments = () => {
        return <RAFImageFileAttachmentInput field="ImageAttachments" />;
    };

    let submitButtonRef = useRef(null);

    const enableSubmitButton = (g: FormRenderProps | null) => {
        if (g) {
            if (
                isNotNullAndUndefined(submitButtonRef) &&
                isNotNullAndUndefined(submitButtonRef.current) &&
                isNotNullAndUndefined(submitButtonRef.current.toggleBtnPermission)
            ) {
                submitButtonRef.current.toggleBtnPermission(g.submitting);
            }
        }
    };

    const onClickBackBtn = () => {
        const shiftActivityFormValue = R.clone(state.shiftActivityFormValue);
        shiftActivityFormValue.title = null;
        onUpdateActiveTabIndex(0);
        setState({ shiftActivityFormValue, selectedIndex: 0 });
    };

    const onSelectActivityItem = (item: LookUpRow) => {
        if (isNotNullAndUndefined(item)) {
            const shiftActivityFormValue = R.clone(state.shiftActivityFormValue);
            shiftActivityFormValue.title = item.Value;
            onUpdateActiveTabIndex(1);
            setState({ shiftActivityFormValue, selectedActivityValue: item.Value, selectedIndex: 1 });
        } else {
            const shiftActivityFormValue = R.clone(state.shiftActivityFormValue);
            shiftActivityFormValue.title = null;
            setState({ shiftActivityFormValue, selectedActivityValue: null });
        }
    };

    const tempFileStorage = useRef({});

    if (state.isActive) {
        if (state.isLoading === false) {
            const hasPermissionToAddOrEditActivitylog =
                checkHasPermissionToAddOrEditActivitylog(
                    state.tenantSettings,
                    props.selectedServiceTransactionRow
                );

            return (
                <>
                    {hasPermissionToAddOrEditActivitylog.hasPermissionToEdit ? (
                        <>
                            <div
                                className={
                                    "e-dlg-body-content taskUpdateForm position-relative pb-3 mb-3"
                                }
                            >
                                {state.selectedIndex === 0 && (
                                    isNotEmptyArray(state.standardizedLibraryLookupItems) && (
                                        <div className="row g-3">
                                            {state.standardizedLibraryLookupItems.map((item) => {
                                                return (
                                                    <div className="col-md-12" key={item.UID}>
                                                        <div
                                                            className={`${item.Value === state.selectedActivityValue
                                                                ? "custom__card__leftBorder primary "
                                                                : ""
                                                                }custom__card clickable`}
                                                            id={`card__${item.UID}`}
                                                            onClick={() => onSelectActivityItem(item)}
                                                        >
                                                            <div className="custom__card__content p-2 darkText">
                                                                <div className="d-flex align-items-center">
                                                                    <RAFIconImage
                                                                        iconCssClass={
                                                                            "far fa-hand-holding-seedling"
                                                                        }
                                                                        moduleavatar="transparent-avatar avatar-text avatar-text-square"
                                                                        iconSize="30"
                                                                        fontSize="16"
                                                                    />
                                                                    <span className="body_2 color_inherit ps-2 ecllipseSecondLine">
                                                                        {" "}
                                                                        {item.Value}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    )
                                )}
                                {state.selectedIndex === 1 && (
                                    <div className="mt-0 comments-details-page">
                                        {/* <CustomCardWidget
                                            title={state.selectedActivityValue}
                                            cardClassName="surface_neutral_base"
                                            cardHeaderClassName="subtitle_1 semi_bold"
                                        >
                                            <div> */}
                                        <RAFEntityProvider
                                            moduleName={state.selectedFormEntityName}
                                        >
                                            <RAFEntityContext.Consumer>
                                                {({ entity, formLibrary }) => {

                                                    const { shiftActivityFormValue } = state;
                                                    let hasCustomForm = false;
                                                    if (
                                                        isNotNullAndUndefined(formLibrary) &&
                                                        isNotNullAndUndefined(formLibrary.FormStructure)
                                                    ) {
                                                        hasCustomForm = true;
                                                    }

                                                    let formModel = new Model(
                                                        formLibrary?.FormStructure
                                                    );

                                                    formModel.data = {
                                                        start_date: state.shiftActivityFormValue.start_date,
                                                        title: state.shiftActivityFormValue.title,
                                                    };

                                                    // Handles selected files
                                                    formModel.onUploadFiles.add((_, options) => {
                                                        // A variable that will store files until the survey is completed
                                                        //const tempFileStorage = {};

                                                        // Add files to the temporary storage
                                                        if (
                                                            tempFileStorage.current[options.name] !==
                                                            undefined
                                                        ) {
                                                            tempFileStorage.current[options.name] =
                                                                tempFileStorage.current[
                                                                    options.name
                                                                ].concat(options.files);
                                                        } else {
                                                            tempFileStorage.current[options.name] =
                                                                options.files;
                                                        }

                                                        // Load file previews
                                                        const content = [];

                                                        let filesRead = 0; // Counter for files read

                                                        options.files.forEach((file) => {
                                                            const fileReader = new FileReader();
                                                            fileReader.onload = () => {
                                                                content.push({
                                                                    name: file.name,
                                                                    type: file.type,
                                                                    content: fileReader.result,
                                                                    file: file,
                                                                });
                                                                filesRead++; // Increment counter

                                                                // Check if all files have been read
                                                                if (filesRead === options.files.length) {
                                                                    // Return a file for preview as a { file, content } object
                                                                    options.callback(
                                                                        content.map((fileContent) => {
                                                                            return {
                                                                                file: fileContent.file,
                                                                                content: fileContent.content,
                                                                            };
                                                                        })
                                                                    );
                                                                }
                                                            };
                                                            fileReader.readAsDataURL(file);
                                                        });
                                                    });

                                                    // Handles file removal
                                                    formModel.onClearFiles.add((_, options) => {
                                                        // Empty the temporary file storage if "Clear All" is clicked
                                                        if (options.fileName === null) {
                                                            tempFileStorage.current[options.name] = [];
                                                            options.callback("success");
                                                            return;
                                                        }

                                                        // Remove an individual file
                                                        const tempFiles =
                                                            tempFileStorage &&
                                                            tempFileStorage.current[options.name];
                                                        if (!!tempFiles) {
                                                            const fileInfoToRemove = tempFiles.filter(
                                                                (file) => file.name === options.fileName
                                                            )[0];
                                                            if (fileInfoToRemove) {
                                                                const index =
                                                                    tempFiles.indexOf(fileInfoToRemove);
                                                                tempFiles.splice(index, 1);
                                                            }
                                                        }
                                                        //setTempFileStorage(tempFiles);
                                                        tempFileStorage.current[options.name] =
                                                            tempFiles;
                                                        options.callback("success");
                                                    });

                                                    formModel.applyTheme(customTheme as ITheme);
                                                    let nextButton =
                                                        formModel.navigationBar.getActionById(
                                                            "sv-nav-next"
                                                        );
                                                    let prevButton =
                                                        formModel.navigationBar.getActionById(
                                                            "sv-nav-prev"
                                                        );
                                                    let completeButton =
                                                        formModel.navigationBar.getActionById(
                                                            "sv-nav-complete"
                                                        );
                                                    nextButton.visible = false;
                                                    prevButton.visible = false;
                                                    completeButton.visible = false;

                                                    formModel.onErrorCustomText.add(function (
                                                        sender,
                                                        options
                                                    ) {
                                                        if (options.name == "required") {
                                                            //options.text = options.obj.title + " is required";
                                                            options.text = "This field is required";
                                                        }
                                                    });

                                                    return (
                                                        <RAFAttributeRelatedListProvider
                                                            moduleName={state.selectedFormEntityName}
                                                        >
                                                            <RAFAttributesContext.Consumer>
                                                                {({ queryAttributes }) => {
                                                                    return (
                                                                        <div style={{ background: "#fff" }}>
                                                                            <RAFForm
                                                                                formRef={(g) => {
                                                                                    enableSubmitButton(g);
                                                                                    return (rafShiftActivityDetailsForm =
                                                                                        g);
                                                                                }}
                                                                                initialValues={
                                                                                    shiftActivityFormValue
                                                                                }
                                                                                layout={RAFLayout.OneColumnLayout}
                                                                                onSubmit={(values) =>
                                                                                    onSubmitShiftActivity(
                                                                                        values,
                                                                                        formModel,
                                                                                        hasCustomForm
                                                                                    )
                                                                                }
                                                                            >
                                                                                <>
                                                                                    {hasCustomForm ? (
                                                                                        <>
                                                                                            <Survey model={formModel} />
                                                                                        </>
                                                                                    ) : (
                                                                                        <>
                                                                                            {isNotNullAndUndefined(
                                                                                                shiftActivityFormValue.Instructions
                                                                                            ) &&
                                                                                                state.selectedFormEntityName ===
                                                                                                "medication_log" && (
                                                                                                    <div className="row gx-2 gx-md-3 gy-1">
                                                                                                        <div className="secondary-header-text-dark p-2 bg-grey">
                                                                                                            <span>
                                                                                                                {
                                                                                                                    shiftActivityFormValue.Instructions
                                                                                                                }
                                                                                                            </span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                )}
                                                                                            <CreateContent
                                                                                                moduleName={
                                                                                                    state.selectedFormEntityName
                                                                                                }
                                                                                                progressDivId={`#add_shift_activity_dlg_outerDiv`}
                                                                                            />
                                                                                            <div className="mt-4">
                                                                                                {getImageAttachments()}
                                                                                            </div>
                                                                                        </>
                                                                                    )}
                                                                                </>
                                                                            </RAFForm>
                                                                        </div>
                                                                    );
                                                                }}
                                                            </RAFAttributesContext.Consumer>
                                                        </RAFAttributeRelatedListProvider>
                                                    );
                                                }}
                                            </RAFEntityContext.Consumer>
                                        </RAFEntityProvider>
                                        {/* </div>
                                        </CustomCardWidget> */}
                                    </div>
                                )}
                            </div>

                            {state.selectedIndex === 1 && (
                                <div className="e-dlg-footerContent">
                                    <div className="w-100">
                                        <div className="row gx-2">
                                            <div className="col-auto">
                                                <div className="col-auto ms-auto">
                                                    <RAFButtonComponent
                                                        type="button"
                                                        action={RAFButtonConstant.Previous}
                                                        onClick={onClickBackBtn}
                                                        idString="next_Content"
                                                        className="e-outline"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-auto ms-auto">
                                                <RAFPermissionRender
                                                    permissionName={
                                                        CareShiftLogPermissionConstants.CareShiftLogAdd
                                                    }
                                                >
                                                    <RAFButtonComponent
                                                        type="button"
                                                        isPrimary
                                                        action={RAFButtonConstant.Save}
                                                        onClick={() =>
                                                            rafShiftActivityDetailsForm &&
                                                            rafShiftActivityDetailsForm.form.submit()
                                                        }
                                                        ref={submitButtonRef}
                                                        idString="CreateContent"
                                                        disabled={
                                                            rafShiftActivityDetailsForm &&
                                                            rafShiftActivityDetailsForm.submitting
                                                        }
                                                    />
                                                </RAFPermissionRender>
                                            </div>
                                            <div className="col-auto">
                                                <RAFButtonComponent
                                                    type="button"
                                                    action={RAFButtonConstant.Cancel}
                                                    onClick={props.onClose}
                                                    idString="CreateContent"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </>
                    ) : (
                        <div className="e-dlg-content-outer">
                            <div className="p-3 py-2 columnChooserPanel customScrollBar">
                                <RAFDeletedRecordState
                                    title={`${hasPermissionToAddOrEditActivitylog.warningMessage}`}
                                />
                            </div>
                        </div>
                    )}
                </>
            );
        } else {
            return (
                <div className="container-fluid px-0">
                    <ACLoadingPanel loadingText="Preparing Data..." />
                </div>
            );
        }
    }
}

export default React.memo(AddShiftActivityFormContent);
