import { DialogComponent } from "@syncfusion/ej2-react-popups";
import DOMPurify from "dompurify";
import moment from "moment";
import React, {
  Fragment,
  PropsWithChildren,
  Reducer,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useReducer
} from "react";
import { PreventFocusOnDialogOpen } from "../../../../RAFComponents/Dialog/SFDialogUtils";
import CustomCardWidget from "../../../../RAFComponents/Navigation/CustomCardWidget";
import RAFRecordInfo from "../../../../RAFComponents/Navigation/RAFRecordInfo";
import { RAFCustomFilter } from "../../../../RAFComponents/RAFViewPanels/RAFFilterColumn/RAFCustomFilter";
import RAFPermissionRender from "../../../../RAFComponents/helpers/PermissionHelper";
import {
  RAFEventName,
  subscribeRAFEvent,
  triggerRAFEvent,
  unsubscribeRAFEvent,
} from "../../../../RAFComponents/helpers/RAFDocumentEvents";
import {
  IsNotNullOrWhiteSpace,
  convertUTCDateToLocalTimezone,
  isNotNullAndUndefined,
} from "../../../../RAFComponents/helpers/utils";
import { CareRecipientPermissionConstants } from "../../../../constants/CareESIO/CareESIOPermissionConstant";
import { RAFEntityName } from "../../../../constants/Common/EntityConstants";
import {
  ActivityRow,
  RAFActivityCommentType,
} from "../../../ActiveContacts/Activity/ActivityRow";
import ManageClientNote from "./ManageClientNote";

interface IProps {
  relatedToType?: string;
  careRecipientUID?: string;

  shiftUID?: string;

  mode?: "PinnedNotesOnly" | "PinnedNotesOrShiftNotes";

  uiMode?: "table";
  headerMode?: "Avatar" | "Icon";

  isRelatedSection?: boolean;
  modifiedDateRangeFilter?: RAFCustomFilter[];
  displayStyle?: "ImageOnly" | "TextOnly" | "Default";
  activityItem: ActivityRow;
  onSave?: () => void;
}

interface IState {
  showManageClientNoteContent: boolean;
}

const RAFRelatedNoteList = forwardRef(function RAFRelatedNoteList(
  {
    careRecipientUID,
    shiftUID,
    uiMode,
    activityItem,
    headerMode = "Avatar",
    isRelatedSection = false,
    ...props
  }: PropsWithChildren<IProps>,
  ref
) {
  const [state, setState] = useReducer<Reducer<IState, Partial<IState>>>(
    (state, newState) => ({ ...state, ...newState }),
    {
      showManageClientNoteContent: false,
    }
  );

  useEffect(() => {
    if (props.mode === "PinnedNotesOnly") {
      const eventKey = `${RAFEventName.ReloadPinnedActivityContent}_${RAFEntityName.Activity}_${props.mode}_${careRecipientUID}`;
      subscribeRAFEvent(eventKey, refreshActivityList);

      return () => {
        unsubscribeRAFEvent(eventKey, refreshActivityList);
      };
    }
  });

  const refreshActivityList = (args) => {
    if (isNotNullAndUndefined(args) && isNotNullAndUndefined(args.detail)) {
      let argscareRecipientUID = args.detail.careRecipientUID;
      if (argscareRecipientUID === careRecipientUID) {
        //refresh();
      }
    }
  };

  const refreshOnUpdate = (isPinnedItem: boolean) => {
    if (props.mode !== "PinnedNotesOnly" && isPinnedItem) {
      const eventKey = `${RAFEventName.ReloadPinnedActivityContent}_${RAFEntityName.Activity}_PinnedNotesOnly_${careRecipientUID}`;
      triggerRAFEvent(eventKey, {
        careRecipientUID: `${careRecipientUID}`,
      });
    }
    if (props.onSave) {
      props.onSave();
    }
  };

  //manage ClientNote_start
  const showManageClientNoteContent = () => {
    setState({ showManageClientNoteContent: true });
  };

  const manageClientNoteContent = () => {
    if (isNotNullAndUndefined(state.showManageClientNoteContent)) {
      return (
        <ManageClientNote
          objectUID={activityItem.UID}
          onSave={refreshOnUpdate.bind(this)}
          onClose={closeManageClientNoteDialog.bind(this)}
          onDelete={refreshOnUpdate.bind(this)}
          careRecipientUID={careRecipientUID}
          activityCommentType={RAFActivityCommentType.Note}
          isActive
          {...props}
        />
      );
    } else {
      return <div></div>;
    }
  };

  const closeManageClientNoteDialog = () => {
    setState({ showManageClientNoteContent: false });
  };

  //manage ClientNote_end

  //create client note start
  const onAddNoteClicked = () => {
    setState({ showManageClientNoteContent: true });
  };

  useImperativeHandle(ref, () => ({
    onAddNoteClicked() {
      onAddNoteClicked();
    },
  }));

  //create client note end

  const headerTemplate = (item) => {
    return (
      <div
        className={`position-relative pointer`}
        onClick={() => showManageClientNoteContent()}
      >
        <div className={`align-items-center row g-3 flex-nowrap`}>
          <div className="col-auto hidden">
            {/* <i className="fat fa-memo fa-md text-block"></i> */}
            {/* {isNullOrUndefined(item.DisplayOrder) || item.DisplayOrder === 99 ? "" :
              <div className="displayOrderDiv">
                <span className="pt-1">{item.DisplayOrder}</span>
              </div>
            } */}
            {/* {item.DisplayOrder === 99 ? <i className="fat fa-memo fa-md text-block"></i> :
              <div className="border-end" style={{ width: '18px' }}>{item.DisplayOrder}</div>
            } */}
            {/* {props.headerMode === 'Icon' ? <i className="fat fa-memo fa-md text-block"></i> :
              <RAFIconImage
                // value={
                //   isNotNullAndUndefined(item.UserName)
                //     ? item.UserName
                //     : "Satheesh Kumar"
                // }
                userIcon
                iconSize="40"
                fontSize="18"
                moduleavatar="activitiesItem__header__avatar e-avatar-small me-0 rgba-grey-avatar"
              ></RAFIconImage>
            } */}
          </div>
          <div className="col">
            <div className="d-flex align-items-center justify-content-between">
              <span className="header-text ecllipseFirstLine">
                {item.Subject}
              </span>

              {item.Pinned && (
                <span className="note_pin_outer_div">
                  <i className="note_pin_icon fas fa-thumbtack text_orange"></i>
                  {/* <i className="note_pin_icon fas fa-thumbtack" style={{ color: item.ColourCode }}></i> */}
                </span>
              )}
            </div>
            <div className="d-flex align-items-baseline">
              <span className="text_secondary_value text_secondary_value_sm pe-1">
                {" "}
                {item.Pinned ? "Pinned" : "Added"}
              </span>
              <span>
                {isNotNullAndUndefined(item.CreatedBy) ? (
                  <span className="d-flex align-items-center">
                    <span className="text_secondary_value text_secondary_value_sm pe-1">
                      by
                    </span>
                    <span className="text_secondary_value text_secondary_value_sm text-decoration-underline pe-1 ecllipseFirstLine word-break-all">
                      {item.CreatedBy}
                    </span>
                  </span>
                ) : (
                  ""
                )}
              </span>
              <div onClick={(e) => e.stopPropagation()}>
                <RAFRecordInfo
                  createdBy={item.CreatedBy}
                  createdDate={item.CreatedDate}
                  modifiedDate={item.ModifiedDate}
                  modifiedBy={item.ModifiedBy}
                  lastActivityDate={item.LastActivityDate}
                  content=""
                  hideClockIcon
                  dropdownBtnClassName="unset-custom-button-md p-0 remove-minWidth"
                  spanContentClassName="text_secondary_value text_secondary_value_sm"
                ></RAFRecordInfo>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const colorCodeName = isNotNullAndUndefined(activityItem.ColourCode)
    ? activityItem.ColourCode
    : "#67C6C2";

  const lastActivityDate = convertUTCDateToLocalTimezone(activityItem.LastActivityDate);

  return (
    <Fragment>
      <RAFPermissionRender
        permissionName={CareRecipientPermissionConstants.CareRecipientReadNotes}
      >
        <div className="timeline__item" key={activityItem.UID}>
          <div className="row gx-2 gx-md-3">
            <div className="col-auto pe-0">
              <div className="timeline__date__container">
                <div className="timeline__time">
                  {IsNotNullOrWhiteSpace(activityItem.LastActivityDate) ? (
                    <>
                      <span>{moment(lastActivityDate).format("hh:mm")}</span>
                      <span className="secondary-text">{` ${moment(
                        lastActivityDate
                      ).format("A")}`}</span>
                    </>
                  ) : (
                    <span className="body_3_dark_bold">NA</span>
                  )}
                </div>
              </div>
            </div>
            <div className="col-auto">
              <div className="timeline__item__circle"></div>
            </div>
            <div className="col">
              <div className="section__container">
                <div
                  className={`${isRelatedSection === false
                    ? `section__div section__div__maxHeight customScrollBar`
                    : ""
                    }`}
                >
                  <div className="row gy-2 gy-md-3 gx-0 flex-column">
                    <div key={activityItem.UID} className={"col-12"}>
                      <CustomCardWidget
                        // headerTemplate={headerTemplate(item)}
                        style={{
                          borderLeft: `5px solid ${colorCodeName}`,
                          backgroundColor: "#f5f5f5",
                          // backgroundColor: hexToRGBA(colorCodeName, 0.2),
                          // borderColor: hexToRGBA(colorCodeName, 1),
                          // background: `linear-gradient(180deg, ${hexToRGBA(colorCodeName, 0.4)}, ${hexToRGBA(colorCodeName, 0.1)})`
                        }}
                        // hideCardContent={
                        //   isNotNullAndUndefined(activityItem.Message)
                        //     ? false
                        //     : true
                        // }
                        cardContentClassName="p-3"
                      >
                        {headerTemplate(activityItem)}
                        {IsNotNullOrWhiteSpace(activityItem.Message) && (
                          <div
                            id={"summaryActivity_" + activityItem.UID}
                            // style={{ backgroundColor: item.ColorCode }}
                            className="mt-2"
                          >
                            <span
                              dangerouslySetInnerHTML={{
                                __html:
                                  activityItem !== null
                                    ? DOMPurify.sanitize(activityItem.Message)
                                    : undefined,
                              }}
                              className="remove_paragraph_margin profile_card_text_value description-text"
                            ></span>
                          </div>
                        )}
                      </CustomCardWidget>
                    </div>
                    {state.showManageClientNoteContent && (
                      <DialogComponent
                        //  header={isNotNullAndUndefined(state.selectedObjectUID) ? "Update Note" : 'Add Note'}
                        //showCloseIcon={false}
                        visible={state.showManageClientNoteContent}
                        cssClass={
                          "rightDialog createEditForm full-height dlg-new-style"
                        }
                        id="manageupdate_clientnote_dialog"
                        content={manageClientNoteContent.bind(this)}
                        isModal
                        target="body"
                        closeOnEscape={false}
                        close={closeManageClientNoteDialog.bind(this)}
                        open={PreventFocusOnDialogOpen}
                        zIndex={1200}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </RAFPermissionRender>
    </Fragment>
  );
});

export default React.memo(RAFRelatedNoteList);
