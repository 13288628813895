import { PropsWithChildren, Reducer, useEffect, useReducer } from "react";
import {
  IDialogProps,
  IsNullOrWhiteSpace,
  RAFFormComponentProps,
  isNotNullAndUndefined,
} from "../../../RAFComponents/helpers/utils";
import React from "react";
import { FormRenderProps } from "react-final-form";
import RAFEntityProvider, {
  RAFEntityContext,
} from "../../Common/Providers/RAFEntityProvider";
import RAFAttributeRelatedListProvider, {
  RAFAttributesContext,
} from "../../../RAFComponents/Providers/RAFAttributeRelatedListProvider";
import { ITheme, Model } from "survey-core";
import { customTheme } from "../../../RAFMaster/RMModules/FormLibrary/components/custom-theme";
import RAFObjectContextProvider, {
  RAFObjectContext,
} from "../../Common/Providers/RAFObjectContextProvider";
import {
  IsSuperAdmin,
  IsSuperAdminQueryString,
} from "../../../RAFComponents/helpers/AppHelper";
import {
  Constants,
  ESIOSuperAdministrator,
  RAFLayout,
} from "../../../constants/Common/Constants";
import RAFForm from "../../../RAFComponents/Inputs/RAFForm";
import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import RAFDeletedRecordState from "../../../RAFComponents/Navigation/RAFDeletedRecordState";
import { Survey } from "survey-react-ui";
import MyTaskWidget from "../../ActiveContacts/Task/MyTaskWidget";
import CustomCardWidget from "../../../RAFComponents/Navigation/CustomCardWidget";
interface IProps {
  moduleName?: string;
  objectUID?: string;
}

interface IState {
  isLoading: boolean;
  noContent: boolean;
  careShiftLogRow: any;
}
function CareShiftLogDetails({
  moduleName,
  objectUID,
  ...props
}: PropsWithChildren<IProps & RAFFormComponentProps & IDialogProps>) {
  let rafForm: FormRenderProps | null;
  const [state, setState] = useReducer<Reducer<IState, Partial<IState>>>(
    (state, newState) => ({ ...state, ...newState }),
    {
      isLoading: true,
      noContent: true,
      careShiftLogRow: null,
    }
  );

  const onCloseDialogClick = () => {
    if (props.onClose) {
      props.onClose();
    }
  };
  if (
    isNotNullAndUndefined(objectUID) &&
    !IsNullOrWhiteSpace(objectUID) &&
    props.isActive
  ) {
    return (
      <div
        className="h-100"
        key={`${moduleName}`}
        id={`raf_dlg_Outer_Div_${moduleName}`}
      >
        <RAFEntityProvider moduleName={moduleName}>
          <RAFAttributeRelatedListProvider moduleName={moduleName}>
            <RAFAttributesContext.Consumer>
              {({ queryAttributes }) => {
                return (
                  <RAFEntityContext.Consumer>
                    {({ entity, formLibrary }) => {
                      let hasCustomForm = false;
                      if (
                        isNotNullAndUndefined(formLibrary) &&
                        isNotNullAndUndefined(formLibrary.FormStructure)
                      ) {
                        hasCustomForm = true;
                      }
                      let formModel = new Model(formLibrary?.FormStructure);

                      formModel.applyTheme(customTheme as ITheme);
                      let nextButton =
                        formModel.navigationBar.getActionById("sv-nav-next");
                      let prevButton =
                        formModel.navigationBar.getActionById("sv-nav-prev");
                      let completeButton =
                        formModel.navigationBar.getActionById(
                          "sv-nav-complete"
                        );
                      nextButton.visible = false;
                      prevButton.visible = false;
                      completeButton.visible = false;

                      formModel.onErrorCustomText.add(function (
                        sender,
                        options
                      ) {
                        if (options.name == "required") {
                          //options.text = options.obj.title + " is required";
                          options.text = "This field is required";
                        }
                      });

                      return (
                        <RAFObjectContextProvider
                          moduleName={moduleName}
                          objectId={objectUID}
                          progressDivId={`#raf_dlg_Outer_Div_${moduleName}`}
                        >
                          <RAFObjectContext.Consumer>
                            {({
                              /*objectId, */ rafObject,
                              error,
                              saveObject,
                            }) => {
                              if (
                                isNotNullAndUndefined(rafObject) &&
                                !IsNullOrWhiteSpace(rafObject.UID)
                              ) {
                                formModel.data = rafObject;

                                let defaultField = isNotNullAndUndefined(
                                  queryAttributes
                                )
                                  ? queryAttributes.find(
                                    (x) => x.IsDefault === true
                                  )
                                  : null;
                                let defaultFieldValue = isNotNullAndUndefined(
                                  defaultField
                                )
                                  ? rafObject[defaultField.PropertyName]
                                  : null;

                                let isSuperAdminQueryString =
                                  IsSuperAdminQueryString();
                                let isSuperAdmin = IsSuperAdmin();
                                if (
                                  entity.EntityName === "incident" &&
                                  (isSuperAdmin || isSuperAdminQueryString)
                                ) {
                                  formModel.setValue(
                                    "role_permission_name",
                                    ESIOSuperAdministrator
                                  );
                                }

                                formModel.mode = "display";

                                return (
                                  <RAFForm
                                    initialValues={rafObject}
                                    layout={RAFLayout.TwoColumnLayout}
                                    className="h-100"
                                  >
                                    <div className={"dlg-new-style"}>
                                      <div
                                        className={
                                          "e-dlg-headerContent h-auto tab_fixedHeader_scrollableContent_dialog_header"
                                        }
                                      >
                                        <div className="d-flex justify-content-between w-100">
                                          <div className={"e-dlg-header"}>
                                            {`${formLibrary.Title} Details`}
                                          </div>
                                          <ButtonComponent
                                            type="button"
                                            cssClass="primary-custom-button"
                                            iconCss="fas fa-xmark"
                                            onClick={() => onCloseDialogClick()}
                                          ></ButtonComponent>
                                        </div>
                                      </div>

                                      <div
                                        className={
                                          "e-dlg-body-content taskUpdateForm position-relative p-0 mb-3 surface_neutral_light"
                                        }
                                      >
                                        <div>
                                          <Survey model={formModel} />
                                        </div>
                                        {isNotNullAndUndefined(entity) &&
                                          entity.EntitySettings &&
                                          entity.EntitySettings.EnableTask ===
                                          true && (
                                            <div className="px-2-5"
                                            >
                                              <CustomCardWidget
                                                cardContentClassName="p-0"
                                                style={{boxShadow: '0px 0px 0px 1px rgba(0, 0, 0, 0.05), 0px 8px 16px 0px rgba(0, 0, 0, 0.1), 0px 2px 4px 0px rgba(0, 0, 0, 0.1)'}}
                                              >
                                                <MyTaskWidget
                                                  //key={this.state.myTaskWidgetKey}
                                                  relatedToUID={objectUID}
                                                  relatedTo={defaultFieldValue}
                                                  relatedToType={
                                                    entity.EntityName
                                                  }
                                                  // refreshOnCompleteTask={() => this.getContact()}
                                                  // relatedCommentsSectionUpdate={() =>
                                                  //     this.refreshOnCommentsUpdated()
                                                  // }
                                                  // refreshTaskAndComment={() =>
                                                  //     this.refreshTaskAndComment()
                                                  // }
                                                  // refreshOnCommentsUpdated={() =>
                                                  //     this.refreshOnCommentsUpdated()
                                                  // }
                                                  showAllTasks={true}
                                                />
                                              </CustomCardWidget>
                                            </div>
                                          )}
                                      </div>
                                      {/* <div className="e-dlg-footerContent">
                                        <div className="w-100">
                                          <div className="row gx-2">
                                            {isNotNullAndUndefined(
                                              props.onDelete
                                            ) ? (
                                              <div className="col-auto">
                                                <RAFButtonComponent
                                                  action={
                                                    RAFButtonConstant.Delete
                                                  }
                                                  onClick={() =>
                                                    onClickDeleteCareShiftLog()
                                                  }
                                                  idString="DeleteContent"
                                                  className="e-danger e-outline"
                                                />
                                              </div>
                                            ) : (
                                              ""
                                            )}
                                            <div className="col-auto ms-auto">
                                              <RAFButtonComponent
                                                ref={submitButtonRef}
                                                isPrimary
                                                action={
                                                  RAFButtonConstant.Save
                                                }
                                                onClick={() =>
                                                  rafForm &&
                                                  rafForm.form.submit()
                                                }
                                                idString="EditContent"
                                                disabled={
                                                  rafForm &&
                                                  rafForm.submitting
                                                }
                                              />
                                            </div>
                                            <div className="col-auto">
                                              <RAFButtonComponent
                                                action={
                                                  RAFButtonConstant.Cancel
                                                }
                                                onClick={props.onClose}
                                                idString="EditContent"
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </div> */}
                                    </div>
                                  </RAFForm>
                                );
                              } else {
                                if (error === Constants.RAFObject_NoContent) {
                                  return (
                                    <div className="container-fluid px-0">
                                      <RAFDeletedRecordState title="This item is either deleted or You do not have sufficient privileges to view this item." />
                                    </div>
                                  );
                                } else {
                                  return (
                                    <div
                                      className="container-fluid px-0"
                                      style={{
                                        background: "transparent",
                                      }}
                                    >
                                      {/* <ACLoadingPanel loadingText="Loading..." /> */}
                                    </div>
                                  );
                                }
                              }
                            }}
                          </RAFObjectContext.Consumer>
                        </RAFObjectContextProvider>
                      );
                    }}
                  </RAFEntityContext.Consumer>
                );
              }}
            </RAFAttributesContext.Consumer>
          </RAFAttributeRelatedListProvider>
        </RAFEntityProvider>
      </div>
    );
  } else {
    return (
      <div
        className="container-fluid px-0"
        style={{ background: "transparent" }}
      ></div>
    );
  }
}

export default React.memo(CareShiftLogDetails);
