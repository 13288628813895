import * as R from "ramda";
import React, {
  Fragment,
  PropsWithChildren,
  Reducer,
  useEffect,
  useReducer,
  useRef,
} from "react";
import { IconPickerItem } from "react-fa-icon-picker";
import { FormRenderProps } from "react-final-form";
import { ITheme, Model } from "survey-core";
import "survey-core/defaultV2.min.css";
import { Survey } from "survey-react-ui";
import RAFForm from "../../../RAFComponents/Inputs/RAFForm";
import RAFImageFileAttachmentInput from "../../../RAFComponents/Inputs/RAFImageFileAttachmentInput";
import CustomCardWidget from "../../../RAFComponents/Navigation/CustomCardWidget";
import RAFButtonComponent from "../../../RAFComponents/Navigation/RAFButtonComponent";
import RAFCollapse from "../../../RAFComponents/Navigation/RAFCollapse";
import RAFCollapseWithPlusIcon from "../../../RAFComponents/Navigation/RAFCollapseWithPlusIcon";
import RAFEmptyState from "../../../RAFComponents/Navigation/RAFEmptyState";
import RAFIconImage from "../../../RAFComponents/Navigation/RAFIconImage";
import RAFAttributeRelatedListProvider, {
  RAFAttributesContext,
} from "../../../RAFComponents/Providers/RAFAttributeRelatedListProvider";
import {
  showSuccessToast,
  showWarningToast,
} from "../../../RAFComponents/Utility/RAFToastComponent";
import {
  IsSuperAdmin,
  IsSuperAdminQueryString,
  RAFActionMessage,
  getRelatedRecords,
  hideProgress,
  showProgress,
} from "../../../RAFComponents/helpers/AppHelper";
import RAFPermissionRender from "../../../RAFComponents/helpers/PermissionHelper";
import {
  ConvertSystemName,
  IDialogProps,
  IsNotNullOrWhiteSpace,
  getSaveRequest,
  getTimeDifferenceInMinutes,
  isNotEmptyArray,
  isNotNullAndUndefined,
  isNullOrUndefined,
  propertyOf,
} from "../../../RAFComponents/helpers/utils";
import { FormLibraryRow } from "../../../RAFComponents/models/Common/FormLibraryRow";
import { LookUpRow } from "../../../RAFComponents/models/CompositeTypes/LookUpRow";
import { EntityRow } from "../../../RAFMaster/RMModules/Entity/EntityRow";
import { customTheme } from "../../../RAFMaster/RMModules/FormLibrary/components/custom-theme";
import {
  RetrieveFormLibraryById,
  getEntityByObjectName,
  getPredefinedURLAndUplodToS3,
} from "../../../RAFMaster/helpers/RMutils";
import ACLoadingPanel from "../../../components/shared/ACLoadingPanel";
import { CareShiftLogPermissionConstants } from "../../../constants/CareESIO/CareESIOPermissionConstant";
import { CareEsioEntity } from "../../../constants/CareESIO/CareEsioConstants";
import {
  ESIOSuperAdministrator,
  RAFActionStatus,
  RAFButtonConstant,
  RAFLayout,
} from "../../../constants/Common/Constants";
import { NavigateParamsProps } from "../../../router";
import {
  DocumentImageAttachmentRow,
  uploadImageAttachment,
} from "../../ActiveContacts/Document/Library/DocumentHelper";
import { checkHasPermissionToAddOrEditActivitylog } from "../../ActiveContacts/ServiceTransaction/ServiceTransactionHelper";
import { ServiceTransactionRow } from "../../ActiveContacts/ServiceTransaction/ServiceTransactionRow";
import CreateContent from "../../Common/Create/CreateContent";
import RAFEntityProvider, {
  RAFEntityContext,
} from "../../Common/Providers/RAFEntityProvider";
import { getUserTenantSettings } from "../../Common/TenantSettings/TenantSettingsHelper";
import { TenantSettingsRow } from "../../Common/TenantSettings/TenantSettingsRow";
import { SaveShiftActivity } from "../CareShiftLog/CareShiftLogHelper";
import MedicationContent from "../Medication/MedicationContent";
import { RecurringConfigRow } from "../RecurringConfig/RecurringConfigRow";

export const isValidActivityStarEndTimeRange1 = (
  shiftStartTime,
  shiftEndTime,
  activityStartTime,
  activityEndTime
) => {
  if (
    isNotNullAndUndefined(shiftStartTime) &&
    isNotNullAndUndefined(shiftEndTime) &&
    isNotNullAndUndefined(activityStartTime)
  ) {
    // set mintime as shift time-2 hrs
    const startTimeMinValue = new Date(shiftStartTime);
    startTimeMinValue.setHours(startTimeMinValue.getHours() - 2);

    // set maxtime as shift time+2 hrs
    const startTimeMaxValue = new Date(shiftEndTime);
    startTimeMaxValue.setHours(startTimeMaxValue.getHours() + 2);

    const startTime = new Date(activityStartTime);
    const endTime = IsNotNullOrWhiteSpace(activityEndTime)
      ? new Date(activityEndTime)
      : null;

    if (startTime < startTimeMinValue) {
      showWarningToast(
        "Activity start time should be within 2 hours of shift start date."
      );
      return false;
    } else if (startTime > startTimeMaxValue) {
      showWarningToast(
        "Activity start time should be within 2 hours of shift start date."
      );
      return false;
    } else if (isNotNullAndUndefined(endTime)) {
      if (endTime < startTimeMinValue) {
        showWarningToast(
          "Activity end time should be within 2 hours of shift start date."
        );
        return false;
      } else if (endTime > startTimeMaxValue) {
        showWarningToast(
          "Activity end time should be within 2 hours of shift start date."
        );
        return false;
      }
    }
  }

  return true;
};

export const isStartDateGreaterThanEqualToShiftDate = (
  shiftStartDate,
  activityStartDate,
  activityEndDate
) => {
  if (IsNotNullOrWhiteSpace(shiftStartDate)) {
    const startDate = IsNotNullOrWhiteSpace(activityStartDate)
      ? new Date(activityStartDate)
      : null;
    const endDate = IsNotNullOrWhiteSpace(activityEndDate)
      ? new Date(activityEndDate)
      : null;
    const shiftDate = new Date(shiftStartDate);

    if (startDate && startDate < shiftDate) {
      showWarningToast(
        "Activity start date and end date should be greater than or equal to shift start date."
      );
      return false;
    }

    if (endDate && endDate < shiftDate) {
      showWarningToast(
        "Activity start date and end date should be greater than or equal to shift start date."
      );
      return false;
    }
  }

  return true;
};

interface IProps {
  shiftFormInitialValues: any;
  careRecipientUID: string;
  allForms: FormLibraryRow[];
  selectedServiceTransactionRow: ServiceTransactionRow;
  minTimeValue: Date;
  maxTimeValue: Date;
  onUpdateActiveTabIndex: (activeTabIndex: number) => void;
  onSelectRoutine: (
    selectedHeaderTabValue?: "GenericRoutines" | "FromSupportPlan"
  ) => void;
  showDialogHeader?: boolean;
}

interface IState {
  isLoading: boolean;
  shiftActivityFormValue: any;
  filteredForms: FormLibraryRow[];
  recurringConfigItems: RecurringConfigRow[];
  selectedFormLibrary: FormLibraryRow;
  selectedFormEntityName: string;
  selectedSubFormLibrary: FormLibraryRow;
  selectedSubFormEntityName: string;
  selectedIndex: 0 | 1 | 2;
  allRecommendedMedications: any[];
  medicationEntity: EntityRow;

  tenantSettings: TenantSettingsRow;
}

function AddShiftFormContent({
  ...props
}: PropsWithChildren<IProps & NavigateParamsProps & IDialogProps>) {
  let submitButtonRef = useRef(null);
  const tempFileStorage = useRef({});

  let rafShiftActivityDetailsForm: FormRenderProps | null;
  const moduleName = CareEsioEntity.CareShiftActivity.EntityName;

  const [state, setState] = useReducer<Reducer<IState, Partial<IState>>>(
    (state, newState) => ({ ...state, ...newState }),
    {
      isLoading: true,
      shiftActivityFormValue: null,
      filteredForms: null,
      recurringConfigItems: null,
      selectedFormEntityName: null,
      selectedFormLibrary: null,
      selectedIndex: 0,
      selectedSubFormLibrary: null,
      selectedSubFormEntityName: null,
      allRecommendedMedications: null,
      medicationEntity: null,
      tenantSettings: null,
    }
  );

  useEffect(() => {
    refresh();
  }, []);

  const refresh = async () => {
    if (props.isActive) {
      setState({ isLoading: true });
      const shiftActivityFormValue = isNotNullAndUndefined(
        props.shiftFormInitialValues
      )
        ? props.shiftFormInitialValues
        : null;

      if (isNotNullAndUndefined(shiftActivityFormValue)) {
        shiftActivityFormValue.ImageAttachments = null;
      }

      const filteredForms: FormLibraryRow[] = isNotEmptyArray(props.allForms)
        ? props.allForms.filter(
          (x) =>
            x.Entity !== CareEsioEntity.ClientNote.EntityName &&
            x.Entity !== CareEsioEntity.CareCompleteShift.EntityName &&
            x.Entity !== "completion_feedback" &&
            x.Entity !== moduleName
          //x.Entity !== "suggestion_form" &&
          //x.Entity !== "shift_log_form" &&
          //x.Entity !== "concern_form"
        )
        : [];

      const [tenantSettings, recurringConfigItems] = await Promise.all([
        getUserTenantSettings(),
        await getRelatedRecords(
          CareEsioEntity.RecurringConfig.EntityName,
          null,
          propertyOf<RecurringConfigRow>("RelatedToUID"),
          props.careRecipientUID
        ),
      ]);

      setState({
        isLoading: false,
        shiftActivityFormValue,
        filteredForms,
        recurringConfigItems,
        tenantSettings,
      });
    } else {
      setState({ isLoading: false });
    }
  };

  const closeDialog = () => {
    if (props.onClose) {
      props.onClose();
    }
  };

  const onSubmitShiftActivity = (
    shiftActivityFormValue?: any,
    formModel?: any,
    hasCustomForm?: boolean
  ) => {
    const { selectedServiceTransactionRow } = props;
    const { selectedFormLibrary, selectedFormEntityName } = state;
    // let shiftFormInitialValues = R.clone(shiftActivityFormValue);
    let updatedValues = R.clone(shiftActivityFormValue);
    // let updatedValues = R.clone(shiftFormInitialValues);

    if (hasCustomForm) {
      let isFormValid = formModel.validate(true, true);
      if (isFormValid) {
        let progressDiv = showProgress(`#add_shift_activity_dlg_outerDiv`);
        //uploadFileAndGetFormData(formModel, moduleName, "create").then(
        getPredefinedURLAndUplodToS3(formModel, moduleName, "create").then(
          (formData) => {
            //set default values
            if (
              selectedFormLibrary.CategoryType ===
              CareEsioEntity.CareRecipientCQIRegister.DisplayName
            ) {
              formData["add_to_cqi"] = true;
              formData["care_shift_type"] = selectedFormLibrary.Title;
              if (isNullOrUndefined(formData["title"])) {
                formData["title"] = selectedFormLibrary.Title;
              }
            }

            updatedValues = { ...updatedValues, ...formData };

            let saveRequestData = getSaveRequest(
              updatedValues,
              updatedValues.UID
            );
            saveRequestData.EntityName = isNotNullAndUndefined(
              selectedFormEntityName
            )
              ? selectedFormEntityName
              : moduleName;

            let url = "DataList/SaveForm";
            SaveShiftActivity(saveRequestData, url).then(async (response) => {
              if (isNotNullAndUndefined(response)) {
                showSuccessToast(RAFActionMessage.RecordSaved);
                hideProgress(progressDiv);
                if (props.onSave) {
                  props.onSave();
                }
              } else {
                showWarningToast(RAFActionMessage.RecordNotSaved);
                closeDialog();
                hideProgress(progressDiv);
              }
            });
          }
        );
      }
    } else {
      const { selectedSubFormEntityName, selectedSubFormLibrary } = state;

      const startDate = updatedValues.StartDate;
      const endDate = updatedValues.EndDate;

      const shiftStartDate = isNotNullAndUndefined(
        selectedServiceTransactionRow
      )
        ? selectedServiceTransactionRow.StartDate
        : null;

      const shiftEndDate = isNotNullAndUndefined(selectedServiceTransactionRow)
        ? selectedServiceTransactionRow.EndDate
        : null;

      // const isValidDateRange = isValidActivityStarEndTimeRange(
      //   shiftStartDate,
      //   shiftEndDate,
      //   startDate,
      //   endDate
      // );

      // if (!isValidDateRange) {
      //   return;
      // }

      const duration = getTimeDifferenceInMinutes(startDate, endDate);
      updatedValues.Duration = duration;

      if (isNullOrUndefined(updatedValues.Title)) {
        updatedValues.Title = selectedFormLibrary.Title;
      }

      if (
        selectedFormLibrary.CategoryType ===
        CareEsioEntity.CareRecipientCQIRegister.DisplayName
      ) {
        updatedValues.AddToCqi = true;
        updatedValues.CareShiftType = selectedFormLibrary.Title;
        if (isNullOrUndefined(updatedValues.Title)) {
          updatedValues.Title = selectedFormLibrary.Title;
        }
      }

      if (
        isNotNullAndUndefined(selectedSubFormLibrary) &&
        isNotNullAndUndefined(selectedSubFormEntityName)
      ) {
        if (
          updatedValues.AddAdditionalForm === "Yes" &&
          updatedValues.CareShiftType !== "None" &&
          updatedValues.CareShiftType === selectedSubFormLibrary.Title
        ) {
          for (let key in updatedValues) {
            if (
              key.startsWith("SubForm") &&
              key !== "SubForm" + selectedSubFormEntityName
            ) {
              delete updatedValues[key];
            }
          }

          let subFormValues =
            updatedValues["SubForm" + selectedSubFormEntityName];
          if (
            selectedSubFormLibrary.CategoryType ===
            CareEsioEntity.CareRecipientCQIRegister.DisplayName
          ) {
            if (isNotNullAndUndefined(subFormValues)) {
              subFormValues.AddToCqi = true;
              //subFormValues.CareShiftType = selectedSubFormLibrary.Title;
              if (isNullOrUndefined(updatedValues.Title)) {
                updatedValues.Title = selectedSubFormLibrary.Title;
              }
            }
          }
        }
      }

      if (
        updatedValues.AddAdditionalForm === "No" ||
        updatedValues.CareShiftType === "None"
      ) {
        updatedValues.CareShiftType = null;
        for (let key in updatedValues) {
          if (key.includes("SubForm")) {
            delete updatedValues[key];
          }
        }
      }

      const imageAttachments: DocumentImageAttachmentRow[] =
        updatedValues.ImageAttachments;
      const attachments: LookUpRow[] = isNotEmptyArray(imageAttachments)
        ? imageAttachments.map((item) => {
          return {
            UID: item.UID,
            Value: item.Name,
          };
        })
        : [];
      updatedValues.Attachments = attachments;
      delete updatedValues.ImageAttachments;

      let saveRequestData = getSaveRequest(updatedValues, updatedValues.UID);
      saveRequestData.EntityName = isNotNullAndUndefined(selectedFormEntityName)
        ? selectedFormEntityName
        : moduleName;

      //console.log("onSubmitShiftActivity saveRequestData", saveRequestData);
      //return;
      let progressDiv = showProgress(`#add_shift_activity_dlg_outerDiv`);

      SaveShiftActivity(saveRequestData).then(async (response) => {
        if (isNotNullAndUndefined(response)) {
          const onUploadAttachment = await uploadImageAttachment(
            imageAttachments,
            null,
            response.entityId,
            response.objectName,
            isNotNullAndUndefined(selectedFormEntityName)
              ? selectedFormEntityName
              : moduleName,
            updatedValues.CareRecipientUID,
            updatedValues.CareRecipient,
            CareEsioEntity.CareRecipient.EntityName
          );
          showSuccessToast(RAFActionMessage.RecordSaved);
          hideProgress(progressDiv);
          if (props.onSave) {
            props.onSave();
          }
        } else {
          showWarningToast(RAFActionMessage.RecordNotSaved);
          closeDialog();
          hideProgress(progressDiv);
        }
      });
    }
  };

  const onUpdateActiveTabIndex = (activeTabIndex: number) => {
    if (props.onUpdateActiveTabIndex) {
      props.onUpdateActiveTabIndex(activeTabIndex);
    }
  };

  const onClickMedicationItem = async (medication) => {
    let formLibrary;
    if (isNotEmptyArray(props.allForms)) {
      formLibrary = props.allForms.find((x) => x.Entity === "medication_log");
    }
    const { selectedServiceTransactionRow, shiftFormInitialValues } = props;

    if (isNotNullAndUndefined(medication)) {
      let newShiftActivity: any = {}; //Initialize newShiftActivity as an empty object
      newShiftActivity["title"] = medication.MedicationName;
      newShiftActivity["instructions"] = medication.Dose;

      newShiftActivity["unit_of_measurement"] = medication.UnitOfMeasurement;
      newShiftActivity["dosage_count"] = medication.DosageCount;

      newShiftActivity["care_recipientuid"] =
        shiftFormInitialValues.CareRecipientUID;
      newShiftActivity["care_recipient"] = shiftFormInitialValues.CareRecipient;
      newShiftActivity["start_date"] = shiftFormInitialValues.StartDate;

      //newShiftActivity["status"] = RAFActionStatus.Done;
      newShiftActivity["log_date"] = new Date();
      newShiftActivity["form_library"] = formLibrary.Title;
      newShiftActivity["form_libraryuid"] = formLibrary.UID;

      if (isNotNullAndUndefined(selectedServiceTransactionRow)) {
        newShiftActivity["assignee"] = selectedServiceTransactionRow.Assignee;
        newShiftActivity["assigneeuid"] =
          selectedServiceTransactionRow.AssigneeUID;

        newShiftActivity["task"] = selectedServiceTransactionRow.Title;
        newShiftActivity["taskuid"] = selectedServiceTransactionRow.UID;
        /*newShiftActivity["start_date"] = startDate;
        newShiftActivity["end_date"] = endDate;
        newShiftActivity["start_time"] = startDate;
        newShiftActivity["end_time"] = endDate;

        newShiftActivity["duration"] = getTimeDifferenceInMinutes(
          startDate,
          endDate
        );*/
      }
      onUpdateActiveTabIndex(2);
      setState({
        shiftActivityFormValue: newShiftActivity,
        selectedIndex: 2,
        selectedFormEntityName: formLibrary.Entity,
        selectedFormLibrary: formLibrary,
      });
    }
  };

  const onClickRecurringConfigItem = async (item: RecurringConfigRow) => {
    if (isNotNullAndUndefined(item)) {
      let progressDiv = showProgress(`#add_shift_activity_dlg_outerDiv`);
      const formUID = item.FormUID;
      const formLibraryRow: FormLibraryRow = await RetrieveFormLibraryById(
        formUID
      );
      hideProgress(progressDiv);
      if (isNotNullAndUndefined(formLibraryRow)) {
        onClickFormItem(formLibraryRow);
      } else {
        showWarningToast("Form not found");
      }
    }
  };

  const onClickFormItem = async (item: FormLibraryRow) => {
    const { selectedServiceTransactionRow, shiftFormInitialValues } = props;
    let newShiftActivity: any = {}; //Initialize newShiftActivity as an empty object
    if (
      isNotNullAndUndefined(item) &&
      isNotNullAndUndefined(item.FormStructure)
    ) {
      newShiftActivity["title"] = null;
      newShiftActivity["care_recipientuid"] =
        shiftFormInitialValues.CareRecipientUID;
      newShiftActivity["care_recipient"] = shiftFormInitialValues.CareRecipient;

      newShiftActivity["status"] = RAFActionStatus.Done;
      newShiftActivity["log_date"] = new Date();
      newShiftActivity["form_library"] = item.Title;
      newShiftActivity["form_libraryuid"] = item.UID;

      if (isNotNullAndUndefined(selectedServiceTransactionRow)) {
        const startDate =
          isNotNullAndUndefined(shiftFormInitialValues) &&
            isNotNullAndUndefined(shiftFormInitialValues.StartDate)
            ? shiftFormInitialValues.StartDate
            : null;
        //?? new Date();

        const endDate =
          isNotNullAndUndefined(shiftFormInitialValues) &&
            isNotNullAndUndefined(shiftFormInitialValues.EndDate)
            ? shiftFormInitialValues.EndDate
            : //: isNotNullAndUndefined(selectedServiceTransactionRow.EndDate)
            //? new Date(selectedServiceTransactionRow.EndDate)
            //? new Date(selectedServiceTransactionRow.EndDate + "Z")
            null;

        newShiftActivity["assignee"] = selectedServiceTransactionRow.Assignee;
        newShiftActivity["assigneeuid"] =
          selectedServiceTransactionRow.AssigneeUID;

        newShiftActivity["task"] = selectedServiceTransactionRow.Title;
        newShiftActivity["taskuid"] = selectedServiceTransactionRow.UID;
        newShiftActivity["start_date"] = startDate;
        newShiftActivity["end_date"] = endDate;
        newShiftActivity["start_time"] = startDate;
        newShiftActivity["end_time"] = endDate;

        newShiftActivity["duration"] = getTimeDifferenceInMinutes(
          startDate,
          endDate
        );

        if (item.Entity === "complaint_register" || item.Entity === "risk") {
          //newShiftActivity.Status = RAFActionStatus.InProgress;
          newShiftActivity["status"] = RAFActionStatus.Reported;
          //newShiftActivity["status"] = RAFActionStatus.Submitted;
        }
        if (item.Entity === "incident") {
          //newShiftActivity.Status = RAFActionStatus.InProgress;
          newShiftActivity["status"] = RAFActionStatus.Reported;
          newShiftActivity["reported_date_time"] = new Date();
        }
      }
    } else {
      let status = RAFActionStatus.Done;
      if (item.Entity === "risk" || item.Entity === "complaint_register") {
        status = RAFActionStatus.InProgress;
      }
      //else if (item.Entity === "medication_log") {
      //   status = null;
      // }
      newShiftActivity.Title = null;
      newShiftActivity.CareRecipientUID =
        shiftFormInitialValues.CareRecipientUID;
      newShiftActivity.CareRecipient = shiftFormInitialValues.CareRecipient;
      newShiftActivity.LogDate = new Date();
      newShiftActivity.FormLibrary = item.Title;
      newShiftActivity.FormLibraryUID = item.UID;
      if (isNotNullAndUndefined(selectedServiceTransactionRow)) {
        const startDate =
          isNotNullAndUndefined(shiftFormInitialValues) &&
            isNotNullAndUndefined(shiftFormInitialValues.StartDate)
            ? shiftFormInitialValues.StartDate
            : null;

        const endDate =
          isNotNullAndUndefined(shiftFormInitialValues) &&
            isNotNullAndUndefined(shiftFormInitialValues.EndDate)
            ? shiftFormInitialValues.EndDate
            : //: isNotNullAndUndefined(selectedServiceTransactionRow.EndDate)
            //? new Date(selectedServiceTransactionRow.EndDate)
            //? new Date(selectedServiceTransactionRow.EndDate + "Z")
            null;

        newShiftActivity.Assignee = selectedServiceTransactionRow.Assignee;
        newShiftActivity.AssigneeUID =
          selectedServiceTransactionRow.AssigneeUID;

        newShiftActivity.Task = selectedServiceTransactionRow.Title;
        newShiftActivity.TaskUID = selectedServiceTransactionRow.UID;
        newShiftActivity.StartDate = startDate;
        newShiftActivity.EndDate = endDate;
        newShiftActivity.StartTime = startDate;
        newShiftActivity.EndTime = endDate;

        newShiftActivity.Duration = getTimeDifferenceInMinutes(
          startDate,
          endDate
        );
        newShiftActivity.Status = status;
      }
    }

    if (item.Entity === "medication_log") {
      onUpdateActiveTabIndex(
        isNotEmptyArray(state.allRecommendedMedications) ? 1 : 2
      );
      let progressDiv = showProgress(`#add_shift_activity_dlg_outerDiv`);
      const medicationEntity = isNotNullAndUndefined(state.medicationEntity)
        ? state.medicationEntity
        : await getEntityByObjectName({
          ObjectName: ConvertSystemName(
            CareEsioEntity.CareRecipientMedication.EntityName
          ),
        });

      const allRecommendedMedications = isNotEmptyArray(
        state.allRecommendedMedications
      )
        ? state.allRecommendedMedications
        : await getRelatedRecords(
          CareEsioEntity.CareRecipientMedication.EntityName,
          null,
          "CareRecipientUID",
          props.careRecipientUID,
          medicationEntity.UID
        );
      hideProgress(progressDiv);
      setState({
        shiftActivityFormValue: newShiftActivity,
        selectedIndex: isNotEmptyArray(allRecommendedMedications) ? 1 : 2,
        allRecommendedMedications,
        medicationEntity,
        selectedFormLibrary: item,
        selectedFormEntityName: item.Entity,
      });
    } else {
      onUpdateActiveTabIndex(2);
      setState({
        shiftActivityFormValue: newShiftActivity,
        selectedIndex: 2,
        selectedFormLibrary: item,
        selectedFormEntityName: item.Entity,
      });
    }
  };

  const onClickOtherMedications = () => {
    onUpdateActiveTabIndex(2);
    setState({
      selectedIndex: 2,
    });
  };

  const onClickBackBtn = () => {
    onUpdateActiveTabIndex(0);
    setState({
      selectedIndex: 0,
      selectedFormEntityName: null,
      selectedFormLibrary: null,
      selectedSubFormEntityName: null,
      selectedSubFormLibrary: null,
    });
  };

  const getImageAttachments = () => {
    return <RAFImageFileAttachmentInput field="ImageAttachments" />;
  };

  const enableSubmitButton = (g: FormRenderProps | null) => {
    if (g) {
      if (
        isNotNullAndUndefined(submitButtonRef) &&
        isNotNullAndUndefined(submitButtonRef.current) &&
        isNotNullAndUndefined(submitButtonRef.current.toggleBtnPermission)
      ) {
        submitButtonRef.current.toggleBtnPermission(g.submitting);
      }
    }
  };

  const onCloseDialogClick = () => {
    if (props.onClose) {
      props.onClose();
    }
  };

  if (props.isActive) {
    if (state.isLoading === false) {
      const { shiftActivityFormValue, filteredForms, recurringConfigItems } =
        state;

      let groupedByCategoryType = isNotEmptyArray(filteredForms)
        ? filteredForms.reduce((groups, item) => {
          let categoryType = item.CategoryType;
          if (categoryType === "Shift Log") {
            categoryType = "Observation Forms";
          } else if (categoryType === "CQI Register") {
            categoryType = "Quality and Safety Forms";
          }
          if (!groups[categoryType]) {
            groups[categoryType] = [];
          }
          groups[categoryType].push(item);
          return groups;
        }, {})
        : [];

      const hasPermissionToAddOrEditActivitylog =
        checkHasPermissionToAddOrEditActivitylog(
          state.tenantSettings,
          props.selectedServiceTransactionRow
        );

      const headerClass = "e-dlg-headerContent h-auto px-3";

      return (
        <>
          {hasPermissionToAddOrEditActivitylog.hasPermissionToEdit ? (
            <>
              {props.showDialogHeader && (
                <div className={headerClass}>
                  <div className="d-flex align-items-center justify-content-between w-100">
                    {state.selectedIndex !== 0 ? (
                      <RAFButtonComponent
                        displayMode="DialogCloseBtn"
                        iconCss={RAFButtonConstant.ArrowBack.IconCss}
                        className="raf_sm"
                        onClick={onClickBackBtn}
                      ></RAFButtonComponent>
                    ) : (
                      ""
                    )}
                    <div
                      className={`e-dlg-header${state.selectedIndex !== 0 ? " text-center" : ""
                        }`}
                    >
                      {state.selectedIndex === 0
                        ? "Add Shift Log"
                        : state.selectedFormLibrary.Title}
                    </div>
                    <div className="">
                      <RAFButtonComponent
                        displayMode="DialogCloseBtn"
                        onClick={() => onCloseDialogClick()}
                        className="raf_sm"
                      />
                    </div>
                  </div>
                </div>
              )}
              <div
                className={
                  "e-dlg-body-content taskUpdateForm position-relative p-0 mb-3"
                }
              >
                {state.selectedIndex === 0 && (
                  <div className="p-3">
                    <div className="row g-3 mb-3">
                      <div className="col-12">
                        <div className="row g-3">
                          <div className="col-md-12">
                            <div
                              className={` custom__card clickable custom__card__leftBorder primary`}
                              id={`card__${"other"}`}
                              onClick={() => {
                                if (props.onSelectRoutine) {
                                  props.onSelectRoutine("FromSupportPlan");
                                }
                              }}
                            >
                              <div className="custom__card__content darkText px-2">
                                <div className="d-flex">
                                  <RAFIconImage
                                    iconCssClass={
                                      "far fa-hand-holding-seedling"
                                    }
                                    moduleavatar="transparent-avatar avatar-text avatar-text-square"
                                    iconSize="30"
                                    fontSize="16"
                                    color="#000"
                                  />
                                  <div className="ps-2">
                                    <div className="subtitle_2 semi_bold">
                                      <span>Add from Support Plan</span>
                                    </div>
                                    <div className="body_2_light mt-2">
                                      <span>
                                        Select and add specific tasks and
                                        activities directly from the
                                        participant’s support plan.
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div
                              className={` custom__card clickable`}
                              id={`card__${"other"}`}
                              onClick={() => {
                                if (props.onSelectRoutine) {
                                  props.onSelectRoutine("GenericRoutines");
                                }
                              }}
                            >
                              <div className="custom__card__content darkText px-2">
                                <div className="d-flex">
                                  <RAFIconImage
                                    iconCssClass={
                                      "far fa-hand-holding-seedling"
                                    }
                                    moduleavatar="transparent-avatar avatar-text avatar-text-square"
                                    iconSize="30"
                                    fontSize="16"
                                    color="#000"
                                  />
                                  <div className="ps-2">
                                    <div className="subtitle_2 semi_bold">
                                      <span>
                                        Add General Tasks and Routines
                                      </span>
                                    </div>
                                    <div className="body_2_light mt-2">
                                      <span>
                                        Log common tasks and routines frequently
                                        performed for all clients, useful for
                                        activities not listed in the support
                                        plan.
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {isNotEmptyArray(recurringConfigItems) &&
                            recurringConfigItems.map((item) => {
                              return (
                                <div className="col-md-12" key={item.UID}>
                                  <div
                                    className={`${item.Entity === moduleName
                                      ? "custom__card__leftBorder primary "
                                      : ""
                                      }custom__card clickable`}
                                    id={`card__${item.UID}`}
                                    onClick={() =>
                                      onClickRecurringConfigItem(item)
                                    }
                                  >
                                    <div className="custom__card__content p-2 darkText">
                                      <div className="d-flex align-items-center">
                                        <RAFIconImage
                                          iconCssClass={
                                            "far fa-hand-holding-seedling"
                                          }
                                          moduleavatar="transparent-avatar avatar-text avatar-text-square"
                                          iconSize="30"
                                          fontSize="18"
                                          color="#000"
                                        />
                                        <div className="subtitle_2 semi_bold ps-2 ecllipseSecondLine">
                                          {" "}
                                          {item.EntityDisplayName}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          {false &&
                            isNotEmptyArray(filteredForms) &&
                            filteredForms.map((item) => {
                              return (
                                <div className="col-md-12" key={item.UID}>
                                  <div
                                    className={`${item.Entity === moduleName
                                      ? "custom__card__leftBorder primary "
                                      : ""
                                      }custom__card clickable`}
                                    id={`card__${item.UID}`}
                                    onClick={() => onClickFormItem(item)}
                                  >
                                    <div className="custom__card__content p-2 darkText">
                                      <div className="d-flex align-items-center">
                                        {isNotNullAndUndefined(
                                          item.IconName
                                        ) ? (
                                          <span
                                            className="transparent-avatar avatar-text avatar-text-square avatar-text"
                                            style={{
                                              width: "30px",
                                              height: "30px",
                                              minWidth: "30px",
                                              maxWidth: "30px",
                                              fontSize: "16px",
                                            }}
                                          >
                                            <IconPickerItem
                                              icon={item.IconName}
                                              size={18}
                                            />
                                          </span>
                                        ) : (
                                          <RAFIconImage
                                            iconCssClass={
                                              "far fa-hand-holding-seedling"
                                            }
                                            moduleavatar="transparent-avatar avatar-text avatar-text-square"
                                            iconSize="30"
                                            fontSize="18"
                                            color="#000"
                                          />
                                        )}
                                        <div className="subtitle_2 semi_bold ps-2">
                                          {" "}
                                          {item.Title}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          {isNotNullAndUndefined(groupedByCategoryType) &&
                            Object.keys(groupedByCategoryType).length > 0 &&
                            Object.keys(groupedByCategoryType).map(
                              (category) => {
                                let categoryHelpText = "";
                                if (category === "Observation Forms") {
                                  categoryHelpText =
                                    "Record observations such as food and fluid intake, health checks, and other daily logs.";
                                } else if (
                                  category === "Quality and Safety Forms"
                                ) {
                                  categoryHelpText =
                                    "Submit reports for incidents, complaints, and other quality and safety concerns.";
                                }
                                return (
                                  <div key={category} className="col-12">
                                    <CustomCardWidget removeContentPadding>
                                      <RAFCollapseWithPlusIcon
                                        toggleArrowIcon="Arrow"
                                        title={category}
                                        headerFixedHeight
                                        collapsePanelContentClassname="p-2 p-md-3"
                                        isCollapsed={false}
                                        layout={RAFLayout.OneColumnLayout}
                                      >
                                        <div className="row gx-0 gy-2">
                                          <div>
                                            <span>{categoryHelpText}</span>
                                          </div>
                                          {groupedByCategoryType[category].map(
                                            (item) => (
                                              <div
                                                className="col-md-12"
                                                key={item.UID}
                                              >
                                                <div
                                                  className={`${item.Entity === moduleName
                                                    ? "custom__card__leftBorder primary "
                                                    : ""
                                                    }custom__card clickable`}
                                                  id={`card__${item.UID}`}
                                                  onClick={() =>
                                                    onClickFormItem(item)
                                                  }
                                                >
                                                  <div className="custom__card__content p-2 darkText">
                                                    <div className="d-flex align-items-center">
                                                      {isNotNullAndUndefined(
                                                        item.IconName
                                                      ) ? (
                                                        <span
                                                          className="transparent-avatar avatar-text avatar-text-square avatar-text"
                                                          style={{
                                                            width: "30px",
                                                            height: "30px",
                                                            minWidth: "30px",
                                                            maxWidth: "30px",
                                                            fontSize: "16px",
                                                          }}
                                                        >
                                                          <IconPickerItem
                                                            icon={item.IconName}
                                                            size={18}
                                                          />
                                                        </span>
                                                      ) : (
                                                        <RAFIconImage
                                                          iconCssClass={
                                                            "far fa-hand-holding-seedling"
                                                          }
                                                          moduleavatar="transparent-avatar avatar-text avatar-text-square"
                                                          iconSize="30"
                                                          fontSize="18"
                                                          color="#000"
                                                        />
                                                      )}
                                                      <span className="body_2 color_inherit ps-2 ecllipseSecondLine">
                                                        {" "}
                                                        {item.Title}
                                                      </span>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            )
                                          )}
                                        </div>
                                      </RAFCollapseWithPlusIcon>
                                    </CustomCardWidget>
                                  </div>
                                );
                              }
                            )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {state.selectedIndex === 1 && (
                  <div className="p-3">
                    {isNotEmptyArray(state.allRecommendedMedications) &&
                      state.selectedFormEntityName === "medication_log" && (
                        <>
                          <div className="e-card mb-3">
                            <div className="e-card-content p-0">
                              <RAFCollapse
                                title={"Medications"}
                                headerRowClassName="with-height"
                                contentCardClassName="relatedListOuterDiv py-2 py-md-3"
                                // customButton={carePlanSectionCustomButton()}
                                removeContentCardPadding
                                showCustomButtonOnHover
                                IsCollapsed={false}
                              >
                                <div className="mx-2 mx-md-3">
                                  <div className="row gx-0 gy-2 gy-md-3">
                                    {state.allRecommendedMedications.map(
                                      (item) => {
                                        return (
                                          <div className="col-12" key={item.UID}>
                                            <MedicationContent
                                              key={item.UID}
                                              medicationRow={item}
                                              medicationEntity={
                                                state.medicationEntity
                                              }
                                              parent="CreateMedication"
                                              onSave={refresh}
                                              onSelect={() =>
                                                onClickMedicationItem(item)
                                              }
                                              hideFooterContent
                                              allowEdit={false}
                                            />
                                          </div>
                                        );
                                      }
                                    )}
                                  </div>
                                </div>
                              </RAFCollapse>
                            </div>
                          </div>
                          <div
                            className={`custom__card clickable`}
                            onClick={() => onClickOtherMedications()}
                          >
                            <div className="custom__card__content p-2 darkText">
                              <div className="d-flex align-items-center">
                                <RAFIconImage
                                  iconCssClass={"far fa-hand-holding-seedling"}
                                  moduleavatar="transparent-avatar avatar-text avatar-text-square"
                                  iconSize="30"
                                  fontSize="16"
                                />
                                <span className="secondary-header-bold-text-withoutColor ps-2 ecllipseSecondLine">
                                  {"Other Medications"}
                                </span>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                  </div>
                )}
                {state.selectedIndex === 2 && (
                  <div className="">
                    {/* <CustomCardWidget
                      title={`${IsNotNullOrWhiteSpace(state.selectedFormLibrary)
                          ? state.selectedFormLibrary.Title
                          : "Add"
                        }`}
                      cardClassName="surface_neutral_base"
                      cardHeaderClassName="subtitle_1 semi_bold"
                    > */}
                    <div>
                      <RAFEntityProvider
                        moduleName={state.selectedFormEntityName}
                      >
                        <RAFEntityContext.Consumer>
                          {({ entity, formLibrary }) => {
                            let hasCustomForm = false;
                            if (
                              isNotNullAndUndefined(formLibrary) &&
                              isNotNullAndUndefined(formLibrary.FormStructure)
                            ) {
                              hasCustomForm = true;
                            }
                            let formModel = new Model(
                              formLibrary?.FormStructure
                            );

                            //include the default values of all hidden fields in the survey data
                            formModel.storeOthersAsComment = false;

                            // Handles selected files
                            formModel.onUploadFiles.add((_, options) => {
                              // A variable that will store files until the survey is completed
                              //const tempFileStorage = {};

                              // Add files to the temporary storage
                              if (
                                tempFileStorage.current[options.name] !==
                                undefined
                              ) {
                                tempFileStorage.current[options.name] =
                                  tempFileStorage.current[options.name].concat(
                                    options.files
                                  );
                              } else {
                                tempFileStorage.current[options.name] =
                                  options.files;
                              }

                              // Load file previews
                              const content = [];

                              let filesRead = 0; // Counter for files read

                              options.files.forEach((file) => {
                                const fileReader = new FileReader();
                                fileReader.onload = () => {
                                  content.push({
                                    name: file.name,
                                    type: file.type,
                                    content: fileReader.result,
                                    file: file,
                                  });
                                  filesRead++; // Increment counter

                                  // Check if all files have been read
                                  if (filesRead === options.files.length) {
                                    // Return a file for preview as a { file, content } object
                                    options.callback(
                                      content.map((fileContent) => {
                                        return {
                                          file: fileContent.file,
                                          content: fileContent.content,
                                        };
                                      })
                                    );
                                  }
                                };
                                fileReader.readAsDataURL(file);
                              });
                            });

                            // Handles file removal
                            formModel.onClearFiles.add((_, options) => {
                              // Empty the temporary file storage if "Clear All" is clicked
                              if (options.fileName === null) {
                                tempFileStorage.current[options.name] = [];
                                options.callback("success");
                                return;
                              }

                              // Remove an individual file
                              const tempFiles =
                                tempFileStorage &&
                                tempFileStorage.current[options.name];
                              if (!!tempFiles) {
                                const fileInfoToRemove = tempFiles.filter(
                                  (file) => file.name === options.fileName
                                )[0];
                                if (fileInfoToRemove) {
                                  const index =
                                    tempFiles.indexOf(fileInfoToRemove);
                                  tempFiles.splice(index, 1);
                                }
                              }
                              //setTempFileStorage(tempFiles);
                              tempFileStorage.current[options.name] = tempFiles;
                              options.callback("success");
                            });

                            formModel.applyTheme(customTheme as ITheme);
                            let nextButton =
                              formModel.navigationBar.getActionById(
                                "sv-nav-next"
                              );
                            let prevButton =
                              formModel.navigationBar.getActionById(
                                "sv-nav-prev"
                              );
                            let completeButton =
                              formModel.navigationBar.getActionById(
                                "sv-nav-complete"
                              );
                            nextButton.visible = false;
                            prevButton.visible = false;
                            completeButton.visible = false;

                            formModel.onErrorCustomText.add(function (
                              sender,
                              options
                            ) {
                              if (options.name == "required") {
                                //options.text = options.obj.title + " is required";
                                options.text = "This field is required";
                              }
                            });

                            formModel.setValue(
                              "start_date",
                              shiftActivityFormValue.start_date
                            );
                            formModel.setValue(
                              "status",
                              shiftActivityFormValue.status
                            );

                            if (state.selectedFormEntityName === "incident") {
                              let isSuperAdminQueryString =
                                IsSuperAdminQueryString();
                              let isSuperAdmin = IsSuperAdmin();
                              if (isSuperAdmin || isSuperAdminQueryString) {
                                formModel.setValue(
                                  "role_permission_name",
                                  ESIOSuperAdministrator
                                );
                              }

                              formModel.setValue(
                                "reported_date_time",
                                shiftActivityFormValue.reported_date_time
                              );
                              formModel.setValue(
                                "care_recipient",
                                shiftActivityFormValue.care_recipient
                              );
                              formModel.setValue(
                                "assignee",
                                shiftActivityFormValue.assignee
                              );
                            }

                            if (
                              state.selectedFormEntityName === "medication_log"
                            ) {
                              formModel.setValue(
                                "title",
                                shiftActivityFormValue.title
                              );
                              formModel.setValue(
                                "description",
                                shiftActivityFormValue.instructions
                              );
                              formModel.setValue(
                                "unit_of_measurement",
                                shiftActivityFormValue.unit_of_measurement
                              );
                              formModel.setValue(
                                "dosage_count",
                                shiftActivityFormValue.dosage_count
                              );
                            }

                            return (
                              <RAFAttributeRelatedListProvider
                                moduleName={state.selectedFormEntityName}
                              >
                                <RAFAttributesContext.Consumer>
                                  {({ queryAttributes }) => {
                                    return (
                                      <div style={{ background: "#fff" }}>
                                        <RAFForm
                                          formRef={(g) => {
                                            enableSubmitButton(g);
                                            return (rafShiftActivityDetailsForm =
                                              g);
                                          }}
                                          initialValues={shiftActivityFormValue}
                                          layout={RAFLayout.OneColumnLayout}
                                          onSubmit={(values) =>
                                            onSubmitShiftActivity(
                                              values,
                                              formModel,
                                              hasCustomForm
                                            )
                                          }
                                        >
                                          <>
                                            {hasCustomForm ? (
                                              <>
                                                {isNotNullAndUndefined(
                                                  shiftActivityFormValue.instructions
                                                ) &&
                                                  state.selectedFormEntityName ===
                                                  "medication_log" &&
                                                  false && (
                                                    <div className="">
                                                      <div className="secondary-header-text-dark px-3 py-2">
                                                        <span>
                                                          {
                                                            shiftActivityFormValue.instructions
                                                          }
                                                        </span>
                                                      </div>
                                                    </div>
                                                  )}
                                                <Survey
                                                  model={formModel}

                                                // css={{
                                                //   root: "custom-container", // Apply custom class to outer container
                                                //   panel: {
                                                //     header: "custom-panel-header"
                                                //   }
                                                // }}
                                                />
                                                {/* <RAFCreate
                                                moduleName={
                                                  state.selectedFormEntityName
                                                }
                                                initialValues={null}
                                                isActive
                                              /> */}
                                              </>
                                            ) : (
                                              <>
                                                {isNotNullAndUndefined(
                                                  shiftActivityFormValue.Instructions
                                                ) &&
                                                  state.selectedFormEntityName ===
                                                  "medication_log" && (
                                                    <div className="row gx-2 gx-md-3 gy-1">
                                                      <div className="secondary-header-text-dark p-2 bg-grey">
                                                        <span>
                                                          {
                                                            shiftActivityFormValue.Instructions
                                                          }
                                                        </span>
                                                      </div>
                                                    </div>
                                                  )}
                                                <CreateContent
                                                  moduleName={
                                                    state.selectedFormEntityName
                                                  }
                                                  progressDivId={`#add_shift_activity_dlg_outerDiv`}
                                                />
                                                <div className="mt-4">
                                                  {getImageAttachments()}
                                                </div>
                                              </>
                                            )}
                                          </>
                                        </RAFForm>
                                      </div>
                                    );
                                  }}
                                </RAFAttributesContext.Consumer>
                              </RAFAttributeRelatedListProvider>
                            );
                          }}
                        </RAFEntityContext.Consumer>
                      </RAFEntityProvider>
                    </div>
                    {/* </CustomCardWidget> */}
                  </div>
                )}
              </div>
              {state.selectedIndex === 1 && (
                <div className="e-dlg-footerContent">
                  <div className="w-100">
                    <div className="row gx-2">
                      <div className="col-auto">
                        <div className="col-auto ms-auto">
                          <RAFButtonComponent
                            type="button"
                            action={RAFButtonConstant.Previous}
                            onClick={onClickBackBtn}
                            idString="next_Content"
                            className="e-outline"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {state.selectedIndex === 2 && (
                <div className="e-dlg-footerContent">
                  <div className="w-100">
                    <div className="row gx-2">
                      {/* <div className="col-auto">
                        <div className="col-auto ms-auto">
                          <RAFButtonComponent
                            type="button"
                            action={RAFButtonConstant.Previous}
                            onClick={onClickBackBtn}
                            idString="next_Content"
                            className="e-outline"
                          />
                        </div>
                      </div> */}
                      <div className="col-auto ms-auto">
                        <RAFPermissionRender
                          permissionName={
                            CareShiftLogPermissionConstants.CareShiftLogAdd
                          }
                        >
                          <RAFButtonComponent
                            type="button"
                            isPrimary
                            action={RAFButtonConstant.Save}
                            onClick={() =>
                              rafShiftActivityDetailsForm &&
                              rafShiftActivityDetailsForm.form.submit()
                            }
                            ref={submitButtonRef}
                            idString="CreateContent"
                            disabled={
                              rafShiftActivityDetailsForm &&
                              rafShiftActivityDetailsForm.submitting
                            }
                          />
                        </RAFPermissionRender>
                      </div>
                      <div className="col-auto">
                        <RAFButtonComponent
                          type="button"
                          action={RAFButtonConstant.Cancel}
                          onClick={props.onClose}
                          idString="CreateContent"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </>
          ) : (
            <div className="e-dlg-content-outer">
              <div className={headerClass}>
                <div className="d-flex align-items-center justify-content-between w-100">
                  <div
                    className={`e-dlg-header${state.selectedIndex !== 0 ? " text-center" : ""
                      }`}
                  >
                    {state.selectedIndex === 0
                      ? "Add Shift Log"
                      : state.selectedFormLibrary.Title}
                  </div>
                  <div className="">
                    <RAFButtonComponent
                      displayMode="DialogCloseBtn"
                      onClick={() => onCloseDialogClick()}
                      className="raf_sm"
                    />
                  </div>
                </div>
              </div>
              <div className="p-3 py-2 columnChooserPanel customScrollBar">
                <RAFEmptyState
                  title={`${hasPermissionToAddOrEditActivitylog.warningMessage}`}
                  displayStyle="TextOnly"
                />
              </div>
            </div>
          )}
        </>
      );
    } else {
      return (
        <div className="container-fluid px-0">
          <ACLoadingPanel loadingText="Preparing Data..." />
        </div>
      );
    }
  }
}

export default React.memo(AddShiftFormContent);
