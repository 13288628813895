import { DialogComponent } from "@syncfusion/ej2-react-popups";
import React, { PropsWithChildren, Reducer, forwardRef, useImperativeHandle, useReducer } from "react";
import { PreventFocusOnDialogOpen } from "../../../../RAFComponents/Dialog/SFDialogUtils";
import { showWarningToast } from "../../../../RAFComponents/Utility/RAFToastComponent";
import { getUTCDateValue, isNotNullAndUndefined } from "../../../../RAFComponents/helpers/utils";
import { FormLibraryRow } from "../../../../RAFComponents/models/Common/FormLibraryRow";
import { CareEsioEntity } from "../../../../constants/CareESIO/CareEsioConstants";
import { RAFShiftActionStatus } from "../../../../constants/Common/Constants";
import ManageShiftClockAction from "../../../ActiveContacts/ServiceTransaction/ServiceTransactionActions/ManageShiftClockAction";
import { ServiceTransactionRow } from "../../../ActiveContacts/ServiceTransaction/ServiceTransactionRow";
import { ShiftActivityTitle } from "../CareShiftLogHelper";
import ManageClientNote from "../ClientNote/ManageClientNote";
import UpdateCareShiftLog from "../UpdateCareShiftLog";

interface IProps {
    careRecipientUID: string;
    serviceTransactionRow: ServiceTransactionRow;
    allForms: FormLibraryRow[];
    onUpdateData: () => void;
}

interface IState {
    showUpdateClockContent: boolean;
    showUpdateCareShiftLogContent: boolean;
    showUpdateClientNoteContent: boolean;
    selectedShiftActivityRow: any;
    taskAction: "Clock In" | "Clock Out";
}


const CareShiftLogActivityDetailsDialog = forwardRef(
    function CareShiftLogActivityDetailsDialog({
        ...props
    }: PropsWithChildren<IProps>,
        ref
    ) {
        const moduleName = CareEsioEntity.CareShiftLog.EntityName;

        const [state, setState] = useReducer<Reducer<IState, Partial<IState>>>(
            (state, newState) => ({ ...state, ...newState }),
            {
                showUpdateClockContent: false,
                showUpdateCareShiftLogContent: false,
                showUpdateClientNoteContent: false,
                taskAction: null,
                selectedShiftActivityRow: null,
            }
        );

        useImperativeHandle(ref, () => ({
            editCareShiftLog(selectedShiftActivityRow: any) {
                if (isNotNullAndUndefined(selectedShiftActivityRow) && isNotNullAndUndefined(selectedShiftActivityRow.UID)) {
                    editCareShiftLog(selectedShiftActivityRow);
                } else {
                    setState({
                        showUpdateClockContent: false,
                        showUpdateCareShiftLogContent: false,
                        showUpdateClientNoteContent: false,
                        taskAction: null,
                        selectedShiftActivityRow: null,
                    });
                }
            }
        }));

        //taskClockAction start
        const showShiftClockContent = async (selectedShiftActivityRow) => {
            const taskAction =
                selectedShiftActivityRow.title === ShiftActivityTitle.StartedShift
                    ? "Clock In"
                    : "Clock Out";
            setState({ showUpdateClockContent: true, taskAction, selectedShiftActivityRow });
        };

        const shiftClockContent = () => {
            if (state.showUpdateClockContent) {
                const { serviceTransactionRow } = props;
                const { taskAction, selectedShiftActivityRow } = state;
                const minTimeValue = getUTCDateValue(
                    serviceTransactionRow && serviceTransactionRow.StartDate
                );

                const maxTimeValue = getUTCDateValue(
                    serviceTransactionRow && serviceTransactionRow.EndDate
                );

                const taskDateValue = selectedShiftActivityRow.start_date;

                return (
                    <ManageShiftClockAction
                        shiftActivityObjectUID={selectedShiftActivityRow.UID}
                        selectedServiceTransactionRow={serviceTransactionRow && serviceTransactionRow}
                        taskAction={taskAction}
                        taskDateValue={taskDateValue}
                        onSave={refreshOnUpdate.bind(this)}
                        onClose={onDialogClose.bind(this)}
                        minTimeValue={minTimeValue}
                        maxTimeValue={maxTimeValue}
                        isActive
                    />
                );
            } else {
                return <div></div>;
            }
        };

        //taskClockAction end


        //update ClientNote_start
        const showUpdateClientNoteContent = (selectedShiftActivityRow) => {
            setState({ showUpdateClientNoteContent: true, selectedShiftActivityRow });
        };

        const updateClientNoteContent = () => {
            if (isNotNullAndUndefined(state.showUpdateClientNoteContent)) {
                const { selectedShiftActivityRow } = state;
                return (
                    <ManageClientNote
                        objectUID={selectedShiftActivityRow.UID}
                        onSave={refreshOnUpdate.bind(this)}
                        onClose={onDialogClose.bind(this)}
                        onDelete={refreshOnUpdate.bind(this)}
                        careRecipientUID={props.careRecipientUID}
                        isActive
                        {...props}
                    />
                );
            } else {
                return <div></div>;
            }
        };

        //update ClientNote_end

        //update CareShiftLog_start
        const editCareShiftLog = (selectedShiftActivityRow: any) => {
            const { serviceTransactionRow } = props;
            const status = isNotNullAndUndefined(serviceTransactionRow)
                ? serviceTransactionRow.Status
                : null;

            if (
                status === RAFShiftActionStatus.Unassigned ||
                status === RAFShiftActionStatus.Scheduled ||
                status === RAFShiftActionStatus.OfferSent ||
                status === RAFShiftActionStatus.Accepted
            ) {
                showWarningToast(
                    'Start the shift to edit shift log.'
                    //"The task cannot be completed until the shift has started."
                );
                return;
            }

            if (
                isNotNullAndUndefined(selectedShiftActivityRow) &&
                (selectedShiftActivityRow.title === ShiftActivityTitle.StartedShift ||
                    selectedShiftActivityRow.title === ShiftActivityTitle.CompletedShift)
            ) {
                showShiftClockContent(selectedShiftActivityRow);
            } else if (
                isNotNullAndUndefined(selectedShiftActivityRow) &&
                selectedShiftActivityRow.log_type === CareEsioEntity.ClientNote.EntityName
            ) {
                showUpdateClientNoteContent(selectedShiftActivityRow);
            } else {
                setState({ showUpdateCareShiftLogContent: true, selectedShiftActivityRow });
            }
        };

        function updateCareShiftLogContent() {
            if (state.showUpdateCareShiftLogContent === true) {
                const { serviceTransactionRow } = props;
                const { selectedShiftActivityRow } = state;
                return (
                    <UpdateCareShiftLog
                        objectUID={selectedShiftActivityRow.UID}
                        onSave={refreshOnUpdate.bind(this)}
                        onClose={onDialogClose.bind(this)}
                        onDelete={refreshOnUpdate.bind(this)}
                        selectedServiceTransactionRow={serviceTransactionRow}
                        allForms={props.allForms}
                        isActive
                    />
                );
            } else {
                return <div></div>;
            }
        }

        const refreshOnUpdate = () => {
            setState({
                showUpdateClockContent: false,
                showUpdateCareShiftLogContent: false,
                showUpdateClientNoteContent: false,
                taskAction: null,
                selectedShiftActivityRow: null,
            });
            if (props.onUpdateData) {
                props.onUpdateData();
            }
        };

        const onDialogClose = () => {
            setState({
                showUpdateClockContent: false,
                showUpdateCareShiftLogContent: false,
                showUpdateClientNoteContent: false,
                taskAction: null,
                selectedShiftActivityRow: null,
            });
        };
        //update CareShiftLog_end

        if (isNotNullAndUndefined(state.selectedShiftActivityRow)) {
            return (
                < >
                    {state.showUpdateCareShiftLogContent && (
                        <DialogComponent
                            //header={`Update ${CareEsioEntity.CareShiftLog.DisplayName}`}
                            //showCloseIcon
                            visible={state.showUpdateCareShiftLogContent}
                            cssClass="rightDialog createEditForm full-height form-center-dialog dlg-new-style"
                            id={`dlg_update_${moduleName}`}
                            content={updateCareShiftLogContent.bind(this)}
                            isModal
                            target="body"
                            closeOnEscape={false}
                            close={onDialogClose.bind(this)}
                            open={PreventFocusOnDialogOpen}
                            zIndex={1200}
                        ></DialogComponent>
                    )}
                    {state.showUpdateClockContent && (
                        <DialogComponent
                            header={state.taskAction}
                            showCloseIcon
                            visible={state.showUpdateClockContent}
                            cssClass={
                                state.taskAction === "Clock Out"
                                    ? "rightDialog createEditForm full-height dlg-new-style"
                                    : "rightDialog createEditForm full-height dlg-new-style"
                                // : "centerDialog-sm createEditForm form-center-dialog dlg-new-style fixed-header"
                            }
                            id="mangeShiftClock_dialog"
                            content={shiftClockContent.bind(this)}
                            isModal
                            target="body"
                            closeOnEscape={false}
                            close={onDialogClose.bind(this)}
                            open={PreventFocusOnDialogOpen}
                            zIndex={1200}
                        ></DialogComponent>
                    )}
                    {state.showUpdateClientNoteContent && (
                        <DialogComponent
                            // header={"Update Note"}
                            // showCloseIcon={false}
                            visible={state.showUpdateClientNoteContent}
                            cssClass={"rightDialog createEditForm full-height dlg-new-style"}
                            id="manageupdate_clientnote_dialog"
                            content={updateClientNoteContent.bind(this)}
                            isModal
                            target="body"
                            closeOnEscape={false}
                            close={onDialogClose.bind(this)}
                            open={PreventFocusOnDialogOpen}
                            zIndex={1200}
                        ></DialogComponent>
                    )}
                </>
            );
        } else {
            return (
                < ></>
            );
        }
    });

export default React.memo(CareShiftLogActivityDetailsDialog);