import { DialogComponent } from "@syncfusion/ej2-react-popups";
import DOMPurify from "dompurify";
import React, {
  Fragment,
  PropsWithChildren,
  Reducer,
  useContext,
  useEffect,
  useReducer,
} from "react";
import { RolePermissionsContext } from "../../../../RAFAuthentication/RAFRolePermissionsContextProvider";
import { PreventFocusOnDialogOpen } from "../../../../RAFComponents/Dialog/SFDialogUtils";
import CustomCardWidget from "../../../../RAFComponents/Navigation/CustomCardWidget";
import RAFButtonComponent from "../../../../RAFComponents/Navigation/RAFButtonComponent";
import RAFEmptyState from "../../../../RAFComponents/Navigation/RAFEmptyState";
import RAFRecordInfo from "../../../../RAFComponents/Navigation/RAFRecordInfo";
import {
  RAFCustomFilter,
  RAFCustomOperator,
} from "../../../../RAFComponents/RAFViewPanels/RAFFilterColumn/RAFCustomFilter";
import RAFPermissionRender, {
  hasPermission,
} from "../../../../RAFComponents/helpers/PermissionHelper";
import {
  RAFEventName,
  subscribeRAFEvent,
  triggerRAFEvent,
  unsubscribeRAFEvent,
} from "../../../../RAFComponents/helpers/RAFDocumentEvents";
import {
  IsNotNullOrWhiteSpace,
  isNotEmptyArray,
  isNotNullAndUndefined,
  propertyOf,
} from "../../../../RAFComponents/helpers/utils";
import ACLoadingPanel from "../../../../components/shared/ACLoadingPanel";
import { CareRecipientPermissionConstants } from "../../../../constants/CareESIO/CareESIOPermissionConstant";
import { CareEsioEntity } from "../../../../constants/CareESIO/CareEsioConstants";
import {
  BrowserIsDevice,
  MomentFormats,
  RAFButtonConstant,
} from "../../../../constants/Common/Constants";
import { RAFEntityName } from "../../../../constants/Common/EntityConstants";
import { getAllActivityItems } from "../../../ActiveContacts/Activity/ActivityHelper";
import {
  ActivityRow,
  RAFActivityCommentType,
} from "../../../ActiveContacts/Activity/ActivityRow";
import ManageClientNote from "./ManageClientNote";
import "./ClientNoteStyle.scss";
import RAFCheckBoxList from "../../../../RAFComponents/Inputs/RAFCheckBoxList";
import RAFCheckBox from "../../../../RAFComponents/Inputs/RAFCheckBox";
import { SwitchComponent } from "@syncfusion/ej2-react-buttons";
import UserProfilePhoto from "../../../ActiveContacts/User/UserProfilePhoto";
import { getCustomDateFormat } from "@syncfusion/ej2-react-grids";
import { getFormatedDate } from "../../../../RAFComponents/Inputs/RFFUtils";
import ClientNoteDetails from "./ClientNoteDetails";

interface IProps {
  take?: number;
  relatedToType?: string;
  careRecipientUID?: string;
  careRecipientName?: string;

  secondaryRelatedTo?: string;
  secondaryRelatedToUID?: string;
  secondaryRelatedToType?: string;

  shiftUID?: string;

  mode?: "PinnedNotesOnly" | "PinnedNotesOrShiftNotes" | "UnPinnedNotesOnly";
  viewMode?: "Horizontal" | "Vertical";
  allowAdd?: boolean;
  showEmptyState?: boolean;
  uiMode?: "NormalCard" | "CardWithLeftBorder";
  headerMode?: "Avatar" | "Icon";

  isRelatedSection?: boolean;
  modifiedDateRangeFilter?: RAFCustomFilter[];
  displayStyle?: "ImageOnly" | "TextOnly" | "Default";
  paddingClassname?: string;

  showHeaderTitle?: boolean;
  btnPosition?: "Top" | "Bottom";
}

interface IState {
  isLoading: boolean;
  activityItems: ActivityRow[];
  showManageClientNoteContent: boolean;
  showClientNoteDetailsContent: boolean;
  selectedObjectUID: string;
}

function ClientNoteList({
  careRecipientUID,
  shiftUID,
  careRecipientName,
  uiMode,
  headerMode = "Avatar",
  allowAdd = true,
  showEmptyState = false,
  isRelatedSection = false,
  paddingClassname = "p-2 p-md-3",
  btnPosition = "Bottom",
  ...props
}: PropsWithChildren<IProps>) {
  const [state, setState] = useReducer<Reducer<IState, Partial<IState>>>(
    (state, newState) => ({ ...state, ...newState }),
    {
      isLoading: true,
      activityItems: null,
      showManageClientNoteContent: false,
      showClientNoteDetailsContent: false,
      selectedObjectUID: null,
    }
  );

  const rolePermissionsContext = useContext(RolePermissionsContext);
  const permissionValue = isNotNullAndUndefined(rolePermissionsContext)
    ? rolePermissionsContext.permissionValue
    : null;

  useEffect(() => {
    refresh();
  }, [careRecipientUID, props.modifiedDateRangeFilter]);

  useEffect(() => {
    if (props.mode === "PinnedNotesOnly") {
      const eventKey = `${RAFEventName.ReloadPinnedActivityContent}_${RAFEntityName.Activity}_${props.mode}_${careRecipientUID}`;
      subscribeRAFEvent(eventKey, refreshActivityList);

      return () => {
        unsubscribeRAFEvent(eventKey, refreshActivityList);
      };
    }
  });

  const refreshActivityList = (args) => {
    if (isNotNullAndUndefined(args) && isNotNullAndUndefined(args.detail)) {
      let argscareRecipientUID = args.detail.careRecipientUID;
      if (argscareRecipientUID === careRecipientUID) {
        refresh();
      }
    }
  };

  const refresh = async () => {
    setState({
      isLoading: true,
      showManageClientNoteContent: false,
      showClientNoteDetailsContent: false,
    });
    if (isNotNullAndUndefined(careRecipientUID)) {
      const sortQuery: string[] = [
        propertyOf<ActivityRow>("Pinned"),
        propertyOf<ActivityRow>("DisplayOrder"),
        `${propertyOf<ActivityRow>("ModifiedDate")} desc`,
      ];

      let customFilter: RAFCustomFilter = {};
      customFilter.Condition = "and";
      customFilter.Rules = [];

      if (props.mode === "PinnedNotesOnly") {
        let filter: RAFCustomFilter = {};
        let filterVal: string[] = [];
        filterVal.push("true");
        filter.Operator = RAFCustomOperator.Equal;
        filter.Value = filterVal;
        filter.Field = propertyOf<ActivityRow>("Pinned");
        customFilter.Rules.push(filter);
      } else if (props.mode === "PinnedNotesOrShiftNotes") {
        let customFilter1: RAFCustomFilter = {};
        customFilter1.Condition = "or";
        customFilter1.Rules = [];

        let shiftfilter1: RAFCustomFilter = {};
        let shiftVal1: string[] = [];
        shiftVal1.push(shiftUID);
        shiftfilter1.Operator = RAFCustomOperator.Equal;
        shiftfilter1.Value = shiftVal1;
        shiftfilter1.Field = propertyOf<ActivityRow>("SecondaryRelatedToUID");
        customFilter1.Rules.push(shiftfilter1);

        let filter1: RAFCustomFilter = {};
        let filterVal1: string[] = [];
        filterVal1.push("true");
        filter1.Operator = RAFCustomOperator.Equal;
        filter1.Value = filterVal1;
        filter1.Field = propertyOf<ActivityRow>("Pinned");
        customFilter1.Rules.push(filter1);

        customFilter.Rules.push(customFilter1);
      } else if (props.mode === "UnPinnedNotesOnly") {
        let filter: RAFCustomFilter = {};
        let filterVal: string[] = [];
        filterVal.push("false");
        filter.Operator = RAFCustomOperator.Equal;
        filter.Value = filterVal;
        filter.Field = propertyOf<ActivityRow>("Pinned");
        customFilter.Rules.push(filter);
      }

      let nullParentUIDFilter: RAFCustomFilter = {};
      nullParentUIDFilter.Operator = RAFCustomOperator.IsNull;
      nullParentUIDFilter.Field = propertyOf<ActivityRow>("ParentUID");
      customFilter.Rules.push(nullParentUIDFilter);

      let commentTypeFiler: RAFCustomFilter = {};
      let commentTypeFilterValue: string[] = [];
      commentTypeFilterValue.push(RAFActivityCommentType.Note);
      commentTypeFiler.Operator = RAFCustomOperator.Equal;
      commentTypeFiler.Value = commentTypeFilterValue;
      commentTypeFiler.Field = propertyOf<ActivityRow>("CommentType");
      customFilter.Rules.push(commentTypeFiler);

      if (isNotEmptyArray(props.modifiedDateRangeFilter)) {
        props.modifiedDateRangeFilter.forEach((filter) => {
          customFilter.Rules.push(filter);
        });
      }

      const activityItems = await getAllActivityItems(
        null,
        careRecipientUID,
        null,
        null,
        customFilter,
        sortQuery,
        isNotNullAndUndefined(props.take)
          ? props.take
          : isNotEmptyArray(props.modifiedDateRangeFilter)
            ? null
            : 20,
        0
      );

      setState({
        isLoading: false,
        activityItems,
      });
    } else {
      setState({
        isLoading: false,
        activityItems: [],
      });
    }
  };

  const refreshOnUpdate = async (isPinnedItem: boolean) => {
    //if (props.mode !== "PinnedNotesOnly" && isPinnedItem) {
    if (props.mode !== "PinnedNotesOnly") {
      const eventKey = `${RAFEventName.ReloadPinnedActivityContent}_${RAFEntityName.Activity}_PinnedNotesOnly_${careRecipientUID}`;
      triggerRAFEvent(eventKey, {
        careRecipientUID: `${careRecipientUID}`,
      });
    }
    refresh();
  };

  //manage ClientNote_start
  const showManageClientNoteContent = (selectedObjectUID?) => {
    if(isNotNullAndUndefined(selectedObjectUID)){
    setState({ showManageClientNoteContent: true, selectedObjectUID });
    }
    else {
    setState({ showManageClientNoteContent: true });
    }
  };

  const manageClientNoteContent = () => {
    if (isNotNullAndUndefined(state.showManageClientNoteContent)) {
      let initialValues: ActivityRow = new ActivityRow();

      if (
        isNotNullAndUndefined(careRecipientUID) &&
        isNotNullAndUndefined(careRecipientName)
      ) {
        initialValues.RelatedToUID = careRecipientUID;
        initialValues.RelatedTo = careRecipientName;
        initialValues.RelatedToType = props.relatedToType;
        //const { formLibrary } = state;
        //initialValues["FormLibrary"] = formLibrary.Title;
        //initialValues["FormLibraryUID"] = formLibrary.UID;
        //initialValues["Status"] = "Done";
      }

      if (
        isNotNullAndUndefined(props.secondaryRelatedToUID) &&
        isNotNullAndUndefined(props.secondaryRelatedTo)
      ) {
        initialValues.SecondaryRelatedTo = props.secondaryRelatedTo;
        initialValues.SecondaryRelatedToUID = props.secondaryRelatedToUID;
        initialValues.SecondaryRelatedToType = props.secondaryRelatedToType;
      }

      return (
        <ManageClientNote
          objectUID={state.selectedObjectUID}
          initialValues={state.selectedObjectUID ? null : initialValues}
          onSave={refreshOnUpdate.bind(this)}
          onClose={closeManageClientNoteDialog.bind(this)}
          onDelete={refreshOnUpdate.bind(this)}
          careRecipientUID={careRecipientUID}
          activityCommentType={RAFActivityCommentType.Note}
          isActive
          {...props}
        />
      );
    } else {
      return <div></div>;
    }
  };

  const closeManageClientNoteDialog = async () => {
    setState({ showManageClientNoteContent: false, selectedObjectUID: null });
  };

  //manage ClientNote_end

  //details ClientNote_start
  const showClientNoteDetailsContent = (selectedObjectUID) => {
    setState({ showClientNoteDetailsContent: true, selectedObjectUID });
  };

  const detailsClientNoteContent = () => {
    if (isNotNullAndUndefined(state.showClientNoteDetailsContent)) {
      let initialValues: ActivityRow = new ActivityRow();

      if (
        isNotNullAndUndefined(careRecipientUID) &&
        isNotNullAndUndefined(careRecipientName)
      ) {
        initialValues.RelatedToUID = careRecipientUID;
        initialValues.RelatedTo = careRecipientName;
        initialValues.RelatedToType = props.relatedToType;
        //const { formLibrary } = state;
        //initialValues["FormLibrary"] = formLibrary.Title;
        //initialValues["FormLibraryUID"] = formLibrary.UID;
        //initialValues["Status"] = "Done";
      }

      if (
        isNotNullAndUndefined(props.secondaryRelatedToUID) &&
        isNotNullAndUndefined(props.secondaryRelatedTo)
      ) {
        initialValues.SecondaryRelatedTo = props.secondaryRelatedTo;
        initialValues.SecondaryRelatedToUID = props.secondaryRelatedToUID;
        initialValues.SecondaryRelatedToType = props.secondaryRelatedToType;
      }

      return (
        <ClientNoteDetails
          objectUID={state.selectedObjectUID}
          initialValues={state.selectedObjectUID ? null : initialValues}
          onSave={refreshOnUpdate.bind(this)}
          onClose={closeDetailsClientNoteDialog.bind(this)}
          onDelete={refreshOnUpdate.bind(this)}
          careRecipientUID={careRecipientUID}
          activityCommentType={RAFActivityCommentType.Note}
          onEdit={(selectedObjectUID) => showManageClientNoteContent(selectedObjectUID)}
          isActive
          {...props}
        />
      );
    } else {
      return <div></div>;
    }
  };

  const closeDetailsClientNoteDialog = async () => {
    setState({ showClientNoteDetailsContent: false, selectedObjectUID: null });
  };

  //details ClientNote_end

  //create client note start
  const onAddNoteClicked = () => {
    setState({ showManageClientNoteContent: true, selectedObjectUID: null });
  };

  // useImperativeHandle(ref, () => ({
  //   onAddNoteClicked() {
  //     onAddNoteClicked();
  //   },
  // }));

  //create client note end

  const headerTemplate = (item) => {
    return (
      <div>
        <div className="d-flex align-items-center">
          <UserProfilePhoto
            uid={
              isNotNullAndUndefined(item) &&
                IsNotNullOrWhiteSpace(item.CreatedByUID)
                ? item.CreatedByUID
                : ""
            }
            relatedToType={RAFEntityName.User}
            canEdit={false}
            profileImgCss={"profileImg-container-xsm"}
            title={item.CreatedBy}
            emptyAvatarType="Initial"
            iconSize='24'
            fontSize='10'
          />
          <span className="subtitle_3 ps-2 ecllipseFirstLine word-break-all">
            {item.CreatedBy}
          </span>
        </div>
        <div className="mt-2">
          <span className="subtitle_1 semi_bold ecllipseFirstLine">
            {item.Subject}
          </span>
        </div>
        <div className="mt-1 d-flex">
          <div className="body_3_light">
            Expired:
          </div>
          <div className="body_3 semi_bold">
            {getFormatedDate(item.ExpiredDate, MomentFormats.DATETIME)}
          </div>
        </div>
      </div>
    );
  };

  const getFooterContent = (showAddBtn: boolean) => {
    // if (showAddBtn && BrowserIsDevice && isRelatedSection === false) {
    //   return (
    //     <RAFPermissionRender
    //       permissionName={ClientNotePermissionConstants.ClientNoteAdd}
    //     >
    //       <div>
    //         <FabComponent
    //           id={`btn_${RAFButtonConstant.Add.Id}_${CareEsioEntity.ClientNote.EntityName}`}
    //           iconCss={RAFButtonConstant.Add.IconCss}
    //           content={RAFButtonConstant.Add.DisplayName}
    //           onClick={onAddNoteClicked}
    //         // target={`#care_${CareEsioEntity.CareShiftLog.EntityName}_div`}
    //         ></FabComponent>
    //       </div>
    //     </RAFPermissionRender>
    //   );
    // } else
    if (showAddBtn) {
      return (
        <div className={"col-auto"}>
          <RAFButtonComponent
            action={RAFButtonConstant.Add}
            btnContent="Add Note"
            onClick={onAddNoteClicked}
            className="btn_brand_primary semi_dark"
          ></RAFButtonComponent>
        </div>
      );
    }
  };

  const getHeaderTitle = () => {
    const { activityItems } = state;
    const pinnedNotesLength = isNotEmptyArray(activityItems)
      ? activityItems.filter((x) => x.Pinned).length
      : 0;
    const totalNotesLength = isNotEmptyArray(activityItems)
      ? activityItems.length
      : 0;

    return (
      <div>
        <div className="row gx-2">
          <div className="col">
            <div className="row gx-2 gy-1">
              <div className="col-12">
                <span className="header_5">{`Notes`}</span>
              </div>
              <div className="col-auto">
                <div className="body_2">
                  <span>Pinned notes:</span>
                  <span className="content_neutral_dark medium">
                    {" "}
                    {pinnedNotesLength}
                  </span>
                </div>
              </div>
              <div className="col-auto">
                <div className="group_btn_separator"></div>
              </div>
              <div className="col-auto">
                <div className="body_2">
                  <span>Total notes:</span>
                  <span className="content_neutral_dark medium">
                    {" "}
                    {totalNotesLength}
                  </span>
                </div>
              </div>
            </div>
          </div>
          {getFooterContent(
            hasPermission(
              permissionValue,
              CareRecipientPermissionConstants.CareRecipientManageMyNotes
            )
              ? true
              : false
          )}
        </div>
      </div>
    );
  };

  return (
    <div className="row g-0 gy-3">
      <div className="col-12">
        {getHeaderTitle()}
      </div>
      <div className="col-12">
        {state.isLoading === false ? (
          <RAFPermissionRender
            permissionName={
              CareRecipientPermissionConstants.CareRecipientReadNotes
            }
          >
            {isNotEmptyArray(state.activityItems) &&
              state.activityItems.length > 0
              ?
              <div className="row g-3">
                {state.activityItems.map((item) => {
                  const colorCodeName = isNotNullAndUndefined(
                    item.ColourCode
                  )
                    ? item.ColourCode
                    : "#67C6C2";
                  return (
                    <div className="col-12 col-md-6 col-lg-12 col-xl-6 col-xxl-4">
                      <CustomCardWidget
                        cardClassName="note_card"
                        onClick={() => showClientNoteDetailsContent(item.UID)}

                      >
                        <div className="row gx-2">
                          <div className="col-auto">
                            <div className="note_card_color_badge"
                              style={{ backgroundColor: colorCodeName }}
                            ></div>
                          </div>
                          <div className="col">
                            <div className="row g-0 justify-content-end align-items-center">
                              <div className="col-auto d-flex align-items-center">
                                <div className="body_3_light pe-1">Feed</div>
                                <SwitchComponent
                                  name={"feeds"}
                                  checked={item.PostFeed ?? false}
                                  disabled
                                />
                              </div>
                              <div className="col-auto">
                                <div className="group_btn_separator"></div>
                              </div>
                              <div className="col-auto">
                                <span className="note_pin_outer_div">
                                  <i className={`note_pin_icon fas fa-thumbtack${item.Pinned ? ' text_orange' : ''}`}></i>
                                  {/* <i className="note_pin_icon fas fa-thumbtack" style={{ color: item.ColourCode }}></i> */}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="mt-2-5">
                          {headerTemplate(item)}
                        </div>
                        <div className="mt-2-5">
                          <span
                            dangerouslySetInnerHTML={{
                              __html:
                                item !== null
                                  ? DOMPurify.sanitize(item.Message)
                                  : undefined,
                            }}
                            className="remove_paragraph_margin body_2_dark description-text note_card_content"
                          ></span>
                        </div>
                      </CustomCardWidget>
                    </div>
                  )
                })}
              </div>
              :
              <RAFEmptyState
                title={"You do not have any notes."}
                body={`To get started, please add a new ${CareEsioEntity.ClientNote.DisplayName} by clicking on the "Add" button.`}
              />
            }
            <div>
            {state.showManageClientNoteContent && (
              <DialogComponent
                //  header={isNotNullAndUndefined(state.selectedObjectUID) ? "Update Note" : 'Add Note'}
                //showCloseIcon={false}
                visible={state.showManageClientNoteContent}
                cssClass={
                  "rightDialog createEditForm full-height dlg-new-style"
                }
                id="manageupdate_clientnote_dialog"
                content={manageClientNoteContent.bind(this)}
                isModal
                target="body"
                closeOnEscape={false}
                close={closeManageClientNoteDialog.bind(this)}
                open={PreventFocusOnDialogOpen}
                zIndex={1200}
              />
            )}
            </div>
            <div>
            {state.showClientNoteDetailsContent && (
              <DialogComponent
                //  header={isNotNullAndUndefined(state.selectedObjectUID) ? "Update Note" : 'Add Note'}
                //showCloseIcon={false}
                visible={state.showClientNoteDetailsContent}
                cssClass={
                  "centerDialog-md createEditForm full-height dlg-new-style"
                }
                id="manageupdate_clientnote_dialog"
                content={detailsClientNoteContent.bind(this)}
                isModal
                target="body"
                closeOnEscape={false}
                close={closeDetailsClientNoteDialog.bind(this)}
                open={PreventFocusOnDialogOpen}
                zIndex={1200}
              />
            )}
            </div>
          </RAFPermissionRender>)
          :
          <ACLoadingPanel loadingText="Loading..." />
        }
      </div>
    </div>
  )
}

export default React.memo(ClientNoteList);
