import { DialogUtility } from "@syncfusion/ej2-popups";
import React, { PropsWithChildren, Reducer, useReducer } from "react";
import RAFTabGroupBtn from "../../../RAFComponents/Navigation/RAFTabGroupBtn";
import { hideProgress, showProgress } from "../../../RAFComponents/helpers/AppHelper";
import { isNotEmptyArray, isNotNullAndUndefined } from "../../../RAFComponents/helpers/utils";
import { RAFActionStatus } from "../../../constants/Common/Constants";
import { ServiceTransactionRow } from "../../ActiveContacts/ServiceTransaction/ServiceTransactionRow";
import { getAllRelatedTasks } from "../../ActiveContacts/Task/TaskHelper";
import { TenantSettingsRow } from "../../Common/TenantSettings/TenantSettingsRow";
import CareShiftLogCalendar from "./CareShiftLogCalendar/CareShiftLogCalendar";
import { getShiftActivitiesByTaskId } from "./CareShiftLogHelper";
import CareShiftLogList from "./CareShiftLogList";

const activitiesTab: {
    title: string;
    id: string;
}[] = [{
    title: "Hourly view",
    id: 'DayView'
},
{
    title: "Timeline",
    id: 'Timeline'
}];


interface IProps {
    careRecipientName: string;
    careRecipientUID: string;

    taskRow: ServiceTransactionRow;
    shiftType: "Default" | "Billing" | "Payroll";
    selectedEntity: string;


    hideCareShiftLogList: boolean;
    showShiftLogContent: boolean;
    isSupportLog: boolean;
    manageClockActionClick: (taskAction: "Clock In" | "Clock Out") => void;
    onSave: () => void;
    tenantSettings: TenantSettingsRow;
}

interface IState {
    selectedTabValue: any;
}

function CareShiftLogTabContent({
    tenantSettings,
    taskRow,
    ...props
}: PropsWithChildren<IProps>) {

    const enableActivityHourlyView = isNotNullAndUndefined(tenantSettings) ? tenantSettings.EnableActivityHourlyView === "Yes" ? true : false : false;

    const [state, setState] = useReducer<Reducer<IState, Partial<IState>>>(
        (state, newState) => ({ ...state, ...newState }),
        {
            selectedTabValue: activitiesTab[1].id,
            // selectedTabValue: enableActivityHourlyView ? activitiesTab[0].id : activitiesTab[1].id,
        }
    );

    let alertUtilityDialog: any;

    const taskStatus = isNotNullAndUndefined(taskRow)
        ? props.shiftType === "Billing"
            ? taskRow.BillingStatus
            : taskRow.Status
        : null;


    //action start
    const onClickClockActions = async (action: "Clock In" | "Clock Out") => {
        if (action === "Clock Out") {
            let progressDiv = showProgress('body');
            const [childShiftActivityItems, childTaskItems] = await Promise.all([
                getAllRelatedTasks(
                    taskRow.RelatedToUID,
                    taskRow.UID
                ),
                getShiftActivitiesByTaskId(
                    taskRow.UID
                )
            ]);
            hideProgress(progressDiv);
            const plannedShiftActivity = isNotEmptyArray(childShiftActivityItems)
                ? childShiftActivityItems.filter(
                    (item: any) => item.Status == RAFActionStatus.Planned
                )
                : [];

            const plannedTaskItems =
                isNotEmptyArray(childTaskItems) &&
                    isNotNullAndUndefined(taskRow.RelatedToUID) &&
                    isNotNullAndUndefined(taskRow.UID)
                    ? childTaskItems.filter(
                        (item: any) => item.TaskStatus == RAFActionStatus.Planned
                    )
                    : [];

            if (isNotEmptyArray(plannedShiftActivity)) {
                showAlertContent(
                    "Ensure completion of one or more scheduled support activities before clocking out of this shift."
                );
                return;
            } else if (isNotEmptyArray(plannedTaskItems)) {
                showAlertContent(
                    "Ensure completion of one or more scheduled tasks or appointments before clocking out of this shift."
                );
                return;
            } else {
                if (props.manageClockActionClick) {
                    props.manageClockActionClick(action);
                }
            }
        } else {
            if (props.manageClockActionClick) {
                props.manageClockActionClick(action);
            }
        }
    };

    const showAlertContent = (content: string) => {
        alertUtilityDialog = DialogUtility.alert({
            animationSettings: { effect: "Fade" },
            content: content,
            okButton: { text: "OK", click: alertOkButtonClick.bind(this) },
            title: "Alert",
            position: { X: "center", Y: "center" },
            cssClass: "raf-delete_alert_dialog alert-dialog",
            closeOnEscape: true,
            showCloseIcon: false,
        });
    };

    const alertOkButtonClick = () => {
        alertUtilityDialog.hide();
    };


    //action end

    const renderTabContent = () => {
        const { selectedTabValue } = state;
        if (selectedTabValue === "DayView") {
            return activitiesCalendarContent();
        } else if (selectedTabValue === "Timeline") {
            return activitiesTabContent();
        } else {
            return <></>;
        }
    };

    const activitiesTabContent = () => {
        return (
            <div className="pt-3">
                {props.hideCareShiftLogList !== true && props.showShiftLogContent && (
                    <div className="col-12">{activitiesContent()}</div>
                )}
            </div>
        );
    };
    const activitiesCalendarContent = () => {
        return (
            <div className="pt-3">
                {props.hideCareShiftLogList !== true && props.showShiftLogContent && (
                    <div className="col-12">{activitiesCalendar()}</div>
                )}
            </div>
        );
    };

    const dayGroupBtnContent = () => {
        if (enableActivityHourlyView) {
            return (
                <RAFTabGroupBtn
                    items={activitiesTab}
                    selectedItem={state.selectedTabValue}
                    onSelect={(item) => onTabSelected(item.id)}
                    mode="TextOnly"
                    buttonStyle="primary"
                />
            );
        } else {
            return null;
        };
    };

    const activitiesContent = () => {
        return (
            <CareShiftLogList
                careRecipientUID={props.careRecipientUID}
                careRecipientName={props.careRecipientName}
                careEventRow={taskRow}
                onClickClockActions={onClickClockActions}
                mode={"CollapsePanel"}
                selectedEntity={props.selectedEntity}
                isSupportLog={props.isSupportLog}
                headerString={{
                    primaryHeader: "Record all activities and time spent with the client",
                }}
                taskRow={taskRow}
                onSave={refreshAfterSave}
                groupBtnContent={dayGroupBtnContent()}
            />
        );
    };

    const headerTemplate = () => {
        return (
            <div>
                <span className="subtitle_1">
                    Log everything you've done and noticed during the shift.
                </span>
            </div>
        );
    };

    const refreshAfterSave = () => {
        if (props.onSave) {
            props.onSave();
        }
    };


    const activitiesCalendar = () => {
        return (
            <CareShiftLogCalendar
                careRecipientUID={props.careRecipientUID}
                careRecipientName={props.careRecipientName}
                selectedEntity={props.selectedEntity}
                headerString={{
                    primaryHeader: "Record all activities and time spent with the client",
                }}
                serviceTransactionRow={taskRow}
                groupBtnContent={dayGroupBtnContent()}
            />
        );
    };


    const onTabSelected = (selectedTabValue?) => {
        setState({ selectedTabValue: selectedTabValue });
    };

    return (
        <div>
            {headerTemplate()}
            <div key={state.selectedTabValue}>
                {renderTabContent()}
            </div>
        </div>
    );
};

export default React.memo(CareShiftLogTabContent);